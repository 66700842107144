import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";

import { checkAndLogout } from "../../../../objects/User";
import * as Logging from "../../../../objects/Logging";
import SellFormResultModal from "./SellFormResultModal";
import { DATA_PRODUCTS, AREACOMMS_PRODUCTS } from "../../../../objects/Brands";

const HELPTXT_BENEFICIARY = "El número a recargar o enviar PIN o referencia";
const HELPTXT_BENEFICIARYCONF = "Escriba el destino para confirmarlo";
const HELPTXT_SRVAMOUNT = "Indique el monto de la factura";
const HELPTXT_PIN = "Indique su PIN de seguridad";
const ERROR_BENEFICIARY = "Escriba un número de 10 dígitos";
const ERROR_CONF = "No coinciden los dos datos";
const ERROR_SRVAMOUNT = "Solo se permiten números";
const ERROR_FILLBENEFICIARY = "Destino vacío";
const ERROR_CHECKERRORS = "Error en lo ingresado";
const ERROR_SELECTAMOUNT = "Indique un monto correcto";
const ERROR_PIN = "PIN debe de ser 6 dígitos";

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

class SellForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      showResultModal: false,
      success: false,
      sellData: {
        result: "",
        amount: "",
        beneficiary: "",
        product: "",
      },
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "SellForm class"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
  }

  showResultModal(show) {
    this.setState({ showResultModal: show });
  }

  _normalAmountOptions() {
    if (this.props.productData.type !== "normal") {
      return [];
    }
    const cName = this.props.productData.canonName;
    let options;
    if (DATA_PRODUCTS.includes(cName)) {
      options = Object.entries(this.props.normalProducts[cName]).map(
        ([amount, desc]) => (
          <option value={amount} key={amount}>
            {desc}
          </option>
        )
      );
    } else {
      options = Object.entries(this.props.normalProducts[cName]).map((data) => (
        <option value={data[0]} key={data[0]}>
          ${data[0]}
        </option>
      ));
    }
    return options;
  }

  _pinsAmount() {
    if (this.props.productData.type !== "pins") {
      return "";
    }
    let amount = this.props.productData.fullName.split(" ")[0];
    return amount.substring(1);
  }

  _commissionLabel = (beneficiary, amount) => {
    const canonName = this.props.productData.canonName;
    const type = this.props.productData.type;

    if (type === "services") {
      const product = this.props.servicesProducts[canonName];
      const com = product.ClientCommission ?? "0";
      return `Comisión ${com}`;
    } else if (
      type === "normal" &&
      amount &&
      canonName in AREACOMMS_PRODUCTS &&
      beneficiary &&
      beneficiary.length > 1
    ) {
      var comField = AREACOMMS_PRODUCTS[canonName];

      const fourChars = {
        ac: beneficiary.length > 3 ? beneficiary.substring(0, 4) : "",
        com: -1.0,
      };
      const threeChars = {
        ac: beneficiary.length > 2 ? beneficiary.substring(0, 3) : "",
        com: -1.0,
      };
      const twoChars = {
        ac: beneficiary.length > 1 ? beneficiary.substring(0, 2) : "",
        com: -1.0,
      };
      let areaCommission = -1;
      let areaCode = "";

      Object.entries(this.props.areaCommissions).forEach(([ac, data]) => {
        if (fourChars.ac === ac) {
          fourChars.com = data[comField];
        } else if (threeChars.ac === ac) {
          threeChars.com = data[comField];
        } else if (twoChars.ac === ac) {
          twoChars.com = data[comField];
        }
      });

      if (fourChars.com !== -1) {
        areaCommission = fourChars.com;
        areaCode = fourChars.ac;
      } else if (threeChars.com !== -1) {
        areaCommission = threeChars.com;
        areaCode = threeChars.ac;
      } else if (twoChars.com !== -1) {
        areaCommission = twoChars.com;
        areaCode = twoChars.ac;
      }
      areaCommission = Number.parseFloat(areaCommission);
      amount = Number.parseFloat(amount);
      if (!areaCode || isNaN(amount)) {
        return "";
      }

      let commission = ((areaCommission / 100) * amount).toFixed(2);

      return `Comisión LADA(${areaCode}) ${commission}`;
    } else {
      return "";
    }
  };

  _display = (elem) =>
    elem === this.props.productData.type ? "block" : "none";

  _displayPINInput = () =>
    this.props.userData.Permissions &&
    this.props.userData.Permissions["DisableAuthPIN"]
      ? "none"
      : "block";

  _smallTextClass = (error) =>
    error ? "from-text text-danger" : "from-text text-muted";

  _handleChange = (event) => {
    event.preventDefault();
    let stateField;
    let errorField;
    let errorMsg = "";
    let beneficiary = this.props.formData.beneficiary;
    let amount = this.props.formData.amount;
    let maxLength = this.props.formData.maxBenLeght;
    let { name, value } = event.target;
    value = value.trim();
    switch (name) {
      case "beneficiary": {
        stateField = errorField = name;
        if (this.props.productData.type === "services") {
          const cName = this.props.productData.canonName;
          const vString = this.props.servicesProducts[cName].Validator;
          if (vString) {
            let vPieces = vString.split("|");
            maxLength = Number.parseInt(vPieces.pop());
            let regexString = "^[";
            vPieces.forEach((piece) => {
              if (piece === "ABC") {
                regexString += "A-Z";
              } else if (piece === "abc") {
                regexString += "a-z";
              } else if (piece === "Abc") {
                regexString += "a-zA-Z";
              } else if (piece === "d") {
                regexString += "\\d";
              }
            });
            regexString += `]{${maxLength}}$`;
            const regex = new RegExp(regexString);
            if (value.length > maxLength) {
              value = value.substr(0, maxLength);
            }
            errorMsg = regex.test(value)
              ? ""
              : this.props.servicesProducts[cName].ErrorMessage;
          }
        } else {
          if (value.length > maxLength) {
            value = value.substr(0, maxLength);
          }
          errorMsg = /^\d{10}$/.test(value) ? "" : ERROR_BENEFICIARY;
        }
        beneficiary = value;
        break;
      }
      case "beneficiaryConf":
        if (value.length > maxLength) {
          value = value.substr(0, maxLength);
        }
        stateField = errorField = name;
        errorMsg = this.props.formData.beneficiary !== value ? ERROR_CONF : "";
        break;
      case "servicesAmount":
        amount = value;
        stateField = "amount";
        errorField = "srvAmount";
        errorMsg = /^\d{1,7}(\.\d{1,2})?$/.test(value) ? "" : ERROR_SRVAMOUNT;
        break;
      case "pinsAmount":
        amount = value;
        stateField = "amount";
        break;
      case "normalAmount":
        amount = value;
        stateField = "amount";
        break;
      case "pin":
        stateField = "pin";
        errorField = "pin";
        errorMsg = /^\d{6}$/.test(value) ? "" : ERROR_PIN;
        break;
      default:
        break;
    }
    const commsLabel = this._commissionLabel(beneficiary, amount);
    this.props.setData({
      ...this.props.formData,
      [stateField]: value,
      commissionLabel: commsLabel,
      maxBenLeght: maxLength,
      errors: { ...this.props.formData.errors, [errorField]: errorMsg },
    });
  };

  _handleSubmit = async (event) => {
    event.preventDefault();
    const formData = this.props.formData;
    const needsPIN = !this.props.userData.Permissions["DisableAuthPIN"];
    const pin = formData.pin ? formData.pin.trim() : "";
    const errors = formData.errors;
    const amount =
      this.props.productData.type === "pins"
        ? event.target.elements.pinsAmount.value
        : formData.amount;
    if (!formData.beneficiary || !formData.beneficiaryConf) {
      this.props.showSnackbar(ERROR_FILLBENEFICIARY);
      return;
    }
    if (errors.beneficiary || errors.beneficiaryConf || errors.srvAmount) {
      this.props.showSnackbar(ERROR_CHECKERRORS);
      return;
    }
    if (amount === "0" || amount === "") {
      this.props.showSnackbar(ERROR_SELECTAMOUNT);
      return;
    }
    if (needsPIN && errors.pin) {
      this.props.showSnackbar(ERROR_PIN);
      return;
    }
    this.setState({ processing: true });
    const sellData = {
      result: "",
      amount: amount,
      beneficiary: formData.beneficiary,
      product: this.props.productData.canonName,
    };
    let res;
    let success = false;
    try {
      res = await this.props.userObj.doSell(
        sellData.beneficiary,
        amount,
        sellData.product,
        pin,
        this.props.productData.type === "services"
      );
      sellData.result = res.message;
      success = true;
      this._logger(Logging.LEVELS.DEBUG, `Sell Success ${JSON.stringify(res)}`);
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Sell Failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        sellData.result = match[1] || e.message;
      } else {
        sellData.result = e.message;
      }
    }
    this.setState({
      ...this.state,
      success: success,
      processing: false,
      sellData: sellData,
    });
    this.showResultModal(true);
    if (success) {
      this.props.resetForm();
    }
  };

  _submitButton = () =>
    this.state.processing ? (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Procesando...</span>
      </div>
    ) : (
      <button type="submit" className="btn btn-primary" id="do-sell-button">
        {this.props.productData.type === "services" ? "Pagar" : "Hacer Compra"}
      </button>
    );

  render = () => {
    const inputDisabled = this.state.processing;
    const cName = this.props.productData.canonName;
    const beneficiaryLabel =
      this.props.productData.type === "services"
        ? this.props.servicesProducts[cName].FieldName
        : "Número";
    return (
      <>
        <Collapse in={this.props.show}>
          <div className="mx-auto pb-3" id="sellForm">
            <form autoComplete="off" onSubmit={this._handleSubmit}>
              <div className="d-flex justify-content-between p-2">
                <h5 className="font-weight-bold text-primary text-uppercase mb-1">
                  {this.props.productData.fullName}
                </h5>
                <div role="button">
                  <i className="fas fa-times" onClick={this.props.onHide}></i>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="beneficiary">{beneficiaryLabel}</label>
                <input
                  id="beneficiary"
                  name="beneficiary"
                  type="text"
                  className="form-control"
                  placeholder={beneficiaryLabel}
                  aria-describedby="beneficiaryHelp"
                  onChange={this._handleChange}
                  value={this.props.formData.beneficiary}
                  disabled={inputDisabled}
                />
                <small
                  id="beneficiaryHelp"
                  className={this._smallTextClass(
                    this.props.formData.errors.beneficiary
                  )}
                >
                  {this.props.formData.errors.beneficiary ||
                    HELPTXT_BENEFICIARY}
                </small>
              </div>

              <div className="form-group">
                <label htmlFor="beneficiaryConf">Confirme</label>
                <input
                  id="beneficiaryConf"
                  name="beneficiaryConf"
                  type="text"
                  className="form-control"
                  placeholder="Confirmación"
                  aria-describedby="beneficiaryConfHelp"
                  onChange={this._handleChange}
                  value={this.props.formData.beneficiaryConf}
                  disabled={inputDisabled}
                />
                <small
                  id="beneficiaryConfHelp"
                  className={this._smallTextClass(
                    this.props.formData.errors.beneficiaryConf
                  )}
                >
                  {this.props.formData.errors.beneficiaryConf ||
                    HELPTXT_BENEFICIARYCONF}
                </small>
              </div>

              <div
                className="form-group"
                id="servicesAmountBlock"
                style={{ display: this._display("services") }}
              >
                <div className="d-flex justify-content-between">
                  <label htmlFor="servicesAmount">Monto del Recibo</label>
                  <span className="small">
                    {this.props.formData.commissionLabel}
                  </span>
                </div>
                <input
                  id="servicesAmount"
                  name="servicesAmount"
                  type="text"
                  className="form-control"
                  placeholder="Monto"
                  aria-describedby="servicesAmountHelp"
                  onChange={this._handleChange}
                  disabled={inputDisabled}
                />
                <small
                  id="servicesAmountHelp"
                  className={this._smallTextClass(
                    this.props.formData.errors.srvAmount
                  )}
                >
                  {this.props.formData.errors.srvAmount || HELPTXT_SRVAMOUNT}
                </small>
              </div>

              <div
                className="form-group"
                id="normalAmountBlock"
                style={{ display: this._display("normal") }}
              >
                <div className="d-flex justify-content-between">
                  <label htmlFor="normalAmount">Monto</label>
                  <span className="small">
                    {this.props.formData.commissionLabel}
                  </span>
                </div>
                <select
                  id="normalAmount"
                  name="normalAmount"
                  className="form-control"
                  onChange={this._handleChange}
                  value={this.props.formData.amount}
                  disabled={inputDisabled}
                >
                  <option value="0" disabled>
                    Seleccione
                  </option>
                  {this._normalAmountOptions()}
                </select>
              </div>

              <div
                className="form-group"
                id="pinsAmountBlock"
                style={{ display: this._display("pins") }}
              >
                <div className="d-flex justify-content-between">
                  <label htmlFor="pinsAmount">Monto</label>
                  <span className="small">Saldo de Servicios</span>
                  <span className="small" style={{ display: "none" }}>
                    {this.props.formData.commissionLabel}
                  </span>
                </div>
                <input
                  id="pinsAmount"
                  name="pinsAmount"
                  type="text"
                  className="form-control"
                  placeholder="Monto"
                  value={this._pinsAmount()}
                  disabled={true}
                />
              </div>

              <div
                className="form-group"
                id="pinBlock"
                style={{ display: this._displayPINInput() }}
              >
                <div className="d-flex justify-content-between">
                  <label htmlFor="pin">PIN</label>
                </div>
                <input
                  id="pin"
                  name="pin"
                  type="password"
                  className="form-control"
                  placeholder="PIN"
                  value={this.props.formData.pin}
                  onChange={this._handleChange}
                  maxLength="6"
                  style={{ width: "6em" }}
                  aria-describedby="pinHelp"
                />
                <small
                  id="pinHelp"
                  className={this._smallTextClass(
                    this.props.formData.errors.pin
                  )}
                >
                  {this.props.formData.errors.pin || HELPTXT_PIN}
                </small>
              </div>

              <div className="row">
                <div className="w-100 py-2" style={{ textAlign: "center" }}>
                  {this._submitButton()}
                </div>
              </div>
            </form>
          </div>
        </Collapse>

        <SellFormResultModal
          show={this.state.showResultModal}
          onHide={() => this.showResultModal(false)}
          success={this.state.success}
          sellData={this.state.sellData}
        />
      </>
    );
  };
}

SellForm.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  productData: PropTypes.object,
  normalProducts: PropTypes.object,
  servicesProducts: PropTypes.object,
  areaCommissions: PropTypes.object,
  setData: PropTypes.func,
  resetForm: PropTypes.func,
  formData: PropTypes.object,
  userObj: PropTypes.object,
  userData: PropTypes.object,
  showSnackbar: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(SellForm);
