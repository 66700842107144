import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { checkAndLogout } from "../../../../objects/User";
import * as Logging from "../../../../objects/Logging";
import "./ReportDepositForm.css";
import BanksModal from "./BanksModal";

const HELPTXT_FOLIO = "Indique número de Folio o Autorización";
const HELPTXT_AMOUNT = "Indique la cantidad depositada";
const HELPTXT_BRANCH = "Indique la Sucursal del Depósito";

const ERROR_FOLIO = "Escriba el Folio";
const ERROR_AMOUNT = "Escriba el Monto";
const ERROR_DATE = "Fecha u Hora Incorrecta";
const ERROR_BANK = "Escoja el Banco";
const ERROR_TICKET = "Suba el Ticket";
const ERROR_FILESIZE = "Archivo demasiado grande";
const ERROR_BRANCH = "Escriba la Sucursal";

const SUCCESS = "Depósito Reportado Exitosamente";

const FILE_MAX_SIZE_KB = 4900;

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

class ReportDepositForm extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.initialFormState = {
      folio: "",
      branch: "",
      amount: "",
      dateYear: now.getFullYear().toString(),
      dateMonth: (now.getMonth() + 1).toString(),
      dateDay: now.getDate().toString(),
      hourHour: "",
      hourMins: "",
      hourSecs: "",
      ticket: {
        fileName: "",
        mimeType: "",
        base64Data: "",
      },
      bank: {
        id: "",
        name: "",
        needsTicket: false,
        showBranch: false,
      },
    };
    this.state = {
      processing: false,
      showBanksModal: false,
      formData: this.initialFormState,
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "SellForm class"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
  }

  selectBank = (bankData) =>
    this.setState({
      formData: {
        ...this.state.formData,
        bank: bankData,
      },
    });

  showBanksModal = (show) => this.setState({ showBanksModal: show });

  _handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    value = value.trim();

    switch (name) {
      case "folio":
        value = value.replace(/[^-\d]/, "");
        break;
      case "amount":
        value = value.replace(/[^.\d]/, "");
        break;
      case "dateYear":
      case "dateMonth":
      case "dateDay":
      case "hourHour":
      case "hourMins":
      case "hourSecs":
        value = value.replace(/[^\d]/, "");
        break;
      case "ticketFile": {
        value = event.target.files[0];
        if (value.size / 1024 > FILE_MAX_SIZE_KB) {
          event.target.value = null;
          this.props.showSnackbar(ERROR_FILESIZE);
          return;
        }
        const reader = new FileReader();

        reader.onload = () => {
          // DataURL format: "data:<mimeType>;base64,<base64Data>"
          const base64Data = reader.result.split(",")[1];
          this._logger(
            Logging.LEVELS.DEBUG,
            `File loaded:\n${value.name}\n${value.type}\n${base64Data}`
          );
          this.setState({
            formData: {
              ...this.state.formData,
              ticket: {
                fileName: value.name,
                mimeType: value.type,
                base64Data: base64Data,
              },
            },
          });
        };
        reader.onerror = () =>
          this.props.showSnackbar("Comprobante no cargado " + reader.error);
        reader.onloadstart = () => this.setState({ processing: true });
        reader.onloadend = () => this.setState({ processing: false });
        // Start file reading
        reader.readAsDataURL(value);
        return;
      }
      default:
        break;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };

  _handleSubmit = async (event) => {
    event.preventDefault();
    const data = this.state.formData;

    const pad = (digit) =>
      digit.toString().length === 1 ? "0" + digit.toString() : digit.toString();
    const date =
      `${data.dateYear}-${pad(data.dateMonth)}-${pad(data.dateDay)} ` +
      `${pad(data.hourHour)}:${pad(data.hourMins)}:${pad(data.hourSecs)}`;
    const d = new Date(date);
    const b = Number.parseInt(data.bank.id);

    if (!data.folio) {
      this.props.showSnackbar(ERROR_FOLIO);
      return;
    }
    if (!data.amount) {
      this.props.showSnackbar(ERROR_AMOUNT);
      return;
    }
    if (isNaN(d.getTime())) {
      this.props.showSnackbar(ERROR_DATE);
      return;
    }
    if (!data.bank.id || isNaN(b) || b < 1) {
      this.props.showSnackbar(ERROR_BANK);
      return;
    }
    if (data.bank.needsTicket && !data.ticket.base64Data) {
      this.props.showSnackbar(ERROR_TICKET);
      return;
    }
    if (data.bank.showBranch && !data.branch) {
      this.props.showSnackbar(ERROR_BRANCH);
      return;
    }

    this.setState({ processing: true });

    let res;
    let newFormState = this.state.formData;
    let message = "";
    try {
      res = await this.props.userObj.reportDeposit({
        folio: data.folio,
        amount: data.amount,
        datetime: date,
        bankID: data.bank.id,
        ticketData: data.ticket.base64Data,
        branch: data.branch,
      });
      this._logger(Logging.LEVELS.DEBUG, `Sell Success ${JSON.stringify(res)}`);
      message = SUCCESS;
      newFormState = this.initialFormState;
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Deposit Report Failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        message = match[1] || e.message;
      } else {
        message = e.message;
      }
    }
    this.setState({
      processing: false,
      formData: newFormState,
    });
    this.props.showSnackbar(message);
  };

  _submitButton = () =>
    this.state.processing ? (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Procesando...</span>
      </div>
    ) : (
      <button type="submit" className="btn btn-primary" id="doReportDeposit">
        Reportar Depósito
      </button>
    );

  render = () => {
    const inputDisabled = this.state.processing;
    const displayTicketUpload = this.state.formData.bank.needsTicket
      ? "block"
      : "none";
    const showBranchInput = this.state.formData.bank.showBranch
      ? "block"
      : "none";
    return (
      <div className="mx-auto pb-3" id="repDepForm">
        <form autoComplete="off" onSubmit={this._handleSubmit}>
          <div className="form-group">
            <label htmlFor="bankName">Folio</label>
            <input
              id="folio"
              name="folio"
              type="text"
              className="form-control text-input"
              placeholder="Folio"
              aria-describedby="folioHelp"
              onChange={this._handleChange}
              value={this.state.formData.folio}
              disabled={inputDisabled}
            />

            <small id="folioHelp" className="from-text text-muted">
              {HELPTXT_FOLIO}
            </small>
          </div>

          <div className="form-group" style={{ display: showBranchInput }}>
            <label htmlFor="bankName">Sucursal</label>
            <input
              id="branch"
              name="branch"
              type="text"
              className="form-control text-input"
              placeholder="Sucursal"
              aria-describedby="branchHelp"
              onChange={this._handleChange}
              value={this.state.formData.branch}
              disabled={inputDisabled}
            />

            <small id="branchHelp" className="from-text text-muted">
              {HELPTXT_BRANCH}
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="amount">Monto</label>
            <input
              id="amount"
              name="amount"
              type="text"
              className="form-control text-input"
              placeholder="Monto"
              aria-describedby="amountHelp"
              onChange={this._handleChange}
              value={this.state.formData.amount}
              disabled={inputDisabled}
            />

            <small id="amountHelp" className="from-text text-muted">
              {HELPTXT_AMOUNT}
            </small>
          </div>

          <div className="form-group">
            <label htmlFor="amount">Fecha</label>
            <div className="d-flex">
              <input
                id="dateYear"
                name="dateYear"
                type="text"
                className="form-control px-2 mx-1 date-y-input"
                maxLength="4"
                placeholder="Año"
                onChange={this._handleChange}
                value={this.state.formData.dateYear}
                disabled={inputDisabled}
              />
              <input
                id="dateMonth"
                name="dateMonth"
                type="text"
                className="form-control px-2 mx-1 date-inputs"
                maxLength="2"
                placeholder="Mes"
                onChange={this._handleChange}
                value={this.state.formData.dateMonth}
                disabled={inputDisabled}
              />
              <input
                id="dateDay"
                name="dateDay"
                type="text"
                className="form-control px-2 mx-1 date-inputs"
                maxLength="2"
                placeholder="Día"
                onChange={this._handleChange}
                value={this.state.formData.dateDay}
                disabled={inputDisabled}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="hour">Hora</label>
            <div className="d-flex">
              <input
                id="hourHour"
                name="hourHour"
                type="text"
                className="form-control px-2 mx-1 hour-inputs"
                maxLength="2"
                placeholder="Hora"
                onChange={this._handleChange}
                value={this.state.formData.hourHour}
                disabled={inputDisabled}
              />
              <input
                id="hourMins"
                name="hourMins"
                type="text"
                className="form-control px-2 mx-1 hour-inputs"
                maxLength="2"
                placeholder="Min"
                onChange={this._handleChange}
                value={this.state.formData.hourMins}
                disabled={inputDisabled}
              />
              <input
                id="hourSecs"
                name="hourSecs"
                type="text"
                className="form-control px-2 mx-1 hour-inputs"
                maxLength="2"
                placeholder="Seg"
                onChange={this._handleChange}
                value={this.state.formData.hourSecs}
                disabled={inputDisabled}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="bankName" className="mx-1 my-2">
              Banco
            </label>
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={() => this.showBanksModal(true)}
              disabled={inputDisabled}
            >
              Seleccione
            </button>
          </div>

          <div className="bank-name text-muted">
            {this.state.formData.bank.name}
          </div>

          <div className="form-group" style={{ display: displayTicketUpload }}>
            <label htmlFor="ticketFile" className="mx-1 my-2">
              Comprobante
            </label>
            <input
              type="file"
              className="form-control-file"
              id="ticketFile"
              name="ticketFile"
              onChange={this._handleChange}
            />
          </div>
          <br />
          <div className="mx-auto" style={{ width: "150px" }}>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={inputDisabled}
            >
              Enviar
            </button>
          </div>
        </form>

        <BanksModal
          show={this.state.showBanksModal}
          onHide={() => this.showBanksModal(false)}
          banks={this.props.banks}
          setBank={this.selectBank}
        />
      </div>
    );
  };
}

ReportDepositForm.propTypes = {
  showSnackbar: PropTypes.func,
  banks: PropTypes.object,
  userObj: PropTypes.object,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(ReportDepositForm);
