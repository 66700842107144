import React from "react";
import PropTypes from "prop-types";

const _buildTable = (data) => {
  if (data.length === 0) {
    return null;
  }
  const normal = "d-table-cell";
  const hideOnSmall = "d-none d-md-table-cell";
  return (
    <table className="table table-striped">
      <thead>
        <tr className="text-center">
          <th className={normal}>Producto</th>
          <th className={normal}>Monto</th>
          <th className={hideOnSmall}>Número</th>
          <th className={hideOnSmall}>Folio</th>
          <th className={normal}>Día</th>
          <th className={normal}>Hora</th>
          <th className={hideOnSmall}>Medio</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          const [date, hour] = item.DateTime.split(" ");
          return (
            <tr key={`${item.Beneficiary}-${item.Folio}`}>
              <td className={normal}>{item.Product}</td>
              <td className={normal}>{item.Amount}</td>
              <td className={hideOnSmall}>{item.Beneficiary}</td>
              <td className={hideOnSmall}>{item.Folio}</td>
              <td className={normal}>{date}</td>
              <td className={normal}>{hour}</td>
              <td className={hideOnSmall}>{item.Source}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

function SellReportResults(props) {
  const err = props.sellResults.errorMessage;
  return (
    <div className="col-auto">
      <div
        className="card bg-info text-white shadow"
        style={{ display: err ? "" : "none" }}
      >
        <div className="card-body">{err}</div>
      </div>
      {_buildTable(props.sellResults.sells)}
    </div>
  );
}

SellReportResults.propTypes = {
  sellResults: PropTypes.object,
};

export default SellReportResults;
