import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

// Chane Modal.animation to True when the issue with transition is resolved

const resultRow = (label, value, big = false) => (
  <div key={label} className="row" style={big ? { fontSize: "1.4em" } : {}}>
    <div className="w-50 px-2" style={{ textAlign: "right" }}>
      {label}:
    </div>
    <div className="w-50 px-2" style={{ textAlign: "left" }}>
      {value}
    </div>
  </div>
);

function SellFormResultModal(props) {
  let resultSymbolClass,
    simpleResultLabel,
    textVersion = "";
  const now = new Date();
  const data = [
    [props.success ? "Folio" : "Respuesta", props.sellData.result, true],
    ["Compañía", props.sellData.product, false],
    ["Destino", props.sellData.beneficiary, false],
    ["Monto", props.sellData.amount, false],
    ["Fecha", now.toISOString().split("T")[0], false],
    ["Hora", now.toTimeString().split(" ")[0], false],
  ];
  data.forEach((row) => (textVersion += `${row[0]}: ${row[1]}\n`));
  if (props.success) {
    resultSymbolClass = "fas fa-check-circle fa-2x text-success";
    simpleResultLabel = "Éxito";
  } else {
    resultSymbolClass = "fas fa-times-circle fa-2x text-danger";
    simpleResultLabel = "Error";
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="sellResultModalTitle"
      aria-hidden="true"
      role="dialog"
      centered
    >
      <Modal.Header className="py-2" closeButton>
        <Modal.Title id="sellResultModalTitle" style={{ fontSize: "1.2em" }}>
          Resultado
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="w-100 py-2" style={{ textAlign: "center" }}>
            <i className={resultSymbolClass} title={simpleResultLabel}></i>
          </div>
        </div>

        {data.map((row) => resultRow(row[0], row[1], row[2]))}

        <div className="row">
          <div className="w-100 py-2" style={{ textAlign: "center" }}>
            <button
              className="btn btn-light btn-icon-split btn-sm"
              onClick={() => navigator.clipboard.writeText(textVersion)}
            >
              <span className="icon text-gray-600">
                <i className="fas fa-clipboard"></i>
              </span>
              <span className="text">Copiar</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

SellFormResultModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  success: PropTypes.bool,
  sellData: PropTypes.object,
};

export default SellFormResultModal;
