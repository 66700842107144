import { PATHS } from "../App";
import { RESTClient, RESTClientResponse, HTTP_METHODS } from "./RESTClient";
import {
  AppData,
  RecargakiClientResponse,
  parseRecargakiError,
  parseRecargakiResult,
} from "./RecargakiClient";
import { DEV_ENVIRONMENT } from "../constants/Constants";
import { Mutex } from "async-mutex";
import * as Logging from "./Logging";

export const RESPONSES = {
  REFRESHERROR: {
    // Returned by methods using endpoints that require auth, MUST Logout
    code: -10,
    message: "Error al recuperar credenciales. Inténtelo de nuevo después.",
  },
  NOLOGIN: {
    // Returned by methods using endpoints that require auth, MUST Logout
    code: -20,
    message: "No ha hecho login",
  },
  REFRESH_SUCCESS: { code: 10, message: "Credenciales válidas o renovadas" },
  REFRESH_NOLOGIN: { code: 11, message: "No ha hecho login" },
  REFRESH_FAILURE: { code: 12, message: "Error actualizando credenciales" },
  REFRESH_APIERROR: {
    code: 1000,
    message: "La renovación de su token falló\n",
  },
  LOGIN_SUCCESS: { code: 20, message: "Usuario Loggeado" },
  LOGIN_REFRESHERROR: {
    code: 21,
    message: "Error al recuperar credenciales. Inténtelo de nuevo después.",
  },
  LOGIN_FAILURE: { code: 22, message: "Error ingresando a su cuenta" },
  LOGIN_APIERROR: {
    code: 2000,
    message: "Falló su acceso\n",
  },
  LOGOUT_SUCCESS: { code: 30, message: "Ha salido de su cuenta" },
  LOGOUT_APIERROR: {
    code: 3000,
    message: "Falló la salida\n",
  },
  GBAL_SUCCESS: { code: 40, message: "Saldo" },
  GBAL_NOLOGIN: { code: 41, message: "No ha hecho login" },
  GBAL_APIERROR: {
    code: 4000,
    message: "Falló obtención de saldo\n",
  },
  GUDATA_SUCCESS: { code: 50, message: "Información del usuario obtenida" },
  GUDATA_APIERROR: {
    code: 5000,
    message: "Falló obtención de datos del usuario\n",
  },
  GPRODUCTS_SUCCESS: { code: 60, message: "Información de productos obtenida" },
  GPRODUCTS_APIERROR: {
    code: 6000,
    message: "Falló obtención de productos disponibles\n",
  },
  GACOMMS_SUCCESS: {
    code: 70,
    message: "Información de códigos de area obtenida",
  },
  GACOMMS_APIERROR: {
    code: 7000,
    message: "Falló obtención de comisiones de LADA\n",
  },
  DOSELL_SUCCESS: { code: 80, message: "Venta exitosa" },
  DOSELL_APIERROR: { code: 8000, message: "Falló operación\n" },
  BANKSAVL_SUCCESS: { code: 90, message: "Bancos Obtenidos" },
  BANKSAVL_APIERROR: { code: 9000, message: "Falló obtener info\n" },
  REPDEP_SUCCESS: { code: 100, message: "Reporte exitoso" },
  REPDEP_APIERROR: { code: 10000, message: "Falló el reporte\n" },
  GULIST_SUCCESS: { code: 110, message: "Lista de Usuarios" },
  GULIST_APIERROR: { code: 11000, message: "Falló solicitud\n" },
  GSREP_SUCCESS: { code: 120, message: "Lista de Ventas" },
  GSREP_APIERROR: { code: 12000, message: "Falló solicitud\n" },
  SPOSDATA_SUCCESS: { code: 130, message: "Operación Exitosa" },
  SPOSDATA_APIERROR: { code: 13000, message: "Falló solicitud\n" },
  DBALTRANS_SUCCESS: { code: 140, message: "Operación Exitosa" },
  DBALTRANS_WRONGTYPE: { code: 141, message: "Tipo de Saldo Incorrecto" },
  DBALTRANS_APIERROR: { code: 14000, message: "Falló solicitud\n" },
  SDISTPDATA_SUCCESS: { code: 150, message: "Operación Exitosa" },
  SDISTPDATA_APIERROR: {
    code: 15000,
    message: "No se guardaron los cambios\n",
  },
  NEWPASS_SUCCESS: { code: 160, message: "Operación Exitosa" },
  NEWPASS_APIERROR: { code: 16000, message: "No se renovó su Clave\n" },
  GDISTMSG_SUCCESS: { code: 170, message: "Operación Exitosa" },
  GDISTMSG_APIERROR: { code: 17000, message: "Falló solicitud\n" },
  SDISTMSG_SUCCESS: { code: 180, message: "Operación Exitosa" },
  SDISTMSG_APIERROR: { code: 18000, message: "Falló solicitud\n" },
  GRGKIMSG_SUCCESS: { code: 190, message: "Operación Exitosa" },
  GRGKIMSG_APIERROR: { code: 19000, message: "Falló solicitud\n" },
  CDEFTUSER_SUCCESS: { code: 190, message: "Operación Exitosa" },
  CDEFTUSER_APIERROR: { code: 19000, message: "Falló solicitud\n" },
  NEWPIN_SUCCESS: { code: 200, message: "Operación Exitosa" },
  NEWPIN_APIERROR: { code: 20000, message: "No se pudo crear su PIN\n" },
};

/**
 * Checks if Exception Error object has information that means
 * this App should logout the user out.
 * @param {Error} err Exception error object with formatted
 *                    RecargakiResponse codes inside err.name
 */
export const checkAndLogout = (userObj, history) => (err) => {
  const code = err.name.split("#")[1];
  if (
    code === RESPONSES.REFRESHERROR.code.toString() ||
    code === RESPONSES.NOLOGIN.code.toString() ||
    err.message.includes("(401) Usuario Incorrecto") ||
    err.message.includes("(401) Credenciales Incorrectas")
  ) {
    userObj
      .logout()
      .catch((e) =>
        this._logger(
          Logging.LEVELS.WARNING,
          "Failed to properly logout " + e.message
        )
      )
      .finally(() => history.replace(PATHS.login));
  }
};

/**
 * Set this class Log Level as desired
 */
const LOG_LEVEL = Logging.LEVELS.INFO;
const DEBUG_APICALLS = false;
const BAL_UPDATEINTERVAL = 1 * 1000; // milliseconds, default: 1 min
const PRODS_UPDATEINTERVAL = (DEV_ENVIRONMENT ? 1 : 5) * 1000; // milliseconds default: 5 min
const AREACODE_UPDINTERVAL = (DEV_ENVIRONMENT ? 1 : 5) * 1000; // milliseconds default: 5 min
const BANKS_UPDINTERVAL = (DEV_ENVIRONMENT ? 1 : 5) * 1000; // milliseconds default: 5 min
const USERLIST_UPDINTERVAL = 1 * 1000; // milliseconds default: 1 min

const STORAGE_PREFIX = AppData.appName + "-";
const LSTOKEYS = {
  // Local Storage Keys
  username: STORAGE_PREFIX + "username",
  aToken: STORAGE_PREFIX + "AT",
  rToken: STORAGE_PREFIX + "RT",
  expDate: STORAGE_PREFIX + "ED",
};
const SSTOKEYS = {
  // Session Storage Keys
  balTStamp: STORAGE_PREFIX + "BalTimestamp",
  balTopup: STORAGE_PREFIX + "TopUpBalance",
  balServices: STORAGE_PREFIX + "ServicesBalance",
  productsNormTS: STORAGE_PREFIX + "ProdsNormalTimestamp",
  productsNormal: STORAGE_PREFIX + "NormalProducts",
  productsServTS: STORAGE_PREFIX + "ProdsServicesTimestamp",
  productsServ: STORAGE_PREFIX + "ServicesProducts",
  areaComms: STORAGE_PREFIX + "AreaCodeCommissions",
  areaCommsTS: STORAGE_PREFIX + "AreaCodeCommsTimestamp",
  banksAvble: STORAGE_PREFIX + "BanksAvailable",
  banksAvbleTS: STORAGE_PREFIX + "BanksAvailableTimestamp",
  usersList: STORAGE_PREFIX + "UsersList",
  usersListTS: STORAGE_PREFIX + "UsersListTimestamp",
};
export const BAL_TYPES = {
  normal: "normal",
  services: "services",
};
export const PRODS_TYPES = {
  normal: "normal",
  services: "services",
};

/**
 * User retains current user data and permissions
 * @class
 * @public
 */
class User {
  /**
   * User is initialized with a <username>
   * @param {string} [username=""]
   */
  constructor(username = "", options = { debug: false }) {
    this.username = username;
    this._sStorage = window.sessionStorage;
    this._lStorage = window.localStorage;

    /**
     * Logs <message> if <debugLevel> is lower or equal than LOG_LEVEL
     * @param {Logging.LEVELS} debugLevel
     * @param {string} message
     *
     * @private
     * @function
     */
    this._logger = Logging.Logger(
      options.debug ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "User class"
    );

    this._initUserStorage();
  }

  /**
   * Initialize Local Storage if necessary
   *
   * Resets credentials on Local Storage if a different user is used
   * do nothing if <username> construct parameter was empty or is the
   * same as the one in the Local Storage.
   * @private
   */
  _initUserStorage() {
    let storedUser = this._lStorage.getItem(LSTOKEYS.username);

    if (!storedUser) {
      storedUser = this.username;
    } else if (!this.username) {
      this.username = storedUser;
    }

    let isDifferentUser = storedUser !== this.username;

    Object.entries(LSTOKEYS).forEach(([key, value]) => {
      if (key === "username") {
        this._lStorage.setItem(value, this.username);
      }
      // Clear token storage up if is a different user
      if (!this._lStorage.getItem(value || isDifferentUser)) {
        this._lStorage.setItem(value, "");
      }
    });
    Object.entries(SSTOKEYS).forEach((stoKey) =>
      this._sStorage.setItem(stoKey[1], "")
    );
  }

  /**
   * Clears up storage and resets each field to an empty string
   * @private
   */
  _clearStorage() {
    this._lStorage.clear();
    this._sStorage.clear();
    Object.entries(LSTOKEYS).forEach((stoKey) =>
      this._lStorage.setItem(stoKey[1], "")
    );
    Object.entries(SSTOKEYS).forEach((stoKey) =>
      this._sStorage.setItem(stoKey[1], "")
    );
  }

  /**
   * Retrieves Access Token from Local Storage
   *
   * Access Tokens are used for API Methods that require authentication.
   * It has an Expiration Date on which afterwards it's no longer valid.
   * @returns {string} accessToken
   *
   * @public
   * @function
   */
  getAccessToken = () => this._lStorage.getItem(LSTOKEYS.aToken) || "";

  /**
   * Retrieves Refresh Token from Local Storage
   *
   * Refresh Tokens are used to get a new Access Token without the need
   * of log in again, after the original Access Token has expired.
   * @returns {string} refreshToken
   *
   * @public
   * @function
   */
  getRefreshToken = () => this._lStorage.getItem(LSTOKEYS.rToken) || "";

  /**
   * Retrieves Access Token's Expiration Date from Local Storage
   *
   * Date of Expiration of the Access Token
   * @returns {Date} expirationDate
   *
   * @public
   * @function
   */
  getExpirationDate = () => {
    const data = this._lStorage.getItem(LSTOKEYS.expDate) || "0";
    return new Date(Number.parseInt(data));
  };

  /**
   * Returns True if there's an User logged in with valid credentials
   * @returns {boolean} accessToken && refreshToken
   *
   * @public
   * @function
   */
  isLoggedIn = () => this.getAccessToken() && this.getRefreshToken();

  /**
   * Returns True if Access Token Expiration Date has already passed
   * @returns {boolean} (now >= expDate)
   *
   * @public
   * @function
   */
  isTokenExpired() {
    const expDate = this.getExpirationDate();
    const now = new Date();
    this._logger(
      Logging.LEVELS.DEBUG,
      `ATExpDate: ${expDate.toISOString()} Now: ${now.toISOString()}`
    );
    return now >= expDate;
  }

  /**
   * Returns an Error object with an Error Code on Error.name
   * @param {Object} response {code: int, message: string}
   * @returns {Error}  {name: `Error #${response.code}`, message: response.message}
   *
   * @private
   * @function
   */
  _buildError(response = { code: 0, message: "Desconocido" }) {
    const e = new Error(response.message);
    e.name = `Error #${response.code}`;
    return e;
  }

  /**
   * If <refreshToken> exists on Local Storage, renew Access Token
   * @returns {Promise<RecargakiClientResponse>}
   *                          onSuccess RecargakiClientResponse
   *                          onFail Error() and user should be logged out
   *
   * @async
   * @public
   * @function
   */
  async refreshAccessToken() {
    const mutex = new Mutex();
    await mutex.runExclusive(async () => {
      if (!this.isLoggedIn()) {
        this._logger(Logging.LEVELS.DEBUG, "RefreshAT - User is not Logged In");
        throw this._buildError(RESPONSES.REFRESH_NOLOGIN);
      }

      if (!this.isTokenExpired()) {
        this._logger(Logging.LEVELS.DEBUG, "RefreshAT - Token is not expired");
        return new RecargakiClientResponse({
          success: true,
          errorCode: RESPONSES.REFRESH_SUCCESS.code,
          message: RESPONSES.REFRESH_SUCCESS.message,
        });
      }

      const restCli = new RESTClient(AppData.baseURL, {
        debug: DEBUG_APICALLS,
      });

      let res = new RESTClientResponse({});
      try {
        res = await restCli.request("/user/refresh-token", {
          method: HTTP_METHODS.POST,
          params: { domain: window.location.hostname },
          credentials: {
            username: AppData.appName + "|" + AppData.appToken,
            password: this.username + "|" + this.getRefreshToken(),
          },
        });
      } catch (e) {
        // Class RESTClientResponse
        const errData = parseRecargakiError(e);
        const message = RESPONSES.REFRESH_APIERROR.message + errData.message;
        this._logger(
          Logging.LEVELS.DEBUG,
          "RefreshAT - Failed to update tokens " +
            e.httpCode.toString() +
            ` Msg: (${errData.code}) ${message}`
        );
        throw this._buildError({
          code: RESPONSES.REFRESH_APIERROR.code + errData.code,
          message: message,
        });
      }

      const result = parseRecargakiResult(res);
      const success = result.success;

      if (!success) {
        this._logger(
          Logging.LEVELS.WARNING,
          `RefreshAT - Failed ${result.message}`
        );
        throw this._buildError({
          code: RESPONSES.REFRESH_APIERROR.code + result.errorCode,
          message: result.message,
        });
      }

      const tokenData = result.message.TokenData;
      this._lStorage.setItem(LSTOKEYS.aToken, tokenData.Token);
      this._lStorage.setItem(LSTOKEYS.rToken, tokenData.RefreshToken);
      this._lStorage.setItem(LSTOKEYS.expDate, tokenData.ExpiresAt * 1000);
      this._logger(Logging.LEVELS.DEBUG, "RefreshAT - Success");
      return new RecargakiClientResponse({
        success: true,
        errorCode: RESPONSES.REFRESH_SUCCESS.code,
        message: RESPONSES.REFRESH_SUCCESS.message,
      });
    });
  }

  /**
   * If user is not initialized, sets its tokens and expiration date
   * @param {string} userPassword
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async logInUser(userPassword = "") {
    if (this.isLoggedIn()) {
      if (this.isTokenExpired()) {
        try {
          this._logger(
            Logging.LEVELS.DEBUG,
            "LogInUser - AccessToken expired, will refresh"
          );
          const res = await this.refreshAccessToken();
          if (res.success) {
            this._logger(
              Logging.LEVELS.DEBUG,
              "LogInUser - AccessToken refreshed"
            );
            return new RecargakiClientResponse({
              success: true,
              errorCode: RESPONSES.LOGIN_SUCCESS.code,
              message: RESPONSES.LOGIN_SUCCESS.message,
            });
          }
        } catch (e) {
          this._logger(
            Logging.LEVELS.ERROR,
            `LogInUser - AccessToken failed to refresh (${e.name}) ${e.message}`
          );
          //logout();
          throw this._buildError(RESPONSES.LOGIN_REFRESHERROR);
        }
      } else {
        this._logger(
          Logging.LEVELS.DEBUG,
          "LogInUser - User already Initialized and Not Expired"
        );
        return new RecargakiClientResponse({
          success: true,
          errorCode: RESPONSES.LOGIN_SUCCESS.code,
          message: RESPONSES.LOGIN_SUCCESS.message,
        });
      }
    }

    // User not logged in
    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });

    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/access-token", {
        method: HTTP_METHODS.POST,
        params: { domain: window.location.hostname },
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + userPassword,
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      const message = RESPONSES.LOGIN_APIERROR.message + errData.message;
      this._logger(
        Logging.LEVELS.DEBUG,
        "LogInUser - Failed to log in " +
          e.httpCode.toString() +
          ` Msg: (${errData.code}) ${message}`
      );
      throw this._buildError({
        code: RESPONSES.LOGIN_APIERROR.code + errData.code,
        message: message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      this._logger(
        Logging.LEVELS.WARNING,
        `LogInUser - Failed ${result.message}`
      );
      throw this._buildError({
        code: RESPONSES.LOGIN_APIERROR.code + result.errorCode,
        message: result.message,
      });
    }

    const tokenData = result.message.TokenData;
    this._lStorage.setItem(LSTOKEYS.aToken, tokenData.Token);
    this._lStorage.setItem(LSTOKEYS.rToken, tokenData.RefreshToken);
    this._lStorage.setItem(LSTOKEYS.expDate, tokenData.ExpiresAt * 1000);
    this._logger(Logging.LEVELS.DEBUG, "LogInUser - Success");
    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.LOGIN_SUCCESS.code,
      message: RESPONSES.LOGIN_SUCCESS.message,
    });
  }

  /**
   * Logs the User out
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async logout() {
    try {
      if (!this.isLoggedIn()) {
        throw this._buildError(RESPONSES.NOLOGIN);
      }

      /* Logout from Recargaki API */

      if (this.isTokenExpired()) {
        this._logger(
          Logging.LEVELS.DEBUG,
          "Logout - Token is expired, will refresh"
        );
        const res = await this.refreshAccessToken();
        if (!res.success) {
          throw this._buildError(RESPONSES.REFRESHERROR);
        }
        // Token refreshed
      }

      const restCli = new RESTClient(AppData.baseURL, {
        debug: DEBUG_APICALLS,
      });

      let res = new RESTClientResponse({});
      try {
        res = await restCli.request("/user/access-token", {
          method: HTTP_METHODS.DELETE,
          credentials: {
            username: AppData.appName + "|" + AppData.appToken,
            password: this.username + "|" + this.getAccessToken(),
          },
        });
      } catch (e) {
        // Class RESTClientResponse
        const errData = parseRecargakiError(e);
        throw this._buildError({
          code: errData.code,
          message:
            RESPONSES.GBAL_APIERROR.message +
            `| (${e.httpCode}) ` +
            errData.message,
        });
      }

      const result = parseRecargakiResult(res);
      const success = result.success;

      if (!success) {
        throw this._buildError({
          code: result.errorCode,
          message: RESPONSES.GBAL_APIERROR.message + result.message,
        });
      }

      this._logger(Logging.LEVELS.DEBUG, "Logout - Success");
    } catch (e) {
      this._logger(Logging.LEVELS.DEBUG, "Logout - " + e.message);
    }

    /*const returnToLogin = () => {
      if (context != null) {
        Navigator.pushNamedAndRemoveUntil(
            context, '/', (Route<dynamic> route) => false);
      }
    }*/

    this._clearStorage();
    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.LOGOUT_SUCCESS.code,
      message: RESPONSES.LOGOUT_SUCCESS.message,
    });
  }

  /**
   * Returns the User balance of type opts.balType it may use cached data
   * @param {Object} opts
   * @param {BAL_TYPES} opts.type Type of Balance to retrieve
   * @param {Boolean} opts.forceUpdate if true will force a call to the API
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async getBalance(opts = { type: BAL_TYPES.normal, forceUpdate: false }) {
    const balanceStoKey =
      opts.type === BAL_TYPES.normal ? SSTOKEYS.balTopup : SSTOKEYS.balServices;

    // return cached data if BAL_UPDATEINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._sStorage.getItem(SSTOKEYS.balTStamp) || "0"
    );

    if (!opts.forceUpdate && Date.now() < lastUpdate + BAL_UPDATEINTERVAL) {
      const bal = this._sStorage.getItem(balanceStoKey);
      this._logger(
        Logging.LEVELS.DEBUG,
        `gBalance - ${opts.type} bal on cache ${bal}`
      );
      return new RecargakiClientResponse({
        success: true,
        errorCode: RESPONSES.GBAL_SUCCESS.code,
        message: bal,
      });
    }

    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gBalance - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/balance", {
        method: HTTP_METHODS.GET,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GBAL_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GBAL_APIERROR.message + result.message,
      });
    }

    const balance = result.message;
    this._logger(
      Logging.LEVELS.DEBUG,
      `gBalance - retrieved from API ${JSON.stringify(balance)}`
    );
    this._sStorage.setItem(SSTOKEYS.balTopup, balance.Normal);
    this._sStorage.setItem(SSTOKEYS.balServices, balance.Service);
    this._sStorage.setItem(SSTOKEYS.balTStamp, Date.now().toString());
    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GBAL_SUCCESS.code,
      message: this._sStorage.getItem(balanceStoKey),
    });
  }

  /**
   * Retrieve <username> full data
   * @param {string} username User from which data will be retrieved
   *                          if empty, the current user data will be returned
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async getUserData(username = "") {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gUserData - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/pos", {
        method: HTTP_METHODS.GET,
        params: { username: username },
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GUDATA_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GUDATA_APIERROR.message + result.message,
      });
    }

    const data = result.message;
    data.IsEmployee = data.IsEmployee === "1";
    // Normalize permissions object to booleans
    Object.entries(data.Permissions).forEach((kv) => {
      data.Permissions[kv[0]] = data.Permissions[kv[0]] === "1";
    });
    this._logger(
      Logging.LEVELS.DEBUG,
      `gUserData - retrieved from API ${JSON.stringify(data)}`
    );

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GUDATA_SUCCESS.code,
      message: data,
    });
  }

  /**
   * Updates or creates a Point of Sale for the current user
   * @param {string} posUsername username of the POS to update or create
   * @param {Object} data       Complementary info about the POS
   * @param {string} data.pin             defaults to blank
   * @param {string} data.companyName     defaults to blank
   * @param {string} data.email           defaults to blank
   * @param {string} data.contactName     defaults to blank
   * @param {string} data.phoneNumber     defaults to blank
   * @param {string} data.mobileNumber    defaults to blank
   * @param {string} data.city            defaults to blank
   * @param {string} data.state           defaults to blank
   * @param {string} data.streetAndNumber defaults to blank
   * @param {string} data.colony          defaults to blank
   * @param {string} data.postalCode      defaults to blank
   * @param {string} data.sellerCommission defaults to blank
   * @param {boolean} data.sellTopUp      defaults to false
   * @param {boolean} data.createUsers    defaults to false
   * @param {boolean} data.createEmployees  defaults to false
   * @param {boolean} data.getReports     defaults to false
   * @param {boolean} data.reportDeposit  defaults to false
   * @param {boolean} create    defaults to false, if it is set operation
   *                            will create a POS, otherwise it will try
   *                            to update <posUsername>
   * @param {string} userType   normal | employee [Only valid if create=true]
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async setPOSData(
    posUsername,
    data = {
      pin: "",
      companyName: "",
      email: "",
      contactName: "",
      phoneNumber: "",
      mobileNumber: "",
      city: "",
      state: "",
      streetAndNumber: "",
      colony: "",
      postalCode: "",
      sellerCommission: "",
      sellTopUp: false,
      createUsers: false,
      createEmployees: false,
      getReports: false,
      reportDeposit: false,
    },
    create = false,
    userType = "normal"
  ) {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "sPOSData - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const parameters = {
      domain: window.location.hostname,
      pin: data.pin,
      username: posUsername,
      user_type: userType,
      company_name: data.companyName,
      email: data.email,
      contact_name: data.contactName,
      phone_number: data.phoneNumber,
      mobile_number: data.mobileNumber,
      city: data.city,
      state: data.state,
      street_and_number: data.streetAndNumber,
      colony: data.colony,
      postal_code: data.postalCode,
      seller_comission: data.sellerCommission,
      ov_sell_topup: data.sellTopUp,
      ov_create_users: data.createUsers,
      ov_create_employees: data.createEmployees,
      ov_get_reports: data.getReports,
      ov_report_deposit: data.reportDeposit,
    };

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/pos", {
        method: create ? HTTP_METHODS.POST : HTTP_METHODS.PUT,
        params: parameters,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.SPOSDATA_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.SPOSDATA_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.SPOSDATA_SUCCESS.code,
      message: result.message,
    });
  }

  /**
   * Returns available products for the current user it may use cached data
   * @param {opts} opts
   * @param {PRODS_TYPES} opts.type Type of Products to retrieve
   * @param {Boolean} opts.forceUpdate if true will force a call to the API
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async getProducts(opts = { type: PRODS_TYPES.normal, forceUpdate: false }) {
    let storageKey, timestampKey;
    switch (opts.type) {
      case PRODS_TYPES.services:
        storageKey = SSTOKEYS.productsServ;
        timestampKey = SSTOKEYS.productsServTS;
        break;
      case PRODS_TYPES.normal:
      default:
        storageKey = SSTOKEYS.productsNormal;
        timestampKey = SSTOKEYS.productsNormTS;
        break;
    }

    // return cached data if PRODS_UPDATEINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._sStorage.getItem(timestampKey) || "0"
    );

    if (!opts.forceUpdate && Date.now() < lastUpdate + PRODS_UPDATEINTERVAL) {
      const products = this._sStorage.getItem(storageKey);
      this._logger(
        Logging.LEVELS.DEBUG,
        `gProducts - ${opts.type} products on cache ${products}`
      );
      return new RecargakiClientResponse({
        success: true,
        errorCode: RESPONSES.GPRODUCTS_SUCCESS.code,
        message: products,
      });
    }

    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "getProducts - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/orders/products", {
        method: HTTP_METHODS.GET,
        params:
          opts.type === PRODS_TYPES.normal
            ? { with_amounts: "1" }
            : { services_products: "1", with_serv_metadata: "1" },
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GPRODUCTS_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GPRODUCTS_APIERROR.message + result.message,
      });
    }

    const data =
      Array.isArray(result.message) && result.message.length === 0
        ? {}
        : result.message;

    this._logger(
      Logging.LEVELS.DEBUG,
      `getProducts - retrieved from API ${JSON.stringify(data)}`
    );
    this._sStorage.setItem(storageKey, JSON.stringify(data));
    this._sStorage.setItem(timestampKey, Date.now().toString());
    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GPRODUCTS_SUCCESS.code,
      message: data,
    });
  }

  /**
   * Returns Area Code Comissions
   * @param {Boolean} forceUpdate
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async getAreaCommissions(forceUpdate = false) {
    // return cached data if AREACODE_UPDINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._sStorage.getItem(SSTOKEYS.areaCommsTS) || "0"
    );

    if (!forceUpdate && Date.now() < lastUpdate + AREACODE_UPDINTERVAL) {
      const comms = this._sStorage.getItem(SSTOKEYS.areaComms);
      this._logger(
        Logging.LEVELS.DEBUG,
        `gAreaComms - area commissions on cache ${comms}`
      );
      return new RecargakiClientResponse({
        success: true,
        errorCode: RESPONSES.GACOMMS_SUCCESS.code,
        message: comms,
      });
    }

    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gAreaComms - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/orders/lada-commissions", {
        method: HTTP_METHODS.GET,
        params: { domain: window.location.hostname },
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GACOMMS_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GACOMMS_APIERROR.message + result.message,
      });
    }

    const data =
      Array.isArray(result.message) && result.message.length === 0
        ? {}
        : result.message;

    this._logger(
      Logging.LEVELS.DEBUG,
      `gAreaComms - retrieved from API ${JSON.stringify(data)}`
    );
    this._sStorage.setItem(SSTOKEYS.areaComms, JSON.stringify(data));
    this._sStorage.setItem(SSTOKEYS.areaCommsTS, Date.now().toString());
    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GACOMMS_SUCCESS.code,
      message: data,
    });
  }

  async doSell(beneficiary, amount, product, pin, isService) {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "doSell - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/orders/" + (isService ? "pay" : "sell"), {
        method: HTTP_METHODS.POST,
        params: {
          amount: amount,
          beneficiary: beneficiary,
          product: product,
          pin: pin,
          domain: window.location.hostname,
          test_response: "3",
        },
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.DOSELL_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.DOSELL_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.DOSELL_SUCCESS.code,
      message: result.message,
    });
  }

  async banksAvailable(forceUpdate = false) {
    // return cached data if BANKS_UPDINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._sStorage.getItem(SSTOKEYS.banksAvbleTS) || "0"
    );

    if (!forceUpdate && Date.now() < lastUpdate + BANKS_UPDINTERVAL) {
      const banks = this._sStorage.getItem(SSTOKEYS.banksAvble);
      this._logger(
        Logging.LEVELS.DEBUG,
        `gBanksAvailable - area commissions on cache ${banks}`
      );
      return new RecargakiClientResponse({
        success: true,
        errorCode: RESPONSES.BANKSAVL_SUCCESS.code,
        message: banks,
      });
    }

    if (!this.isLoggedIn()) {
      this._logger(
        Logging.LEVELS.DEBUG,
        "gBanksAvailable - User not logged in"
      );
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/reports/banks", {
        method: HTTP_METHODS.GET,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GACOMMS_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GACOMMS_APIERROR.message + result.message,
      });
    }

    const data =
      Array.isArray(result.message) && result.message.length === 0
        ? {}
        : result.message;

    this._logger(
      Logging.LEVELS.DEBUG,
      `gBanksAvailable - retrieved from API ${JSON.stringify(data)}`
    );
    this._sStorage.setItem(SSTOKEYS.banksAvble, JSON.stringify(data));
    this._sStorage.setItem(SSTOKEYS.banksAvbleTS, Date.now().toString());
    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GACOMMS_SUCCESS.code,
      message: data,
    });
  }

  async reportDeposit(
    deposit = {
      folio: "",
      amount: "",
      datetime: "",
      bankID: "",
      ticketData: "",
      branch: "",
    }
  ) {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "repDep - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    let reqData = {
      folio: deposit.folio,
      datetime: deposit.datetime,
      amount: deposit.amount,
      bank_id: deposit.bankID,
      branch: deposit.branch,
    };
    if (deposit.ticketData) {
      reqData.ticket_data = deposit.ticketData;
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/reports/deposit", {
        method: HTTP_METHODS.POST,
        params: reqData,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.REPDEP_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.REPDEP_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.REPDEP_SUCCESS.code,
      message: result.message,
    });
  }

  async getUsersList(forceUpdate = false) {
    // return cached data if USERLIST_UPDINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._sStorage.getItem(SSTOKEYS.usersListTS) || "0"
    );

    if (!forceUpdate && Date.now() < lastUpdate + USERLIST_UPDINTERVAL) {
      const banks = this._sStorage.getItem(SSTOKEYS.usersList);
      this._logger(
        Logging.LEVELS.DEBUG,
        `gUList - area commissions on cache ${banks}`
      );
      return new RecargakiClientResponse({
        success: true,
        errorCode: RESPONSES.GULIST_SUCCESS.code,
        message: banks,
      });
    }

    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gUList - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/userlist", {
        method: HTTP_METHODS.GET,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GULIST_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GULIST_APIERROR.message + result.message,
      });
    }

    const data = result.message;

    this._logger(
      Logging.LEVELS.DEBUG,
      `gUList - retrieved from API ${JSON.stringify(data)}`
    );
    this._sStorage.setItem(SSTOKEYS.usersList, JSON.stringify(data));

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GULIST_SUCCESS.code,
      message: data,
    });
  }

  async getSellReport(
    initDate,
    endDate,
    opts = { username: "", sellType: "" }
  ) {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gSellReport - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    let reqData = {
      date_init: initDate,
      date_end: endDate,
    };
    if (opts.username) {
      reqData.from_user = opts.username;
    }
    if (opts.sellType) {
      reqData.source_type = opts.sellType;
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/sellreport", {
        method: HTTP_METHODS.GET,
        params: reqData,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GSREP_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GSREP_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GSREP_SUCCESS.code,
      message: result.message,
    });
  }

  /**
   * Execute balance transfer to users below the current user.
   * @param {string} toUsername The user that will receive the transfer
   * @param {Decimal} amount Balance to transfer
   * @param {string} type topup/services
   * @param {string} pin  Security PIN
   * @param {string} comment 25 char comment
   *
   * @async
   * @public
   * @function
   */
  async doBalanceTransfer(toUsername, amount, type, pin, comment) {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "dBalTrans - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    switch (type) {
      case "topup":
        type = "TopUp";
        break;
      case "services":
        type = "Services";
        break;
      default:
        throw this._buildError(RESPONSES.DBALTRANS_WRONGTYPE);
    }

    const reqData = {
      to_username: toUsername,
      amount: amount,
      balance_type: type,
      pin: pin,
      comment: comment,
    };

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/balance/transfer", {
        method: HTTP_METHODS.POST,
        params: reqData,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.DBALTRANS_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.DBALTRANS_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.DBALTRANS_SUCCESS.code,
      message: result.message,
    });
  }

  async setDistributorPortalData(
    data = {
      loginImageBase64: "",
      faviconBase64: "",
      logo192Base64: "",
      stylingItems: {},
    }
  ) {
    if (!this.isLoggedIn()) {
      this._logger(
        Logging.LEVELS.DEBUG,
        "sDistPortalData - User not logged in"
      );
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const params = { portal_data: {} };
    if (data.loginImageBase64) {
      params.portal_data.login_image = data.loginImageBase64;
    }
    if (data.faviconBase64) {
      params.portal_data.favicon = data.faviconBase64;
    }
    if (data.logo192Base64) {
      params.portal_data.logo192 = data.logo192Base64;
    }
    if (data.stylingItems) {
      params.portal_data.styling_items = data.stylingItems;
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/distributor/portaldata", {
        method: HTTP_METHODS.POST,
        params: params,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.SDISTPDATA_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.SDISTPDATA_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.SDISTPDATA_SUCCESS.code,
      message: result.message,
    });
  }

  async newPassword() {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "newPassword - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/password", {
        method: HTTP_METHODS.POST,
        params: {},
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.NEWPASS_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.NEWPASS_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.NEWPASS_SUCCESS.code,
      message: result.message,
    });
  }

  async newPIN() {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "newPIN - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/user/pin", {
        method: HTTP_METHODS.POST,
        params: {},
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.NEWPIN_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.NEWPIN_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.NEWPIN_SUCCESS.code,
      message: result.message,
    });
  }

  async getRecargakiMessages() {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gPortalMsg - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/recargaki/messages", {
        method: HTTP_METHODS.GET,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GRGKIMSG_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GRGKIMSG_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GRGKIMSG_SUCCESS.code,
      message: result.message,
    });
  }

  async getDistributorMessages() {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "gDistMsg - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/distributor/messages", {
        method: HTTP_METHODS.GET,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.GDISTMSG_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.GDISTMSG_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.GDISTMSG_SUCCESS.code,
      message: result.message,
    });
  }

  async setDistributorMessage(title, message) {
    if (!this.isLoggedIn()) {
      this._logger(Logging.LEVELS.DEBUG, "sDistMsg - User not logged in");
      throw this._buildError(RESPONSES.NOLOGIN); // MUST logout
    }

    try {
      await this.refreshAccessToken();
    } catch (e) {
      throw this._buildError({
        // MUST logout
        code: RESPONSES.REFRESHERROR.code,
        message: e.message,
      });
    }

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/distributor/messages", {
        method: HTTP_METHODS.POST,
        params: { title: title, message: message },
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: this.username + "|" + this.getAccessToken(),
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.SDISTMSG_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.SDISTMSG_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.SDISTMSG_SUCCESS.code,
      message: result.message,
    });
  }

  /**
   * Creates a Point of Sale under Distributor User
   * @param {string} posUsername username of the POS to update or create
   * @param {Object} data       Complementary info about the POS
   * @param {string} data.companyName     defaults to blank
   * @param {string} data.email           defaults to blank
   * @param {string} data.contactName     defaults to blank
   * @param {string} data.phoneNumber     defaults to blank
   * @param {string} data.mobileNumber    defaults to blank
   * @param {string} data.city            defaults to blank
   * @param {string} data.state           defaults to blank
   * @param {string} data.streetAndNumber defaults to blank
   * @param {string} data.colony          defaults to blank
   * @param {string} data.postalCode      defaults to blank
   * @param {string} data.recaptchaToken  defaults to blank
   * @returns {Promise<RecargakiClientResponse>}
   *
   * @async
   * @public
   * @function
   */
  async createDefaultUser(
    posUsername,
    data = {
      companyName: "",
      email: "",
      contactName: "",
      phoneNumber: "",
      mobileNumber: "",
      city: "",
      state: "",
      streetAndNumber: "",
      colony: "",
      postalCode: "",
      recaptchaToken: "",
    }
  ) {
    const parameters = {
      username: posUsername,
      company_name: data.companyName,
      email: data.email,
      contact_name: data.contactName,
      phone_number: data.phoneNumber,
      mobile_number: data.mobileNumber,
      city: data.city,
      state: data.state,
      street_and_number: data.streetAndNumber,
      colony: data.colony,
      postal_code: data.postalCode,
      recaptcha_token: data.recaptchaToken,
    };

    const restCli = new RESTClient(AppData.baseURL, {
      debug: DEBUG_APICALLS,
    });
    let res = new RESTClientResponse({});
    try {
      res = await restCli.request("/distributor/pos", {
        method: HTTP_METHODS.POST,
        params: parameters,
        credentials: {
          username: AppData.appName + "|" + AppData.appToken,
          password: window.location.hostname + "|_",
        },
      });
    } catch (e) {
      // Class RESTClientResponse
      const errData = parseRecargakiError(e);
      throw this._buildError({
        code: errData.code,
        message:
          RESPONSES.CDEFTUSER_APIERROR.message +
          `| (${e.httpCode}) ` +
          errData.message,
      });
    }

    const result = parseRecargakiResult(res);
    const success = result.success;

    if (!success) {
      throw this._buildError({
        code: result.errorCode,
        message: RESPONSES.CDEFTUSER_APIERROR.message + result.message,
      });
    }

    return new RecargakiClientResponse({
      success: true,
      errorCode: RESPONSES.CDEFTUSER_SUCCESS.code,
      message: result.message,
    });
  }
}

export default User;
