import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {
  const id = props.id || "toggle-switch-new";
  const name = props.name || "toggle-switch-new";
  return (
    <>
      <input
        className="toggle-switch-checkbox"
        id={id}
        name={name}
        type="checkbox"
        disabled={props.disabled}
      />
      <label className="toggle-switch-label" htmlFor={id}>
        <span className={`toggle-switch-button`} />
      </label>
    </>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;
