import React from "react";
import PropTypes from "prop-types";

import BalanceTransferForm from "./BalanceTransferForm";

class BalanceTransfer extends React.Component {
  render = () => {
    return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Transferencia de Saldo</h1>
        </div>

        <div className="row">
          <BalanceTransferForm
            usersList={this.props.usersList}
            updateBalances={this.props.updateBalances}
            showSnackbar={this.props.showSnackbar}
            userObj={this.props.userObj}
            userData={this.props.userData}
          />
        </div>
      </>
    );
  };
}

BalanceTransfer.propTypes = {
  usersList: PropTypes.array,
  updateBalances: PropTypes.func,
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  userData: PropTypes.object,
};

export default BalanceTransfer;
