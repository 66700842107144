import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login/Login";
import MainPortal from "./components/MainPortal/MainPortal";
import Themer, { DEFAULT, buildThemeClasses } from "./objects/Themer";
import "./App.css";

export const PATHS = {
  root: "/",
  login: "/ingresar",
  app: "/portal",
  repdep: "/portal/reportar-depositos",
  sellrep: "/portal/reportes-ventas",
  pos: "/portal/pdvs",
  baltrans: "/portal/transferir-saldo",
  distibutor: "/portal/distribuidor",
  editinfo: "/portal/miinfo",
};

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loginImage: DEFAULT.loginImageURL,
      distributorName: DEFAULT.distributorName,
      themeClasses: buildThemeClasses(DEFAULT.stylingItems),
    };
  }

  componentDidMount = async () => {
    const debugThemer = false;
    const themer = new Themer(debugThemer);
    try {
      const loginImage = await themer.getLoginImage();
      const distName = await themer.getDistributorName();
      const stylingItems = await themer.getThemeClasses();
      this.setState({
        loginImage: loginImage,
        distributorName: distName,
        themeClasses: stylingItems,
      });

      const faviconElem = document.getElementById('favicon');
      faviconElem.href = await themer.getFavicon();
      const logo192Elem = document.getElementById('apple-touch-icon');
      logo192Elem.href = await themer.getLogo192();
    } catch (err) {
      console.log("Loader - failed to load portal data: " + err.message);
    }
  };

  render() {
    return (
      <>
        <style type="text/css">{this.state.themeClasses}</style>
        <Router>
          <div>
            <Switch>
              <Route path={PATHS.app}>
                <MainPortal distributorName={this.state.distributorName} />
              </Route>
              <Route path={PATHS.root} exact={true}>
                <Login
                  image={this.state.loginImage}
                  name={this.state.distributorName}
                />
              </Route>
              <Route path={PATHS.login} exact={true}>
                <Login
                  image={this.state.loginImage}
                  name={this.state.distributorName}
                />
              </Route>
            </Switch>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
