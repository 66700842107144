import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { PhotoshopPicker } from "react-color";
import { withRouter } from "react-router-dom";

import * as Logging from "../../../../objects/Logging";
import { checkAndLogout } from "../../../../objects/User";
import Themer, {
  DEFAULT,
  colorObjToHTML,
  colorHTMLtoObj,
} from "../../../../objects/Themer";

import "./PortalEditionForm.css";

const LOG_LEVEL = Logging.LEVELS.INFO;

const SUCCESS_SUFIX = "Recargue la página para ver los cambios";
const FILE_MAX_SIZE_KB = 1000;
const ERRORS = {
  filesize: "Imagen demasiado grande",
};

const ColorInput = (props) => {
  const [pickerColor, setPickerColor] = useState(props.color);
  const containerID = "colorSetter-" + props.name;
  const closePicker = () => props.showColorPicker("");
  const fakeEventWithValue = (value) => ({
    preventDefault: () => {},
    target: {
      name: props.name,
      value: value,
    },
  });
  return (
    <div className="color-input">
      <div className="title">{props.title}</div>
      <div
        className="box"
        onClick={() => {
          setPickerColor(props.color);
          props.showColorPicker(containerID);
        }}
      >
        <div
          className="sample"
          style={{ backgroundColor: props.color || "" }}
        ></div>
        <input
          type="text"
          className="form-control color-control"
          id={props.name}
          name={props.name}
          value={props.color}
          onChange={props.onChange}
        />
      </div>
      {props.colorPickerSet === containerID ? (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <PhotoshopPicker
            color={pickerColor}
            header={props.title}
            onChangeComplete={(color) => setPickerColor(color.hex)}
            onAccept={() => {
              props.onChange(fakeEventWithValue(pickerColor));
              closePicker();
            }}
            onCancel={closePicker}
          />
        </div>
      ) : null}
    </div>
  );
};

ColorInput.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
  colorPickerSet: PropTypes.string,
  showColorPicker: PropTypes.func,
};

class PortalEditionForm extends React.Component {
  constructor(props) {
    super(props);
    this.initFormData = {
      distMessageTitle: "",
      distMessage: "",
      distMessageMetadata: {
        titleMaxLength: 50,
        messageMaxLength: 300,
      },
      loginImage: {
        fileName: "",
        mimeType: "",
        base64Data: "",
      },
      favicon: {
        fileName: "",
        mimeType: "",
        base64Data: "",
      },
      logo192: {
        fileName: "",
        mimeType: "",
        base64Data: "",
      },
      stylingItems: DEFAULT.stylingItems,
    };
    this.state = {
      processing: false,
      imageURL: "",
      faviconURL: "",
      logo192URL: "",
      colorPickerPosition: "",
      formData: this.initFormData,
    };
    this._logger = Logging.Logger(
      props.debug ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "PortalEditionForm class"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
  }

  _loadPortalData = async (forceUpdate = false) => {
    try {
      const themer = new Themer(this.props.debug);
      const portalData = await themer.getPortalData(forceUpdate);

      const formData = this.initFormData;
      formData.stylingItems = portalData.stylingItems;

      const distMessageData = await this.props.userObj.getDistributorMessages();
      distMessageData.message.forEach((msgObj) => {
        formData.distMessage = msgObj.Message;
        formData.distMessageTitle = msgObj.Title;
        formData.distMessageMetadata.messageMaxLength =
          msgObj.Metadata.MessageMaxLength;
        formData.distMessageMetadata.titleMaxLength =
          msgObj.Metadata.TitleMaxLength;
      });

      this.setState({
        imageURL: portalData.loginImageURL,
        faviconURL: portalData.faviconURL,
        logo192URL: portalData.logo192URL,
        formData: formData,
      });
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Failed to load portal data: ${e.message}`
      );
    }
  };

  componentDidMount = () => {
    this._loadPortalData(true);
  };

  _restoreColors = () =>
    this.setState({
      formData: {
        ...this.state.formData,
        stylingItems: DEFAULT.stylingItems,
      },
    });

  showColorPicker = (pickerID) =>
    this.setState({ colorPickerPosition: pickerID });

  _handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    let stylingItems = [];
    if (name !== "loginImage") {
      stylingItems = name.split("-");
      name = stylingItems.slice(-1)[0];
    }

    let stateKey;
    switch (name) {
      case "shadow":
      case "shadowHTML": {
        value = value.trim();
        value = {
          ...this.state.formData.stylingItems,
          // stylingItems[0] could be primary|secondary|light etc
          [stylingItems[0]]: {
            ...this.state.formData.stylingItems[stylingItems[0]],
            // name = stylingItems[1], leaving it as variable to be able
            // to handle future new cases of r, g, b separated values
            [name]: colorHTMLtoObj(value),
            [name + "HTML"]: value,
          },
        };
        stateKey = "stylingItems";
        break;
      }
      case "loginImage":
      case "favicon":
      case "logo192": {
        value = event.target.files[0];
        if (value.size / 1024 > FILE_MAX_SIZE_KB) {
          event.target.value = null;
          this.props.showSnackbar(ERRORS.filesize);
          return;
        }
        const reader = new FileReader();

        reader.onload = () => {
          // DataURL format: "data:<mimeType>;base64,<base64Data>"
          const base64Data = reader.result.split(",")[1];
          this._logger(
            Logging.LEVELS.DEBUG,
            `File loaded:\n${value.name}\n${value.type}\n${base64Data}`
          );
          this.setState({
            formData: {
              ...this.state.formData,
              [name]: {
                fileName: value.name,
                mimeType: value.type,
                base64Data: base64Data,
              },
            },
          });
        };
        reader.onerror = () =>
          this.props.showSnackbar("Imagen no cargada " + reader.error);
        reader.onloadstart = () => this.setState({ processing: true });
        reader.onloadend = () => this.setState({ processing: false });
        // Start file reading
        reader.readAsDataURL(value);
        return;
      }
      case "distMessageTitle":
      case "distMessage":
        stateKey = name;
        break;
      default: {
        // Normal Color Inputs
        value = value.trim();
        value = value.replace(/[^a-fA-F0-9]/, "");
        value = "#" + value.slice(0, 6);
        const prevColorData = this.state.formData.stylingItems[stylingItems[0]];
        const innerObject =
          stylingItems.length === 2
            ? {
                // name = stylingItems[1], stylingItems[0] could be primary|secondary|light etc
                ...prevColorData,
                [name]: value,
              }
            : {
                // name = stylingItems[2], stylingItems[1] most likely is "disabled"
                // leaving it as variable to be able to handle new cases with this attr depth
                ...prevColorData,
                [stylingItems[1]]: {
                  ...prevColorData[stylingItems[1]],
                  [name]: value,
                },
              };
        stateKey = "stylingItems";
        value = {
          ...this.state.formData.stylingItems,
          [stylingItems[0]]: innerObject,
        };
        break;
      }
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [stateKey]: value,
      },
    });
  };

  _handleObjectsSubmit = async (event) => {
    event.preventDefault();
    const data = this.state.formData;

    this.setState({ processing: true });

    let message = "";
    try {
      const res = await this.props.userObj.setDistributorPortalData({
        loginImageBase64: data.loginImage.base64Data,
        faviconBase64: data.favicon.base64Data,
        logo192Base64: data.logo192.base64Data,
        stylingItems: data.stylingItems,
      });
      this._logger(
        Logging.LEVELS.DEBUG,
        `Distributor Portal Data changed: ${JSON.stringify(res)}`
      );
      message = res.message + " | " + SUCCESS_SUFIX;
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Distributor Portal Data change failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        message = match[1] || e.message;
      } else {
        message = e.message;
      }
    }
    this.setState({ processing: false });
    this.props.showSnackbar(message);
    this._loadPortalData(true);
  };

  _objectsSubmitButton = () =>
    this.state.processing ? (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Procesando...</span>
      </div>
    ) : (
      <button type="submit" className="btn btn-primary" id="setData">
        Guardar Cambios
      </button>
    );

  _handleMessagesSubmit = async (event) => {
    event.preventDefault();
    const data = this.state.formData;

    this.setState({ processing: true });

    let message = "";
    try {
      const res = await this.props.userObj.setDistributorMessage(
        data.distMessageTitle,
        data.distMessage
      );
      this._logger(
        Logging.LEVELS.DEBUG,
        `Distributor Message changed: ${JSON.stringify(res)}`
      );
      message = res.message + " | " + SUCCESS_SUFIX;
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Distributor Message change failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        message = match[1] || e.message;
      } else {
        message = e.message;
      }
    }
    this.setState({ processing: false });
    this.props.showSnackbar(message);
    this._loadPortalData(true);
  };

  _messagesSubmitButton = () =>
    this.state.processing ? (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Procesando...</span>
      </div>
    ) : (
      <button type="submit" className="btn btn-primary" id="setData">
        Cambiar Mensaje
      </button>
    );

  _buildColorPanel = (title, colorName, colorItems) => (
    <div key={title}>
      <hr className="separator" />

      <div className="text-center my-4 font-weight-bold">{title}</div>

      <div
        className="d-flex justify-content-center"
        id={colorName + "-color-elements"}
      >
        <div className="col-2" id="p-examples">
          <div className="d-flex">
            <Button variant={colorName} className="my-2 mx-auto">
              Activo
            </Button>
          </div>
          <div className="d-flex">
            <Button
              variant={colorName}
              className="my-2 mx-auto"
              disabled={true}
            >
              Desactivado
            </Button>
          </div>
          <div className="d-flex">
            <div
              className={
                "rectangle-gradient bg-gradient-" + colorName + " my-4 mx-auto"
              }
            ></div>
          </div>
        </div>

        <div className="col-4" id="p-inputs">
          <div className="row">
            <ColorInput
              title="Principal"
              name={colorName + "-main"}
              color={colorItems.main}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
            <ColorInput
              title="Texto"
              name={colorName + "-text"}
              color={colorItems.text}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
          </div>
          <div className="row">
            <ColorInput
              title="Hover"
              name={colorName + "-background"}
              color={colorItems.background}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
            <ColorInput
              title="Hover Borde"
              name={colorName + "-border"}
              color={colorItems.border}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
          </div>
          <div className="row">
            <ColorInput
              title="Presionado"
              name={colorName + "-disabled-background"}
              color={colorItems.disabled.background}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
            <ColorInput
              title="Pres. Borde"
              name={colorName + "-disabled-border"}
              color={colorItems.disabled.border}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
          </div>
          <div className="row">
            <ColorInput
              title="Seleccionado"
              name={colorName + "-shadow"}
              color={colorItems.shadowHTML || colorObjToHTML(colorItems.shadow)}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
            <ColorInput
              title="Gradiente"
              name={colorName + "-toGradient"}
              color={colorItems.toGradient}
              onChange={this._handleChange}
              colorPickerSet={this.state.colorPickerPosition}
              showColorPicker={this.showColorPicker}
            />
          </div>
        </div>
      </div>
    </div>
  );

  render = () => {
    const colorPanels = [];
    Object.entries(this.state.formData.stylingItems).forEach(
      ([colorName, colorData]) => {
        let title;
        switch (colorName) {
          case "primary":
            title = "Color Primario";
            break;
          case "secondary":
            title = "Color Secundario";
            break;
          case "light":
            title = "Color Ligero";
            break;
          default:
            break;
        }
        colorPanels.push(this._buildColorPanel(title, colorName, colorData));
      }
    );
    return (
      <>
        <div className="row">
          <div className="mx-auto pb-3" id="distMessageForm">
            <form autoComplete="off" onSubmit={this._handleMessagesSubmit}>
              <h4 className="text-center my-4">Mensaje del Distribuidor</h4>

              <div className="mx-auto pb-3" style={{ width: "150px" }}>
                {this._messagesSubmitButton()}
              </div>

              <div className="form-group my-2 d-flex align-items-center justify-content-center text-center">
                <label htmlFor="distriMessage" className="mx-2 my-2">
                  Título
                </label>
                <input
                  type="text"
                  id="distMessageTitle"
                  name="distMessageTitle"
                  className="form-control distmsgtitle-control"
                  onChange={this._handleChange}
                  value={this.state.formData.distMessageTitle}
                  maxLength={
                    this.state.formData.distMessageMetadata.titleMaxLength
                  }
                />
              </div>

              <div className="form-group my-2 d-flex align-items-center justify-content-center text-center">
                <label htmlFor="distriMessage" className="mx-2 my-2">
                  Mensaje
                </label>
                <textarea
                  id="distMessage"
                  name="distMessage"
                  className="form-control distmsg-control"
                  onChange={this._handleChange}
                  value={this.state.formData.distMessage}
                  maxLength={
                    this.state.formData.distMessageMetadata.messageMaxLength
                  }
                  rows={5}
                />
              </div>
            </form>
          </div>
        </div>

        <hr className="separator" />

        <div className="row">
          <div className="mx-auto pb-3" id="loginImageForm">
            <form autoComplete="off" onSubmit={this._handleObjectsSubmit}>
              <h4 className="text-center my-4">Objetos del Portal</h4>

              <div className="mx-auto" style={{ width: "150px" }}>
                {this._objectsSubmitButton()}
              </div>

              <hr className="separator" />

              <div>
                <Image
                  src={this.state.imageURL}
                  thumbnail
                  fluid
                  className="w-50 my-4 mx-auto d-block"
                />
              </div>

              <div className="form-group my-2 d-flex align-items-center justify-content-center text-center">
                <label htmlFor="loginImage" className="mx-2 my-2">
                  Nueva Imagen de Login
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="loginImage"
                  name="loginImage"
                  onChange={this._handleChange}
                  style={{ width: "350px" }}
                />
              </div>

              <hr className="separator" />

              <div>
                <Image
                  src={this.state.faviconURL}
                  thumbnail
                  fluid
                  className="favicon-display my-4 mx-auto d-block"
                />
              </div>

              <div className="form-group my-2 d-flex align-items-center justify-content-center text-center">
                <label htmlFor="favicon" className="mx-2 my-2">
                  Favicon del Portal
                  <br />
                  <span className="small text-muted">Tamaño: 32x32</span>
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="favicon"
                  name="favicon"
                  onChange={this._handleChange}
                  style={{ width: "350px" }}
                />
              </div>

              <hr className="separator" />

              <div>
                <Image
                  src={this.state.logo192URL}
                  thumbnail
                  fluid
                  className="logo192-display my-4 mx-auto d-block"
                />
              </div>

              <div className="form-group my-2 d-flex align-items-center justify-content-center text-center">
                <label htmlFor="logo192" className="mx-2 my-2">
                  Logo Acceso Directo
                  <br />
                  <span className="small text-muted">Tamaño: 192x192</span>
                </label>
                <input
                  type="file"
                  className="form-control-file"
                  id="logo192"
                  name="logo192"
                  onChange={this._handleChange}
                  style={{ width: "350px" }}
                />
              </div>

              {colorPanels}

              <hr className="separator" />

              <div className="form-group my-2 d-flex align-items-center justify-content-center text-center">
                <Button variant="secondary" onClick={this._restoreColors}>
                  Poner Colores
                  <br />
                  Originales
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
}

PortalEditionForm.propTypes = {
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  debug: PropTypes.bool,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

PortalEditionForm.defaultProps = {
  debug: false,
};

export default withRouter(PortalEditionForm);
