function _asInt(level) {
  switch (level) {
    case "ERROR":
      return 0;
    case "WARNING":
      return 1;
    case "INFO":
      return 2;
    case "DEBUG":
      return 3;
    default:
      return 9999;
  }
}

/**
 * Debug Levels Enumeration Object
 */
export const LEVELS = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  DEBUG: "DEBUG",
  isLowerOrEqualThan: (low, high) => _asInt(low) <= _asInt(high),
};

/**
 * Builds a Logger function which prints to Console
 * if the debug level is lower or equal to <setDebugLevel>
 * @param {LEVELS} setDebugLevel
 * @param {string} prefix
 *
 * @constructor
 */
export const Logger = (setDebugLevel, prefix) => (debugLevel, message) => {
  if (LEVELS.isLowerOrEqualThan(debugLevel, setDebugLevel)) {
    console.log(`${debugLevel} | ${prefix}: ${message}`);
  }
};
