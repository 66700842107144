import * as Logging from "./Logging";
import { API_DOMAIN, DEV_ENVIRONMENT } from "../constants/Constants";
import { RESTClient, RESTClientResponse, HTTP_METHODS } from "./RESTClient";
import {
  AppData,
  parseRecargakiError,
  parseRecargakiResult,
} from "./RecargakiClient";

const DEBUG_APICALLS = false;
const LOG_LEVEL = Logging.LEVELS.INFO;
const PORTALDATA_UPDINTERVAL = 1 * (DEV_ENVIRONMENT ? 1000 : 5000);

const STO_PREFIX = AppData.appName + "-";
const KEYS = {
  domain: STO_PREFIX + "Domain",
  default: {
    theme: STO_PREFIX + "DefaultTheme",
  },
  distributorName: STO_PREFIX + "DistributorName",
  loginImageURL: STO_PREFIX + "LoginImageURL",
  faviconURL: STO_PREFIX + "FaviconURL",
  logo192URL: STO_PREFIX + "Logo192URL",
  stylingItems: STO_PREFIX + "StylingItems",
  themeClasses: STO_PREFIX + "ThemeClasses",
  themeClassesTS: STO_PREFIX + "ThemeClassesTimestamp",
  portalDataTS: STO_PREFIX + "PortalDataTimestamp",
};

export const DEFAULT = {
  loginImageBaseURL: "https://" + API_DOMAIN + "/distriobjects/loginImages/",
  iconImageBaseURL: "https://" + API_DOMAIN + "/distriobjects/icons/",
  loginImageURL: "",
  faviconURL: "/favicon.ico",
  logo192URL: "/logo192.png",
  distributorName: "Dynamic Distributor Name",
  stylingItems: {
    primary: {
      main: "#4e73df",
      toGradient: "#224abe",
      background: "#2e59d9",
      text: "#fff",
      border: "#2653d4",
      shadow: {
        r: "105",
        g: "136",
        b: "228",
      },
      disabled: {
        background: "#2653d4",
        border: "#244ec9",
      },
    },
    secondary: {
      main: "#858796",
      toGradient: "#60616f",
      background: "#717384",
      text: "#fff",
      border: "#6b6d7d",
      shadow: {
        r: "151",
        g: "153",
        b: "166",
      },
      disabled: {
        background: "#6b6d7d",
        border: "#656776",
      },
    },
    light: {
      main: "#f8f9fc",
      toGradient: "#c2cbe5",
      background: "#dde2f1",
      text: "#3a3b45",
      border: "#d4daed",
      shadow: {
        r: "220",
        g: "221",
        b: "225",
      },
      disabled: {
        background: "#d4daed",
        border: "#cbd3e9",
      },
    },
  },
};

export const colorObjToHTML = ({ r, g, b }) =>
  "#" +
  parseInt(r).toString(16).padStart(2, "0") +
  parseInt(g).toString(16).padStart(2, "0") +
  parseInt(b).toString(16).padStart(2, "0");

export const colorHTMLtoObj = (htmlColor) => {
  htmlColor = htmlColor.replace(/[^a-fA-F0-9]/, "");
  const hexToNumString = (hex) => (parseInt(hex, 16) || 0).toString();
  return {
    r: hexToNumString(htmlColor.slice(0, 2)),
    g: hexToNumString(htmlColor.slice(2, 4)),
    b: hexToNumString(htmlColor.slice(4, 6)),
  };
};

export const buildThemeClasses = (colors) => `
  .btn-primary {
    color: ${colors.primary.text};
    background-color: ${colors.primary.main};
    border-color: ${colors.primary.main};
  }

  .btn-primary:hover {
    color: ${colors.primary.text};
    background-color: ${colors.primary.background};
    border-color: ${colors.primary.border};
  }

  .btn-primary:focus,
  .btn-primary.focus {
    color: ${colors.primary.text};
    background-color: ${colors.primary.background};
    border-color: ${colors.primary.border};
    box-shadow: 0 0 0 0.2rem rgba(${colors.primary.shadow.r}, ${colors.primary.shadow.g}, ${colors.primary.shadow.b}, 0.5);
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: ${colors.primary.text};
    background-color: ${colors.primary.main};
    border-color: ${colors.primary.main};
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: ${colors.primary.text};
    background-color: ${colors.primary.disabled.background};
    border-color: ${colors.primary.disabled.border};
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(${colors.primary.shadow.r}, ${colors.primary.shadow.g}, ${colors.primary.shadow.b}, 0.5);
  }

  .btn-secondary {
    color: ${colors.secondary.text};
    background-color: ${colors.secondary.main};
    border-color: ${colors.secondary.main};
  }

  .btn-secondary:hover {
    color: ${colors.secondary.text};
    background-color: ${colors.secondary.background};
    border-color: ${colors.secondary.border};
  }

  .btn-secondary:focus,
  .btn-secondary.focus {
    color: ${colors.secondary.text};
    background-color: ${colors.secondary.background};
    border-color: ${colors.secondary.border};
    box-shadow: 0 0 0 0.2rem rgba(${colors.secondary.shadow.r}, ${colors.secondary.shadow.g}, ${colors.secondary.shadow.b}, 0.5);
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    color: ${colors.secondary.text};
    background-color: ${colors.secondary.main};
    border-color: ${colors.secondary.main};
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: ${colors.secondary.text};
    background-color: ${colors.primary.disabled.background};
    border-color: ${colors.primary.disabled.border};
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(${colors.secondary.shadow.r}, ${colors.secondary.shadow.g}, ${colors.secondary.shadow.b}, 0.5);
  }

  .btn-light {
    color: ${colors.light.text};
    background-color: ${colors.light.main};
    border-color: ${colors.light.main};
  }

  .btn-light:hover {
    color: ${colors.light.text};
    background-color: ${colors.light.background};
    border-color: ${colors.light.border};
  }

  .btn-light:focus,
  .btn-light.focus {
    color: ${colors.light.text};
    background-color: ${colors.light.background};
    border-color: ${colors.light.border};
    box-shadow: 0 0 0 0.2rem rgba(${colors.light.shadow.r}, ${colors.light.shadow.g}, ${colors.light.shadow.b}, 0.5);
  }

  .btn-light.disabled,
  .btn-light:disabled {
    color: ${colors.light.text};
    background-color: ${colors.light.main};
    border-color: ${colors.light.main};
  }

  .btn-light:not(:disabled):not(.disabled):active,
  .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: ${colors.light.text};
    background-color: ${colors.light.disabled.background};
    border-color: ${colors.light.disabled.border};
  }

  .btn-light:not(:disabled):not(.disabled):active:focus,
  .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(${colors.light.shadow.r}, ${colors.light.shadow.g}, ${colors.light.shadow.b}, 0.5);
  }

  .bg-gradient-primary {
    background-color: ${colors.primary.main};
    background-image: linear-gradient(180deg, ${colors.primary.main} 10%, ${colors.primary.toGradient} 100%);
    background-size: cover;
  }

  .bg-gradient-secondary {
    background-color: ${colors.secondary.main};
    background-image: linear-gradient(180deg, ${colors.secondary.main} 10%, ${colors.secondary.toGradient} 100%);
    background-size: cover;
  }

  .bg-gradient-light {
    background-color: ${colors.light.main};
    background-image: linear-gradient(180deg, ${colors.light.main} 10%, ${colors.light.toGradient} 100%);
    background-size: cover;
  }
`;

const getter = async (domain, endpoint) => {
  const restCli = new RESTClient(AppData.baseURL, {
    debug: DEBUG_APICALLS,
  });

  let res = new RESTClientResponse({});
  try {
    res = await restCli.request(endpoint, {
      method: HTTP_METHODS.GET,
      credentials: {
        username: AppData.appName + "|" + AppData.appToken,
        password: domain + "|_",
      },
    });
  } catch (e) {
    // Class RESTClientResponse
    const errData = parseRecargakiError(e);
    const message = errData.message;
    console.log(`Themer - getter "${endpoint}" (${errData.code}): ${message}`);
    return "";
  }

  const result = parseRecargakiResult(res);
  const success = result.success;

  if (!success) {
    console.log(`Themer - getter "${endpoint}": ${result.message}`);
    return "";
  }

  return result.message;
};

class Themer {
  constructor(debug = false) {
    this._storage = window.localStorage;
    this._domain = window.location.hostname;
    this._portalData = {};

    this._logger = Logging.Logger(
      debug ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "Themer class"
    );
  }

  getPortalData = async (forceUpdate = false) => {
    // return cached data if PORTALDATA_UPDINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._storage.getItem(KEYS.portalDataTS) || "0"
    );

    if (!forceUpdate && Date.now() < lastUpdate + PORTALDATA_UPDINTERVAL) {
      this._portalData = {
        distributorName: this._storage.getItem(KEYS.distributorName),
        loginImageURL: this._storage.getItem(KEYS.loginImageURL),
        faviconURL: this._storage.getItem(KEYS.faviconURL),
        logo192URL: this._storage.getItem(KEYS.logo192URL),
        stylingItems: JSON.parse(this._storage.getItem(KEYS.stylingItems)),
      };
      this._logger(
        Logging.LEVELS.DEBUG,
        `gPortalData - portal data on cache ${JSON.stringify(this._portalData)}`
      );
      return this._portalData;
    }

    try {
      const res = await getter(this._domain, "/distributor/portaldata");
      const loginImage = DEFAULT.loginImageBaseURL + res.LoginImage;
      const favicon = res.Favicon
        ? DEFAULT.iconImageBaseURL + res.Favicon
        : DEFAULT.faviconURL;
      const logo192 = res.Logo192
        ? DEFAULT.iconImageBaseURL + res.Logo192
        : DEFAULT.logo192URL;
      this._portalData = {
        distributorName: res.DistributorName,
        loginImageURL: loginImage,
        faviconURL: favicon,
        logo192URL: logo192,
        stylingItems: res.StylingItems,
      };
      this._storage.setItem(KEYS.distributorName, res.DistributorName);
      this._storage.setItem(KEYS.loginImageURL, loginImage);
      this._storage.setItem(KEYS.faviconURL, favicon);
      this._storage.setItem(KEYS.logo192URL, logo192);
      this._storage.setItem(
        KEYS.stylingItems,
        JSON.stringify(res.StylingItems)
      );
      this._storage.setItem(KEYS.portalDataTS, Date.now().toString());
      this._logger(
        Logging.LEVELS.DEBUG,
        `gPortalData - portal data retrieved ${JSON.stringify(
          this._portalData
        )}`
      );
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `gPortalData - couldn't get portal data: (${err.code}) ${err.message}`
      );
      this._portalData = {
        distributorName: DEFAULT.distributorName,
        loginImageURL: DEFAULT.loginImageURL,
        faviconURL: DEFAULT.faviconURL,
        logo192URL: DEFAULT.logo192URL,
        stylingItems: DEFAULT.stylingItems,
      };
    }

    return this._portalData;
  };

  getThemeClasses = async (forceUpdate = false) => {
    let classes;
    const debugClassPrintLength = 300;

    // return cached data if PORTALDATA_UPDINTERVAL has not been met
    const lastUpdate = Number.parseInt(
      this._storage.getItem(KEYS.themeClassesTS) || "0"
    );

    if (!forceUpdate && Date.now() < lastUpdate + PORTALDATA_UPDINTERVAL) {
      classes = this._storage.getItem(KEYS.themeClasses);
      if (!classes) {
        this._logger(
          Logging.LEVELS.WARNING,
          `gThemeClasses - no theme classes in Storage ${classes}`
        );
        classes = buildThemeClasses(
          this._portalData && this._portalData.stylingItems
            ? this._portalData.stylingItems
            : DEFAULT.stylingItems
        );
        this._storage.setItem(KEYS.themeClasses, classes);
      }
      this._logger(
        Logging.LEVELS.DEBUG,
        `gThemeClasses - theme classes on cache ${classes.slice(
          0,
          debugClassPrintLength
        )}`
      );
      return classes;
    }

    try {
      const data = await this.getPortalData(forceUpdate);
      classes = buildThemeClasses(data.stylingItems);
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        "gThemeClasses - failed to get portal styling items: " + err.message
      );
      classes = buildThemeClasses(DEFAULT.stylingItems);
    }

    this._storage.setItem(KEYS.themeClasses, classes);
    this._storage.setItem(KEYS.themeClassesTS, Date.now().toString());

    this._logger(
      Logging.LEVELS.DEBUG,
      `gThemeClasses - theme classes retrieved ${classes.slice(
        0,
        debugClassPrintLength
      )}`
    );
    return classes;
  };

  getLoginImage = async (forceUpdate = false) => {
    let imageURL = "";
    try {
      const data = await this.getPortalData(forceUpdate);
      imageURL = data.loginImageURL;
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        "Failed to get login Image: " + err.message
      );
      imageURL = DEFAULT.loginImageURL;
    }
    return imageURL;
  };

  getDistributorName = async (forceUpdate = false) => {
    let name;
    try {
      const data = await this.getPortalData(forceUpdate);
      name = data.distributorName;
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        "Failed to get Distributor Name: " + err.message
      );
      name = DEFAULT.distributorName;
    }
    return name;
  };

  getFavicon = async (forceUpdate = false) => {
    let faviconURL;
    try {
      const data = await this.getPortalData(forceUpdate);
      faviconURL = data.faviconURL;
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        "Failed to get favicon: " + err.message
      );
      faviconURL = DEFAULT.faviconURL;
    }
    return faviconURL;
  };

  getLogo192 = async (forceUpdate = false) => {
    let logo192URL;
    try {
      const data = await this.getPortalData(forceUpdate);
      logo192URL = data.logo192URL;
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        "Failed to get logo192: " + err.message
      );
      logo192URL = DEFAULT.logo192URL;
    }
    return logo192URL;
  };
}

export default Themer;
