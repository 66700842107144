import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { checkAndLogout } from "../../../../objects/User";
import * as Logging from "../../../../objects/Logging";
import UsersListModal, { DEFAULT_OPTIONS } from "../UsersListModal";

const DEFAULT_USERNAME = DEFAULT_OPTIONS.balanceTransfer;

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

class BalanceTransferForm extends React.Component {
  constructor(props) {
    super(props);
    this.initFormData = {
      userData: { username: DEFAULT_USERNAME },
      amount: "",
      balanceType: "",
      pin: "",
      comment: "",
    };
    this.state = {
      processing: false,
      showUsersModal: false,
      formData: this.initFormData,
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "BalanceTransferForm class"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
  }

  showUsersModal = (show = true) => this.setState({ showUsersModal: show });

  setUserData = (userData) =>
    this.setState({
      formData: {
        ...this.state.formData,
        userData: userData,
      },
    });

  _handleSubmit = async (event) => {
    event.preventDefault();
    const data = this.state.formData;
    const needsPIN = !this.props.userData.Permissions["DisableAuthPIN"];
    const amount = data.amount.trim();
    const comment = data.comment.trim();
    const pin = data.pin ? data.pin.trim() : "";

    if (data.userData.username === DEFAULT_USERNAME) {
      this.props.showSnackbar("Seleccione un Destino");
      return;
    }

    if (!/^\d{1,9}(\.\d{1,2})?$/.test(amount)) {
      this.props.showSnackbar("Monto Incorrecto");
      return;
    }

    if (needsPIN && !/^\d{6}$/.test(pin)) {
      this.props.showSnackbar("PIN debe de ser 6 dígitos");
      return;
    }

    this.setState({ processing: true });

    let message;
    try {
      const res = await this.props.userObj.doBalanceTransfer(
        data.userData.username,
        amount,
        data.balanceType,
        pin,
        comment
      );
      message = res.message;
      this.props.updateBalances();
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Balance Transfer failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        message = match[1] || e.message;
      } else {
        message = e.message;
      }
    }
    this.props.showSnackbar(message);
    this.setState({ processing: false, formData: this.initFormData });
  };

  _handleChange = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "amount":
        value = value.trim();
        value = value.replace(/[^\d.]/, "");
        break;
      default:
        break;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };

  _pinInput = (pinNeeded) =>
    pinNeeded ? (
      <div className="form-group d-flex mx-auto justify-content-center">
        <input
          id="pin"
          name="pin"
          type="password"
          className="form-control"
          placeholder="PIN"
          onChange={this._handleChange}
          value={this.state.formData.pin}
          maxLength="6"
          style={{ width: "6em" }}
        />
      </div>
    ) : null;

  render = () => {
    const inputDisabled = this.state.processing;
    const needsPIN = !this.props.userData.Permissions["DisableAuthPIN"];
    return (
      <div className="mx-auto pb-3" id="repDepForm">
        <form autoComplete="off" onSubmit={this._handleSubmit}>
          <div className="form-group">
            <label className="mx-1 my-2">Al Usuario</label>
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={() => this.showUsersModal(true)}
            >
              {this.state.formData.userData.username}
            </button>
          </div>

          <div className="form-group">
            <label htmlFor="amount">Monto</label>
            <input
              id="amount"
              name="amount"
              type="text"
              className="form-control"
              placeholder="Monto"
              onChange={this._handleChange}
              value={this.state.formData.amount}
              disabled={inputDisabled}
              maxLength="12"
            />
          </div>

          <div className="form-group">
            <div>
              <label htmlFor="balanceType">Tipo de Saldo</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="balanceType"
                id="topupRadio"
                value="topup"
                onChange={this._handleChange}
                checked={this.state.formData.balanceType === "topup"}
                disabled={inputDisabled}
              />
              <label className="form-check-label" htmlFor="topupRadio">
                Recargas
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="balanceType"
                id="serviceRadio"
                value="services"
                onChange={this._handleChange}
                checked={this.state.formData.balanceType === "services"}
                disabled={inputDisabled}
              />
              <label className="form-check-label" htmlFor="serviceRadio">
                Servicios
              </label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="comment">Comentario</label>
            <input
              id="comment"
              name="comment"
              type="text"
              className="form-control"
              placeholder="Comentarios"
              onChange={this._handleChange}
              value={this.state.formData.comment}
              disabled={inputDisabled}
              maxLength="25"
            />
          </div>

          {this._pinInput(needsPIN)}

          <br />

          <div className="mx-auto" style={{ width: "150px" }}>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={inputDisabled}
            >
              Transferir
            </button>
          </div>
        </form>

        <UsersListModal
          show={this.state.showUsersModal}
          onHide={() => this.showUsersModal(false)}
          usersList={this.props.usersList}
          setUserData={this.setUserData}
          defaultOption={DEFAULT_USERNAME}
          showEmployees={false}
        />
      </div>
    );
  };
}

BalanceTransferForm.propTypes = {
  usersList: PropTypes.array,
  updateBalances: PropTypes.func,
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  userData: PropTypes.object,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(BalanceTransferForm);
