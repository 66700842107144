import React from "react";
import PropTypes from "prop-types";
import COLORS from "../../../../objects/Brands";

function ProductMiniCard(props) {
  let symbol, productType;
  const productData = {
    type: props.type,
    fullName: props.fullName,
    shortName: props.shortName,
    canonName:
      props.shortName.split("-").length <= 1
        ? props.shortName
        : props.shortName.split("-")[0],
  };
  switch (productData.type) {
    case "services":
      productData.fullName = props.fullName;
      productType = "Pagos";
      symbol = "fa-barcode";
      break;
    case "normal":
      productData.fullName = props.fullName;
      productType = "Saldo";
      symbol = "fa-coins";
      break;
    case "pins":
      productData.fullName = props.fullName;
      productType = "PINS";
      symbol = "fa-coins";
      break;
    default:
      productData.fullName = "Espere";
      productType = "Cargando";
      symbol = "fa-coins";
      break;
  }
  symbol = `fas ${symbol} fa-2x text-gray-600`;
  const style = {
    borderLeftStyle: "solid",
    borderLeftWidth: "0.25rem",
    borderLeftColor: COLORS[productData.canonName] || COLORS.default,
  };
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <div role="button" className="card shadow h-100 py-2" style={style}>
        <div
          onClick={(e) => props.handle(e, productData)}
          className="card-body"
          aria-expanded="false"
          aria-controls="sellForm"
        >
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div
                className="text-xs font-weight-bold text-primary text-uppercase mb-1"
                aria-label="product-name"
              >
                {productData.fullName}
              </div>
              <div
                className="h5 mb-0 font-weight-bold text-gray-800"
                aria-label="product-type"
              >
                {productType}
              </div>
            </div>
            <div className="col-auto" aria-hidden="true">
              <i className={symbol}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductMiniCard.propTypes = {
  handle: PropTypes.func,
  fullName: PropTypes.string,
  type: PropTypes.string,
  shortName: PropTypes.string,
};

export default ProductMiniCard;
