import React from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
//import $ from "jquery";

import * as Logging from "../../objects/Logging";
import User from "../../objects/User";
import {
  countryStates,
  defaultSelectedState,
  regexCharBlacklist,
} from "../MainPortal/components/POSRoute/UserEditForm";
import "./SignForm.css";

const DEBUG_MODE = true;
const LOG_LEVEL = Logging.LEVELS.INFO;

const FORM_ERRORS = {
  companyName: "Escriba una Razón Social",
  email: "Escriba un email",
  username: "El Nombre de Usuario debe de ser de 10 a 20 dígitos",
  contactName: "Escriba un nombre del Contacto",
  phoneNumber: "Teléfono debe de ser de 10 dígitos",
  mobileNumber: "Celular debe de ser de 10 dígitos",
  city: "Escriba una Ciudad",
  state: "Seleccione un Estado",
  streetAndNumber: "Escriba Calle y Número",
  colony: "Escriba la Colonia",
  postalCode: "Código Postal debe de ser de 5 dígitos",
  recaptcha: "Resuelva primero el Captcha",
};

class SignForm extends React.Component {
  constructor() {
    super();
    this.initForm = {
      username: "",
      companyName: "",
      email: "",
      contactName: "",
      phoneNumber: "",
      city: "",
      state: defaultSelectedState,
      postalCode: "",
      mobileNumber: "",
      colony: "",
      streetAndNumber: "",
    };
    this.state = {
      form: this.initForm,
      processing: false,
      resultMessage: "",
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "SignForm class"
    );
  }

  _onChange = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "companyName":
      case "contactName":
        value = value.replace(regexCharBlacklist.name, "");
        break;
      case "username":
        value = value.replace(regexCharBlacklist.username, "");
        value = value.trim();
        break;
      case "email":
        value = value.replace(regexCharBlacklist.email, "");
        break;
      case "phoneNumber":
      case "mobileNumber":
      case "postalCode":
        value = value.replace(regexCharBlacklist.number, "");
        break;
      case "city":
      case "streetAndNumber":
      case "colony":
        break;
      default:
        value = value.trim();
        break;
    }

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  _recaptchaIsLoaded = () =>
    document.getElementById("login-recaptcha").innerHTML;

  componentDidUpdate = () => {
    if (
      this.props.show &&
      !this.state.resultMessage &&
      !this._recaptchaIsLoaded()
    ) {
      const scriptTag = document.createElement("script");
      scriptTag.src =
        "https://www.google.com/recaptcha/api.js?onload=loadRecaptchaElement&render=explicit";
      scriptTag.async = true;
      scriptTag.defer = true;
      document.body.appendChild(scriptTag);
    }
  };

  _handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ processing: true });
    const formData = this.state.form;
    //formData.recaptchaToken = $("textarea#g-recaptcha-response").val();
    formData.recaptchaToken = window.grecaptcha.getResponse(
      window.loginRecaptchaId
    );
    let message;

    try {
      if (formData.username.length < 10) {
        throw new Error(FORM_ERRORS.username);
      }
      if (formData.email.length < 6) {
        throw new Error(FORM_ERRORS.email);
      }
      if (formData.companyName.length <= 5) {
        throw new Error(FORM_ERRORS.companyName);
      }
      if (formData.contactName.length <= 3) {
        throw new Error(FORM_ERRORS.contactName);
      }
      if (formData.phoneNumber.length !== 10) {
        throw new Error(FORM_ERRORS.phoneNumber);
      }
      if (formData.city.length < 3) {
        throw new Error(FORM_ERRORS.city);
      }
      if (formData.state === defaultSelectedState) {
        throw new Error(FORM_ERRORS.state);
      }
      if (formData.postalCode.length !== 5) {
        throw new Error(FORM_ERRORS.postalCode);
      }
      if (formData.mobileNumber !== "" && formData.mobileNumber.length !== 10) {
        throw new Error(FORM_ERRORS.mobileNumber);
      }
      if (formData.streetAndNumber.length < 3) {
        throw new Error(FORM_ERRORS.streetAndNumber);
      }
      if (formData.colony === "") {
        throw new Error(FORM_ERRORS.colony);
      }
      if (formData.recaptchaToken === "") {
        throw new Error(FORM_ERRORS.recaptcha);
      }

      const userObj = new User("", { debug: DEBUG_MODE });
      const res = await userObj.createDefaultUser(formData.username, formData);

      const parts = res.message.split("\n");
      const title = <p>{parts[0]}</p>;
      const firstField = (
        <div id="CreatedUser">
          Usuario: <b className="mx-3">{parts[1].split(": ")[1]}</b>
        </div>
      );
      const secondField = (
        <div id="CreatedUserEmail">
          Email: <b className="mx-3">{parts[2].split(": ")[1]}</b>
        </div>
      );
      message = (
        <>
          {title}
          {firstField}
          {secondField}
        </>
      );

      this.setState({
        resultMessage: message,
        processing: false,
        form: this.initForm,
      });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Sign In - ${err.name}: ${err.message}`
      );
      const match = /^.+\(\d+\) (.+)$/m.exec(err.message);
      if (match) {
        message = match[1] || err.message;
      } else {
        message = err.message;
      }
      this.setState({ resultMessage: message, processing: false });
    }
  };

  _clearResultMessage = () => this.setState({ resultMessage: "" });

  _closeModal = () => {
    this._clearResultMessage();
    this.props.onHide();
  };

  render = () => {
    const inputDisabled = this.state.processing;
    let modalTitle, modalBody;
    let data = this.state.form;
    if (this.state.resultMessage) {
      modalTitle = "Resultado";
      modalBody = (
        <div className="text-center">
          {this.state.resultMessage}
          <br />
          <Button
            className="m-4"
            variant="secondary"
            onClick={this._clearResultMessage}
          >
            Regresar
          </Button>
        </div>
      );
    } else {
      modalTitle = "¿Quiere unirse y vender de Saldo?";
      modalBody = (
        <Form onSubmit={this._handleSubmit}>
          <Form.Group controlId="usernameInput">
            <Form.Label>Nombre de Usuario</Form.Label>
            <Form.Control
              type="text"
              name="username"
              onChange={this._onChange}
              value={data.username}
              disabled={inputDisabled}
              maxLength="20"
            />
            <Form.Text className="text-muted">
              Solo de 10 a 20 caracteres, sin símbolos ni espacios
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="emailInput">
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              type="text"
              name="email"
              onChange={this._onChange}
              value={data.email}
              disabled={inputDisabled}
              maxLength="128"
            />
          </Form.Group>

          <Form.Group controlId="companyNameInput">
            <Form.Label>Razón Social / Nombre Negocio</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              onChange={this._onChange}
              value={data.companyName}
              disabled={inputDisabled}
              maxLength="128"
            />
          </Form.Group>

          <Form.Group controlId="contactNameInput">
            <Form.Label>Nombre de la Persona a Contactar</Form.Label>
            <Form.Control
              type="text"
              name="contactName"
              onChange={this._onChange}
              value={data.contactName}
              disabled={inputDisabled}
              maxLength="128"
            />
          </Form.Group>

          <Form.Group controlId="phoneInput">
            <Form.Label>Número Telefónico</Form.Label>
            <Form.Control
              type="text"
              name="phoneNumber"
              onChange={this._onChange}
              value={data.phoneNumber}
              disabled={inputDisabled}
              maxLength="10"
            />
          </Form.Group>

          <Form.Group controlId="cityInput">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              name="city"
              onChange={this._onChange}
              value={data.city}
              disabled={inputDisabled}
              maxLength="128"
            />
          </Form.Group>

          <Form.Group controlId="stateInput">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              name="state"
              onChange={this._onChange}
              value={data.state}
              disabled={inputDisabled}
            >
              {countryStates.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="pcInput">
            <Form.Label>Código Postal</Form.Label>
            <Form.Control
              type="text"
              name="postalCode"
              onChange={this._onChange}
              value={data.postalCode}
              disabled={inputDisabled}
              maxLength="5"
            />
          </Form.Group>

          <Form.Group controlId="mobileInput">
            <Form.Label>Número Celular</Form.Label>
            <Form.Control
              type="text"
              name="mobileNumber"
              onChange={this._onChange}
              value={data.mobileNumber}
              disabled={inputDisabled}
              maxLength="10"
            />
          </Form.Group>

          <Form.Group controlId="streetAndNumberInput">
            <Form.Label>Calle y Número</Form.Label>
            <Form.Control
              type="text"
              name="streetAndNumber"
              onChange={this._onChange}
              value={data.streetAndNumber}
              disabled={inputDisabled}
              maxLength="160"
            />
          </Form.Group>

          <Form.Group controlId="colonyInput">
            <Form.Label>Colonia</Form.Label>
            <Form.Control
              type="text"
              name="colony"
              onChange={this._onChange}
              value={data.colony}
              disabled={inputDisabled}
              maxLength="128"
            />
          </Form.Group>

          <div id="login-recaptcha" className="login-recaptcha"></div>

          <Button variant="primary" type="submit">
            Crear Usuario
          </Button>
        </Form>
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this._closeModal}
        aria-labelledby="signinModal"
        aria-hidden="true"
        role="dialog"
        centered
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title id="signinModalTitle" style={{ fontSize: "1.2em" }}>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
      </Modal>
    );
  };
}

SignForm.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default SignForm;
