import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { checkAndLogout } from "../../../../objects/User";
import * as Logging from "../../../../objects/Logging";
import "./SellReportForm.css";

import UsersListModal, { DEFAULT_OPTIONS } from "../UsersListModal";

const ERROR_FROMDATE = `Fecha Incorrecta "Desde"`;
const ERROR_TODATE = `Fecha Incorrecta "Hasta"`;
const DEFAULT_USERNAME = DEFAULT_OPTIONS.sellReport;

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

class SellReportForm extends React.Component {
  constructor(props) {
    super(props);
    const now = new Date();
    const year = now.getFullYear();
    const cMonth = now.getMonth() + 1; // Date() months begins on 0
    const nextMonth = new Date(year, cMonth, 0); // Next month day 0 is last month's last day
    this.initialFormState = {
      userData: { username: DEFAULT_USERNAME },
      dateFromYear: year,
      dateFromMonth: cMonth.toString(),
      dateFromDay: 1,
      dateToYear: year,
      dateToMonth: cMonth.toString(),
      dateToDay: nextMonth.getDate(),
    };
    this.state = {
      processing: false,
      showUsersModal: false,
      formData: this.initialFormState,
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "SellReportForm class"
    );
    this._checkAndLogout = checkAndLogout(this.props.userObj, this.props.history);
  }

  setUserData = (userData) =>
    this.setState({
      formData: {
        ...this.state.formData,
        userData: userData,
      },
    });

  showUsersModal = (show) => this.setState({ showUsersModal: show });

  _handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    value = value.trim();
    value = value.replace(/[^\d]/, "");

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };

  _handleSubmit = async (event) => {
    event.preventDefault();
    const data = this.state.formData;

    const pad = (digit) => (digit.length === 1 ? "0" + digit : digit);
    const buildStringDate = (y, m, d) => y + "-" + pad(m) + "-" + pad(d);
    const fromDate = buildStringDate(
      data.dateFromYear,
      data.dateFromMonth,
      data.dateFromDay
    );
    const toDate = buildStringDate(
      data.dateToYear,
      data.dateToMonth,
      data.dateToDay
    );
    const fd = new Date(fromDate);
    const td = new Date(toDate);

    if (isNaN(fd.getTime())) {
      this.props.showSnackbar(ERROR_FROMDATE);
      return;
    }

    if (isNaN(td.getTime())) {
      this.props.showSnackbar(ERROR_TODATE);
      return;
    }

    const username =
      data.userData.username === DEFAULT_USERNAME ? "" : data.userData.username;

    this.setState({ processing: true });

    let res,
      sellData = [],
      error = "";
    try {
      res = await this.props.userObj.getSellReport(fromDate, toDate, {
        username: username,
      });
      sellData = res.message;
      this._logger(
        Logging.LEVELS.DEBUG,
        `Sell Report Data ${JSON.stringify(sellData)}`
      );
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Sell Report retrieval failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        error = match[1] || e.message;
      } else {
        error = e.message;
      }
    }
    this.setState({ processing: false });
    this.props.setSellsList(sellData, error);
  };

  render = () => {
    const inputDisabled = this.state.processing;
    return (
      <div className="mx-auto pb-3" id="repDepForm">
        <form autoComplete="off" onSubmit={this._handleSubmit}>
          <div className="form-group">
            <label className="mx-1 my-2">Usuario</label>
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={() => this.showUsersModal(true)}
            >
              {this.state.formData.userData.username}
            </button>
          </div>

          <div className="form-group">
            <label htmlFor="dateFrom">Desde</label>
            <div className="d-flex">
              <input
                id="dateFromYear"
                name="dateFromYear"
                type="text"
                className="form-control px-2 mx-1 date-y-input"
                maxLength="4"
                placeholder="Año"
                onChange={this._handleChange}
                value={this.state.formData.dateFromYear}
                disabled={inputDisabled}
              />
              <input
                id="dateFromMonth"
                name="dateFromMonth"
                type="text"
                className="form-control px-2 mx-1 date-inputs"
                maxLength="2"
                placeholder="Mes"
                onChange={this._handleChange}
                value={this.state.formData.dateFromMonth}
                disabled={inputDisabled}
              />
              <input
                id="dateFromDay"
                name="dateFromDay"
                type="text"
                className="form-control px-2 mx-1 date-inputs"
                maxLength="2"
                placeholder="Día"
                onChange={this._handleChange}
                value={this.state.formData.dateFromDay}
                disabled={inputDisabled}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="dateTo">Hasta</label>
            <div className="d-flex">
              <input
                id="dateToYear"
                name="dateToYear"
                type="text"
                className="form-control px-2 mx-1 date-y-input"
                maxLength="4"
                placeholder="Año"
                onChange={this._handleChange}
                value={this.state.formData.dateToYear}
                disabled={inputDisabled}
              />
              <input
                id="dateToMonth"
                name="dateToMonth"
                type="text"
                className="form-control px-2 mx-1 date-inputs"
                maxLength="2"
                placeholder="Mes"
                onChange={this._handleChange}
                value={this.state.formData.dateToMonth}
                disabled={inputDisabled}
              />
              <input
                id="dateToDay"
                name="dateToDay"
                type="text"
                className="form-control px-2 mx-1 date-inputs"
                maxLength="2"
                placeholder="Día"
                onChange={this._handleChange}
                value={this.state.formData.dateToDay}
                disabled={inputDisabled}
              />
            </div>
          </div>

          <br />

          <div className="mx-auto" style={{ width: "150px" }}>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={inputDisabled}
            >
              Consultar
            </button>
          </div>
        </form>

        <UsersListModal
          show={this.state.showUsersModal}
          onHide={() => this.showUsersModal(false)}
          usersList={this.props.usersList}
          setUserData={this.setUserData}
          defaultOption={DEFAULT_USERNAME}
        />
      </div>
    );
  };
}

SellReportForm.propTypes = {
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  usersList: PropTypes.array,
  setSellsList: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(SellReportForm);
