import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import * as Logging from "../../objects/Logging";
import { PATHS } from "../../App";
import User from "../../objects/User";

const LO_TITLE = "¿Quiere salir de su cuenta?";
const LO_BODY = 'Presione en "SALIR" si realmente quiere salir';
const BUTTON_CANCEL = "Cancelar";
const BUTTON_LOGOUT = "SALIR";

/**
 * Set this class Log Level as desired
 */
const LOG_LEVEL = Logging.LEVELS.INFO;
//const DEBUG_APICALLS = true;

class LogOutModal extends React.Component {
  constructor(options = { debug: false }) {
    super();
    /**
     * Logs <message> if <debugLevel> is lower or equal than LOG_LEVEL
     * @param {Logging.LEVELS} debugLevel
     * @param {string} message
     *
     * @private
     * @function
     */
    this._logger = Logging.Logger(
      options.debug ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "LogOut class"
    );
  }

  _logOut = () => {
    const user = new User();
    user
      .logout()
      .catch((e) =>
        this._logger(
          Logging.LEVELS.WARNING,
          "Failed to properly logout " + e.message
        )
      )
      .finally(() => this.props.history.replace(PATHS.login));
  };

  render = () => (
    <Modal
      show={this.props.show}
      onHide={this.props.onHide}
      animation={false}
      aria-labelledby="logoutModal"
      aria-hidden="true"
      role="dialog"
      centered
    >
      <Modal.Header className="py-2" closeButton>
        <Modal.Title id="logoutModal" style={{ fontSize: "1.2em" }}>
          {LO_TITLE}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{LO_BODY}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this.props.onHide}
        >
          {BUTTON_CANCEL}
        </button>
        <button className="btn btn-primary" onClick={this._logOut}>
          {BUTTON_LOGOUT}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

LogOutModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(LogOutModal);
