import React from "react";
import PropTypes from "prop-types";
import { withRouter, Switch, Route } from "react-router-dom";
import $ from "jquery";

import { PATHS } from "../../App";
import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";
import Footer from "./Footer";
import Snackbar from "./components/Snackbar/Snackbar";
import TopUp from "./components/TopUpRoute/TopUp";
import * as Logging from "../../objects/Logging";
import User, {
  BAL_TYPES,
  PRODS_TYPES,
  checkAndLogout,
} from "../../objects/User";
import ReportDeposit from "./components/RepDepRoute/ReportDeposit";
import SellReport from "./components/SellReportsRoute/SellReport";
import POSManagement from "./components/POSRoute/POSManagement";
import BalanceTransfer from "./components/BalTransRoute/BalanceTransfer";
import * as UL from "./components/POSRoute/UserList";
import Distributor from "./components/DistributorRoute/Distributor";
import EditInfo from "./components/EditInfoRoute/EditInfo";

import "./MainPortal.css";

/**
 * Set this class Log Level as desired
 */
const LOG_LEVEL = Logging.LEVELS.INFO;
const DEBUG_MODE = false;

class MainPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarActive: false,
      snackbarMessage: "",
      topUpBalance: "⏳",
      servicesBalance: "⏳",
      userData: {},
      normalProducts: {},
      servicesProducts: {},
      areaCommissions: {},
      banks: {},
      usersListData: {
        users: [],
        totalUsers: 0,
        pageIndex: 0,
        lastPageIndex: 0,
        pagedList: [],
      },
      footerMessages: [],
    };
    /**
     * Logs <message> if <debugLevel> is lower or equal than LOG_LEVEL
     * @param {Logging.LEVELS} debugLevel
     * @param {string} message
     *
     * @private
     * @function
     */
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "MainPortal class"
    );
    const u = new User();
    this.user = new User(u.username, { debug: DEBUG_MODE });
    this.snackbarRef = React.createRef();
    this._checkAndLogout = checkAndLogout(this.user, this.props.history);
  }

  _showSnackbar = (message) => {
    this.setState({ snackbarActive: true, snackbarMessage: message }, () => {
      setTimeout(() => {
        this.setState({ snackbarActive: false, snackbarMessage: "" });
      }, 4000);
    });
  };

  async _updateBalances(forceUpdate = false) {
    try {
      let result;
      result = await this.user.getBalance({
        type: BAL_TYPES.normal,
        forceUpdate: forceUpdate,
      });
      const topUpBalance = result.message;
      result = await this.user.getBalance({
        type: BAL_TYPES.services,
        forceUpdate: forceUpdate,
      });
      const servicesBalance = result.message;
      this.setState({
        topUpBalance: topUpBalance,
        servicesBalance: servicesBalance,
      });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Update Balances - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  }

  _getUserData = async () => {
    try {
      const result = await this.user.getUserData();
      this.setState({ userData: result.message });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get User Data - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  _getProducts = async (forceUpdate = false) => {
    try {
      const normal = await this.user.getProducts({
        type: PRODS_TYPES.normal,
        forceUpdate: forceUpdate,
      });
      const services = await this.user.getProducts({
        type: PRODS_TYPES.services,
        forceUpdate: forceUpdate,
      });
      this.setState({
        normalProducts: normal.message,
        servicesProducts: services.message,
      });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get Product Data - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  _getAreaCommissions = async (forceUpdate = false) => {
    try {
      const comms = await this.user.getAreaCommissions(forceUpdate);
      this.setState({ areaCommissions: comms.message });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get Area Code Commissions Data - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  _getBanks = async (forceUpdate = false) => {
    try {
      const banks = await this.user.banksAvailable(forceUpdate);
      this.setState({ banks: banks.message });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get Available Banks Data - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  _getDistributorMessages = async () => {
    try {
      const msg = await this.user.getDistributorMessages();
      const messages = this.state.footerMessages;
      msg.message.forEach((msgData) => {
        if (msgData.Message) {
          messages.push({ title: msgData.Title, message: msgData.Message });
        }
      });
      this.setState({ footerMessages: messages });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get Distributor Messages - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  _getRecargakiMessages = async () => {
    try {
      const msg = await this.user.getRecargakiMessages();
      const messages = this.state.footerMessages;
      msg.message.forEach((msgData) => {
        if (msgData.Message) {
          messages.push({ title: msgData.Title, message: msgData.Message });
        }
      });
      this.setState({ footerMessages: messages });
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get Recargaki Messages - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  hasPermission = (permissionName) => {
    const perms = this.state.userData.Permissions || {};
    return perms[permissionName] || false;
  };

  isDistributor = () =>
    this.state.userData.CompanyName === this.props.distributorName;

  changeUsersListPage = (newPageIndex) => {
    const usersList = this.state.usersListData.users;
    const total = usersList.length;
    const decrement = total % UL.ITEMS_PERPAGE === 0 ? 1 : 0;
    const newPage = UL.pageData(newPageIndex, usersList);
    this.setState({
      usersListData: {
        users: usersList,
        totalUsers: total,
        pageIndex: newPageIndex,
        lastPageIndex: Math.floor(total / UL.ITEMS_PERPAGE) - decrement,
        pagedList: newPage,
      },
    });
  };

  getUsersList = async (forceUpdate = false) => {
    try {
      const users = await this.user.getUsersList(forceUpdate);
      const usersList = users.message.sort((a, b) => {
        if (a.Username > b.Username) return 1;
        if (b.Username > a.Username) return -1;
        return 0;
      });
      this.setState(
        {
          usersListData: {
            ...this.state.usersListData,
            users: usersList,
          },
        },
        () => this.changeUsersListPage(0)
      );
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Get Users List Data - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
    }
  };

  componentDidMount = async () => {
    window.addEventListener("resize", () => {
      if ($(window).width() < 768) {
        $(".sidebar .collapse").removeClass("show");
      }

      // Toggle the side navigation when window is resized below 480px
      if ($(window).width() < 480 && !$(".sidebar").hasClass("toggled")) {
        $("body").addClass("sidebar-toggled");
        $(".sidebar").addClass("toggled");
        $(".sidebar .collapse").removeClass("show");
      }
    });
    try {
      // Update refresh token first to avoiding race conditions
      // This could be factored out to where the data will be actually be used
      // but I haven't been able to make User.refreshToken()'s Mutex work
      await this._updateBalances();
      // TODO: get profile pic and change this.state.profilePicClass
      this._getUserData();
      this._getProducts();
      this._getAreaCommissions();
      this._getBanks();
      this._getDistributorMessages();
      //this._getRecargakiMessages();
      this.getUsersList();
    } catch (e) {
      this._logger(
        Logging.LEVELS.ERROR,
        `Exception not catched: ${JSON.stringify(e)}`
      );
    }
  };

  render = () => (
    <div id="wrapper" className="mpBottomPadding">
      <SideBar
        distributorName={this.props.distributorName}
        hasPermission={this.hasPermission}
        isDistributor={this.isDistributor}
      />

      {/* Content Wrapper */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* Main Content */}
        <div id="content">
          <TopNavBar
            balances={{
              topup: this.state.topUpBalance,
              services: this.state.servicesBalance,
            }}
            userData={this.state.userData}
          />

          {/* Begin Page Content */}
          <div className="container-fluid">
            <Switch>
              <Route exact path={PATHS.app}>
                <TopUp
                  userObj={this.user}
                  userData={this.state.userData}
                  showSnackbar={this._showSnackbar}
                  normalProducts={this.state.normalProducts}
                  servicesProducts={this.state.servicesProducts}
                  areaCommissions={this.state.areaCommissions}
                />
              </Route>
              {this.hasPermission("ReportDeposit") ? (
                <Route exact path={PATHS.repdep}>
                  <ReportDeposit
                    showSnackbar={this._showSnackbar}
                    banks={this.state.banks}
                    userObj={this.user}
                  />
                </Route>
              ) : null}
              {this.hasPermission("GetReports") ? (
                <Route exact path={PATHS.sellrep}>
                  <SellReport
                    usersList={this.state.usersListData.users}
                    showSnackbar={this._showSnackbar}
                    userObj={this.user}
                  />
                </Route>
              ) : null}
              {this.hasPermission("CreateUsers") ||
              this.hasPermission("CreateEmployees") ? (
                <Route exact path={PATHS.pos}>
                  <POSManagement
                    userData={this.state.userData}
                    usersListData={this.state.usersListData}
                    getUserList={this.getUsersList}
                    changePage={this.changeUsersListPage}
                    showSnackbar={this._showSnackbar}
                    userObj={this.user}
                    hasPermission={this.hasPermission}
                  />
                </Route>
              ) : null}
              {this.hasPermission("CreateUsers") ? (
                <Route exact path={PATHS.baltrans}>
                  <BalanceTransfer
                    usersList={this.state.usersListData.users}
                    updateBalances={() => this._updateBalances(true)}
                    showSnackbar={this._showSnackbar}
                    userObj={this.user}
                    userData={this.state.userData}
                  />
                </Route>
              ) : null}
              {this.isDistributor() ? (
                <Route exact path={PATHS.distibutor}>
                  <Distributor
                    showSnackbar={this._showSnackbar}
                    userObj={this.user}
                  />
                </Route>
              ) : null}
              <Route exact path={PATHS.editinfo}>
                <EditInfo
                  showSnackbar={this._showSnackbar}
                  userObj={this.user}
                  userData={this.state.userData}
                  updateUserData={this._getUserData}
                />
              </Route>
            </Switch>
          </div>
        </div>
        {/* End of Main Content */}

        <Snackbar
          show={this.state.snackbarActive}
          message={this.state.snackbarMessage}
        />
        <Footer messages={this.state.footerMessages} />
      </div>
      {/* End of Content Wrapper */}
    </div>
  );
}

MainPortal.propTypes = {
  distributorName: PropTypes.string,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(MainPortal);
