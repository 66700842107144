import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

function BanksModal(props) {
  const bankRow = (bankData) => (
    <div
      key={bankData.id}
      role="button"
      onClick={() => {
        props.setBank(bankData);
        props.onHide();
      }}
    >
      <i
        className="fas fa-camera px-2"
        style={bankData.needsTicket ? {} : { display: "none" }}
      ></i>
      <span className="px-2">{bankData.name}</span>
    </div>
  );

  const banksRows = [];
  Object.entries(props.banks).forEach(([id, data]) => {
    banksRows.push(
      bankRow({
        id: id,
        name: data.Name,
        needsTicket: Number.parseInt(data.NeedsTicket) === 1,
        showBranch: data.ExtraFields.includes("Sucursal"),
      })
    );
    banksRows.push(<hr key={`div-${id}`} />);
  });
  if (banksRows) {
    banksRows.pop();
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="banksModalTitle"
      aria-hidden="true"
      role="dialog"
      centered
    >
      <Modal.Header className="py-2" closeButton>
        <Modal.Title id="banksModalTitle" style={{ fontSize: "1.2em" }}>
          Seleccione el Banco del Depósito
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{banksRows}</Modal.Body>
    </Modal>
  );
}

BanksModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  banks: PropTypes.object,
  setBank: PropTypes.func,
};

export default BanksModal;
