import React, { useState } from "react";
import PropTypes from "prop-types";

import ExternalScripts from "../ExternalScripts";
import LoginForm from "./LoginForm";
import Alert from "../Alert/Alert";
import SignForm from "./SignForm";

const Login = (props) => {
  const [errorMsg, updateErrorMsg] = useState(null);
  const [signInState, showSignInModal] = useState(false);
  let bgImageClass, divClassName;
  if (!props.image) {
    bgImageClass = `
    .bg-login-image {

    }
    `;
    divClassName = "col-lg-6 d-none d-lg-block";
  } else {
    bgImageClass = `
    .bg-login-image {
      background: url("${props.image}");
      background-position: center;
      background-size: contain;
    }
    `;
    divClassName = "col-lg-6 d-none d-lg-block bg-login-image";
  }
  return (
    <>
      <style type="text/css">{bgImageClass}</style>
      <div className="container">
        <div className="row justify-content-center">
          {/* Outer Row */}
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  {/* Nested Row within Card Body */}
                  <div className={divClassName}></div>
                  <div className="col-lg-6">
                    <LoginForm
                      distributorName={props.name}
                      showError={updateErrorMsg}
                      showSignInForm={() => showSignInModal(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SignForm
            show={signInState}
            onHide={() => showSignInModal(false)}
          />
          <Alert errorMessage={errorMsg} hideError={updateErrorMsg} />
        </div>
        <ExternalScripts />
      </div>
    </>
  );
};

Login.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
};

export default Login;
