import React from "react";

export const SCRIPTS = ["/assets/vendor/jquery/jquery.min.js"];

const tags = SCRIPTS.map((script, i) => <script src={script} key={i}></script>);

async function _mountScript(script) {
  const tag = document.createElement("script");
  tag.src = script;
  document.body.appendChild(tag);
}

/**
 * Mounts external js scripts on the end of the document
 */
export function mountScripts() {
  // JQuery should be mounted first and wait a little bit to process it
  _mountScript(SCRIPTS[0]).then(
    setTimeout(async () => {
      for (let i = 1; i < SCRIPTS.length; i++) {
        await _mountScript(SCRIPTS[i]);
      }
    }, 300)
  );
}

const ExternalScripts = () => <>{tags}</>;

export default ExternalScripts;
