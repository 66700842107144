import React from "react";
import PropTypes from "prop-types";
import PortalEditionForm from "./PortalEditionForm";

class Distributor extends React.Component {
  render = () => {
    return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Edición de Portal</h1>
        </div>

          <PortalEditionForm
            showSnackbar={this.props.showSnackbar}
            userObj={this.props.userObj}
            debug={false}
          />
      </>
    );
  };
}

Distributor.propTypes = {
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
};

export default Distributor;
