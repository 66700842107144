import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom";
import "./SideBar.css";

import { PATHS } from "../../App";

export const toggleSideBar = () => {
  $("body").toggleClass("sidebar-toggled");
  $(".sidebar").toggleClass("toggled");
  if ($(".sidebar").hasClass("toggled")) {
    $(".sidebar .collapse").removeClass("show");
  }
};

class SideBar extends React.Component {
  isLinkActive = (destination) =>
    this.props.location.pathname === destination
      ? "nav-item active"
      : "nav-item";

  render = () => {
    return (
      <>
        {/* Sidebar */}
        <ul
          className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          {/* Sidebar - Brand */}
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href={PATHS.app}
          >
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-coins"></i>
            </div>
            <div className="sidebar-brand-text mx-3">
              {this.props.distributorName}
            </div>
          </a>

          {/* Divider */}
          <hr className="sidebar-divider my-0" />

          {/* Nav Item - Dashboard */}
          <li className={this.isLinkActive(PATHS.app)}>
            <Link className="nav-link" to={PATHS.app}>
              <i className="fas fa-fw fa-coins" aria-hidden="true"></i>
              <span>Ventas</span>
            </Link>
          </li>

          {this.props.hasPermission("ReportDeposit") ? (
            <>
              {/* Divider */}
              <hr className="sidebar-divider" />

              {/* Heading */}
              <div className="sidebar-heading">Depósitos</div>

              {/* Nav Item - Reportar Depósitos */}
              <li className={this.isLinkActive(PATHS.repdep)}>
                <Link className="nav-link" to={PATHS.repdep}>
                  <i className="fas fa-fw fa-chart-area" aria-hidden="true"></i>
                  <span>Reportar</span>
                </Link>
              </li>
            </>
          ) : null}

          {this.props.hasPermission("GetReports") ? (
            <>
              {/* Divider */}
              <hr className="sidebar-divider" />

              {/* Heading */}
              <div className="sidebar-heading">Reportes</div>

              {/* Nav Item - Reportes de Ventas */}
              <li className={this.isLinkActive(PATHS.sellrep)}>
                <Link className="nav-link" to={PATHS.sellrep}>
                  <i className="fas fa-fw fa-chart-area" aria-hidden="true"></i>
                  <span>Ventas</span>
                </Link>
              </li>
            </>
          ) : null}

          {this.props.hasPermission("CreateUsers") ||
          this.props.hasPermission("CreateEmployees") ? (
            <>
              {/* Divider */}
              <hr className="sidebar-divider" />

              {/* Heading */}
              <div className="sidebar-heading">Puntos de Venta</div>

              {this.props.hasPermission("CreateUsers") ? (
                <>
                  {/* Nav Item - Transferencias de Saldo */}
                  <li className={this.isLinkActive(PATHS.baltrans)}>
                    <Link className="nav-link" to={PATHS.baltrans}>
                      <i className="fas fa-fw fa-users" aria-hidden="true"></i>
                      <span>Transferir Saldo</span>
                    </Link>
                  </li>
                </>
              ) : null}

              {/* Nav Item - POS Management */}
              <li className={this.isLinkActive(PATHS.pos)}>
                <Link className="nav-link" to={PATHS.pos}>
                  <i className="fas fa-fw fa-users" aria-hidden="true"></i>
                  <span>Editar PDVs</span>
                </Link>
              </li>
            </>
          ) : null}

          {/* Divider */}
          <hr className="sidebar-divider" />

          {/* Heading */}
          <div className="sidebar-heading">Administración</div>

          {this.props.isDistributor() ? (
            <>
              {/* Nav Item - Distributor Portal Editor */}
              <li className={this.isLinkActive(PATHS.distibutor)}>
                <Link className="nav-link" to={PATHS.distibutor}>
                  <i className="fas fa-fw fa-users" aria-hidden="true"></i>
                  <span>Distribuidor</span>
                </Link>
              </li>
            </>
          ) : null}

          {/* Nav Item - User Information Editor */}
          <li className={this.isLinkActive(PATHS.editinfo)}>
            <Link className="nav-link" to={PATHS.editinfo}>
              <i className="fas fa-fw fa-users" aria-hidden="true"></i>
              <span>Mi Información</span>
            </Link>
          </li>

          {/* Divider */}
          <hr className="sidebar-divider d-none d-md-block" />

          {/* Sidebar Toggler (Sidebar) */}
          <div className="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={toggleSideBar}
            ></button>
          </div>

          <footer className="footer">
            <div className="container my-auto">
              <div className="copyright text-center text-light small my-auto">
                <span>Copyright &copy;<br />Royal Meridian 2021</span>
              </div>
            </div>
          </footer>
        </ul>
        {/* End of Sidebar */}
      </>
    );
  };
}

SideBar.propTypes = {
  distributorName: PropTypes.string,
  hasPermission: PropTypes.func,
  isDistributor: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(SideBar);
