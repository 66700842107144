import React from "react";
import PropTypes from "prop-types";
import Styles from "./snackbar.module.css";

function Snackbar(props) {
  const currentClass = props.show
    ? [Styles.snackbar, Styles.show].join(" ")
    : Styles.snackbar;
  return <div className={currentClass}>{props.message}</div>;
}

Snackbar.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

export default Snackbar;
