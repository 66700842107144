import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { toggleSideBar } from "./SideBar";
import LogOutModal from "./LogOutModal";
import "./TopNavBar.css";

class TopNavBar extends React.Component {
  state = {
    showLogOutModal: false,
  };

  showLogOutModal(show) {
    this.setState({ showLogOutModal: show });
  }

  render = () => {
    const profilePicClass = this.props.userData.profilePic
      ? "show-profile-pic"
      : "hide-profile-pic";
    return (
      <>
        {/* Topbar */}
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          {/* Sidebar Toggle (Topbar) */}
          <button
            id="sidebarToggleTop"
            className="btn btn-link d-md-none rounded-circle mr-3"
            onClick={toggleSideBar}
          >
            <i className="fa fa-bars"></i>
          </button>

          {/* Balance Heading */}
          <ul className="navbar-nav mr-auto ml-md-3 my-2 my-md-0 mw-100">
            <li className="nav-item mx-1">
              <h3>Saldos</h3>
            </li>
            <li className="nav-item mx-1">
              <h3>R: {this.props.balances.topup}</h3>
            </li>
            <li className="nav-item mx-1">
              <h3>S: {this.props.balances.services}</h3>
            </li>
          </ul>

          {/* Topbar Navbar */}
          <ul className="navbar-nav ml-auto">
            <div className="topbar-divider d-none d-sm-block"></div>
            <Dropdown className="nav-item no-arrow">
              <Dropdown.Toggle
                className="nav-link"
                id="userDropdown"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#fff",
                  boxShadow: "none",
                }}
              >
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                  {this.props.userData.CompanyName}
                </span>
                <div className={profilePicClass}>
                  <i className="fas fa-spinner" aria-hidden="true"></i>
                  <img
                    alt="something"
                    className="img-profile rounded-circle"
                    src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow">
                <Dropdown.Item>
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Mi Perfil
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => this.showLogOutModal(true)}>
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Salir
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </nav>
        {/* End of Topbar */}
        <LogOutModal
          show={this.state.showLogOutModal}
          onHide={() => this.showLogOutModal(false)}
        />
      </>
    );
  };
}

TopNavBar.propTypes = {
  balances: PropTypes.object,
  userData: PropTypes.object,
};

export default TopNavBar;
