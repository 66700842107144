import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Accordion } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import UserList from "./UserList";
import NewUserForm from "./NewUserForm";

class POSManagement extends React.Component {
  state = {
    showResponseModal: false,
    newIsEmployee: false,
    responseModalMessage: "",
  };

  showResponseModal = (message, isEmployee) => {
    this.setState({
      showResponseModal: true,
      responseModalMessage: message,
      newIsEmployee: isEmployee,
    });
  };

  _onModalHide = () => {
    this.setState({ showResponseModal: false, responseModalMessage: "" });
  };

  render = () => {
    return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Editar PDVs</h1>
        </div>

        <div className="d-sm-flex align-items-center justify-content-center mb-4">
          <Accordion>
            <Card>
              <Accordion.Toggle as={Button} variant="secondary" eventKey="0">
                Crear nuevo Usuario
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <NewUserForm
                    userData={this.props.userData}
                    userObj={this.props.userObj}
                    showSnackbar={this.props.showSnackbar}
                    getUserList={this.props.getUserList}
                    changePage={this.props.changePage}
                    showResponseModal={this.showResponseModal}
                    hasPermission={this.props.hasPermission}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>

        <div className="d-sm-flex align-items-center justify-content-center mb-4">
          <UserList
            showSnackbar={this.props.showSnackbar}
            usersListData={this.props.usersListData}
            changePage={this.props.changePage}
            userObj={this.props.userObj}
          />
        </div>

        <Modal
          show={this.state.showResponseModal}
          onHide={this._onModalHide}
          aria-labelledby="responseModalTitle"
          aria-hidden="true"
          role="dialog"
          centered
        >
          <Modal.Header className="py-2" closeButton>
            <Modal.Title id="responseModalTitle" style={{ fontSize: "1.2em" }}>
              Creación de{" "}
              {this.state.newIsEmployee ? "Empleado" : "Punto de Venta"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.responseModalMessage}</Modal.Body>
        </Modal>
      </>
    );
  };
}

POSManagement.propTypes = {
  userData: PropTypes.object,
  usersListData: PropTypes.object,
  getUserList: PropTypes.func,
  changePage: PropTypes.func,
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  hasPermission: PropTypes.func,
};

export default POSManagement;
