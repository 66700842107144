import axios from "axios";
import https from "https";
import DEV_ENVIRONMENT from "../constants/Constants";
import * as Logging from "../objects/Logging";

const LOG_LEVEL = Logging.LEVELS.INFO;

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};

export const MIME_TYPES = {
  PLAIN: "text/plain",
  CSV: "text/csv",
  JSON: "application/json",
  BINARY: "application/octet-stream",
  JPEG: "image/jpeg",
  PNG: "image/png",
  TIFF: "image/tiff",
};

/**
 * RESTClient Response with response normalization
 */
export class RESTClientResponse {
  /**
   * RESTClientResponse constructor
   * @param {int} httpCode
   * @param {Object|string} data
   * @param {Object} headers
   */
  constructor({ httpCode = 0, data = null, headers = {} }) {
    this.httpCode = httpCode;
    this.headers = headers;
    this.data = data;
  }

  isObject = () => typeof this.data === "object" && this.data !== null;
}

/**
 * Axios Wrapper for REST clients with debugging capabilities
 * @class
 * @public
 */
export class RESTClient {
  /**
   * RESTClient Constructor
   * @param {string} baseURL
   * @param {Object} options {debug: boolean, encoding: string}
   * @constructor
   */
  constructor(baseURL, options = { debug: false, encoding: "utf-8" }) {
    this._baseURL = baseURL;
    this._encoding = options.encoding;

    /**
     * Logs <message> if <debugLevel> is lower or equal than LOG_LEVEL
     * @param {Logging.LEVELS} debugLevel
     * @param {string} message
     *
     * @private
     * @function
     */
    this._logger = Logging.Logger(
      options.debug ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "RESTClient class"
    );
  }

  /**
   * Generic HTTP request wrapper and normalizer
   * @param {string} path   endpoint path
   * @param {Object} config
   * @param {HTTP_METHODS} config.method
   * @param {Object}       config.params
   *                        Parameters for the request
   * @param {MIME_TYPES}   config.mimeType
   * @param {boolean}      config.sslCheck
   * @param {Object}       config.credentials
   *                        {username: string, secret: string}
   * @returns {Promise<RESTClientResponse>}
   *                        onSuccess or onFail will receive a
   *                        RESTClientResponse object
   *
   * @public
   * @function
   */
  request(
    path,
    config = {
      method: HTTP_METHODS.GET,
      params: null,
      mimeType: MIME_TYPES.JSON,
      sslCheck: true,
      credentials: {},
    }
  ) {
    let requestConfig = {
      baseURL: this._baseURL + path,
      method: config.method.toLowerCase(),
    };

    if (config.params) {
      const paramsAttr = config.method === HTTP_METHODS.GET ? "params" : "data";
      requestConfig[paramsAttr] = config.params;
    }

    if (
      config.credentials.username !== undefined &&
      config.credentials.password !== undefined
    ) {
      requestConfig.auth = config.credentials;
    }

    let httpsAgentOptions = {
      timeout: 120,
    };
    if (!config.sslCheck || DEV_ENVIRONMENT) {
      httpsAgentOptions.rejectUnauthorized = false;
    }

    let agent = new https.Agent(httpsAgentOptions);
    requestConfig.httpsAgent = agent;

    this._logger(
      Logging.LEVELS.DEBUG,
      `${config.method} request: ${
        this._baseURL
      }${path}\nConfig: ${JSON.stringify(requestConfig)}`
    );

    let axInstance = axios.create();
    axInstance.defaults.headers[
      "Content-Type"
    ] = `${config.mimeType};charset=${this._encoding}`;

    if (config.params) {
      const paramsString = JSON.stringify(config.params);
      this._logger(
        Logging.LEVELS.DEBUG,
        `Request length: ${paramsString.length.toString()} body: ${paramsString}`
      );
    }

    const logAndReturn = (callback, code, message, headers) => {
      this._logger(
        Logging.LEVELS.DEBUG,
        `RAW Response (HttpCode ${code}) ${JSON.stringify(message)}`
      );
      callback(
        new RESTClientResponse({
          httpCode: code,
          data: message,
          headers: headers,
        })
      );
    };

    return new Promise((resolve, reject) => {
      axInstance
        .request(requestConfig)
        .then((response) => {
          logAndReturn(
            resolve,
            response.status,
            response.data,
            response.headers
          );
        })
        .catch((error) => {
          if (error.response) {
            // Response status code different than 200
            logAndReturn(
              reject,
              error.response.status,
              error.response.data,
              error.headers
            );
          } else if (error.request) {
            // No response
            let responseString = "Couldn't connect with service";
            this._logger(
              Logging.LEVELS.WARNING,
              `${responseString}: ${JSON.stringify(error)}`
            );
            logAndReturn(reject, -1, error.message, null);
          } else {
            // Error setting up the request
            let responseString = "Internal Client Error";
            this._logger(
              Logging.LEVELS.ERROR,
              `${responseString}: ${JSON.stringify(error)}`
            );
            logAndReturn(reject, -100, error.message, null);
          }
        });
    });
  }
}
