import React from "react";
import PropTypes from "prop-types";

import SellReportForm from "./SellReportForm";
import SellReportResults from "./SellReportResults";

class SellReport extends React.Component {
  state = {
    results: {
      sells: [],
      errorMessage: "",
    },
  };

  setSellsList = (sells, err) =>
    this.setState({
      results: {
        sells: sells,
        errorMessage: err,
      },
    });

  render = () => {
    return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Reporte de Ventas</h1>
        </div>

        <div className="row">
          <SellReportForm
            usersList={this.props.usersList}
            showSnackbar={this.props.showSnackbar}
            userObj={this.props.userObj}
            setSellsList={this.setSellsList}
          />
        </div>

        <div className="row justify-content-center my-4">
          <SellReportResults sellResults={this.state.results} />
        </div>
      </>
    );
  };
}

SellReport.propTypes = {
  usersList: PropTypes.array,
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
};

export default SellReport;
