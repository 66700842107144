import React from "react";
import PropTypes from "prop-types";
import ReportDepositForm from "./ReportDepositForm";

class ReportDeposit extends React.Component {
  render = () => {
    return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Reportar Depositos</h1>
        </div>

        <div className="row">
          <ReportDepositForm
            showSnackbar={this.props.showSnackbar}
            banks={this.props.banks}
            userObj={this.props.userObj}
          />
        </div>
      </>
    );
  };
}

ReportDeposit.propTypes = {
  showSnackbar: PropTypes.func,
  banks: PropTypes.object,
  userObj: PropTypes.object,
};

export default ReportDeposit;
