import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import $ from "jquery";

import * as Logging from "../../../../objects/Logging";
import User, { checkAndLogout } from "../../../../objects/User";
import { isObjectEmpty } from "../../../../objects/Toolbox";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

export const regexCharBlacklist = {
  username: /[^a-z0-9]/,
  name: /[^ .\wñÑáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ]/,
  email: /[^a-zA-Z0-9_\-@.]/,
  number: /[^\d]/,
  numberWithDecimal: /[^\d.]/,
};
export const defaultSelectedState = "Seleccione";
export const countryStates = [
  "Seleccione",
  "AGUASCALIENTES",
  "BAJA CALIFORNIA",
  "BAJA CALIFORNIA SUR",
  "CAMPECHE",
  "CHIAPAS",
  "CHIHUAHUA",
  "COAHUILA",
  "COLIMA",
  "CDMX",
  "DURANGO",
  "ESTADOS UNIDOS",
  "GUANAJUATO",
  "GUERRERO",
  "HIDALGO",
  "JALISCO",
  "MEXICO",
  "MICHOACAN",
  "MORELOS",
  "NAYARIT",
  "NUEVO LEON",
  "OAXACA",
  "PUEBLA",
  "QUERETARO",
  "QUINTANA ROO",
  "SAN LUIS POTOSI",
  "SINALOA",
  "SONORA",
  "TABASCO",
  "TAMAULIPAS",
  "TLAXCALA",
  "VERACRUZ",
  "YUCATAN",
  "ZACATECAS",
];

const FORM_ERRORS = {
  phoneNumber: "Teléfono debe de ser de 10 dígitos",
  mobileNumber: "Celular debe de ser de 10 dígitos",
  postalCode: "Código Postal debe de ser de 5 dígitos",
  sellerCommission: "Comisión inválida. Formato D.DD",
  pin: "PIN debe de ser de 6 dígitos",
};

export function userKeys(userID, username) {
  const key = userID + "-" + username;
  return { key: key, formID: "form-" + key };
}

export class EditFormObject {
  constructor(formID) {
    let tmp = formID.split("-");
    this.userID = tmp[1];
    this.username = tmp[2];
    this._inputs = $("#" + formID + " :input");

    let instance = this;
    this._inputs.each(function () {
      const item = $(this);
      if (item.prop("name")) {
        const [userID, fieldName] = item.prop("name").split("-");
        if (userID === instance.userID && /^[a-z]+$/i.test(fieldName)) {
          const type = item.prop("type");
          instance["_" + fieldName] = {
            type: type,
            value:
              type === "checkbox" ? item.prop("checked") || false : item.val(),
          };
        }
      }
    });

    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "EditFormObject class"
    );
  }

  getInputValue = (inputName) => {
    const innerAttr = "_" + inputName;
    const instance = this;
    this._inputs.each(function () {
      const item = $(this);
      const [userID, fieldName] = item.prop("name").split("-");
      const type = item.prop("type");
      if (
        fieldName === inputName &&
        userID === instance.userID &&
        type === instance[innerAttr].type
      ) {
        instance[innerAttr].value =
          type === "checkbox" ? item.prop("checked") || false : item.val();
      }
    });
    return instance[innerAttr].value;
  };

  setInputValue = (inputName, value) => {
    const innerAttr = "_" + inputName;
    const instance = this;
    instance[innerAttr].value = value;
    this._inputs.each(function () {
      const item = $(this);
      const [userID, fieldName] = item.prop("name").split("-");
      const type = item.prop("type");
      if (
        fieldName === inputName &&
        userID === instance.userID &&
        type === instance[innerAttr].type
      ) {
        const newValue = instance[innerAttr].value;
        switch (type) {
          case "checkbox":
            item.prop("checked", newValue);
            break;
          default:
            item.val(newValue);
            break;
        }
      }
    });
  };

  _hidePermsSeparator = (hide = true) =>
    $(`#${this.userID}-permsSeparator`).css("display", hide ? "none" : "block");

  _hideElement = (element, hide) => {
    var jItem = $(element);
    if (jItem.hasClass("d-flex") || jItem.hasClass("d-none")) {
      if (hide) {
        jItem.removeClass("d-flex").addClass("d-none");
      } else {
        jItem.removeClass("d-none").addClass("d-flex");
      }
    } else {
      element.css("display", hide ? "none" : "block");
    }
  };

  _hideInputBlock = (inputName, hide = true) => {
    const instance = this;
    this._inputs.each(function () {
      const item = $(this);
      const [userID, fieldName] = item.prop("name").split("-");
      if (userID === instance.userID && fieldName === inputName) {
        const parentDiv = item.parent(".form-group");
        if (!parentDiv) {
          console.log("Not found for " + inputName);
          console.log(item);
          console.log(item.parent(".form-group"));
          return;
        }
        instance._hideElement(parentDiv, hide);
      }
    });
  };

  _setDisplayPermissions = (parentPermissions, isEmployee) => {
    let hideCount = 0;
    const fields = [
      "sellTopUp",
      "createUsers",
      "createEmployees",
      "getReports",
      "reportDeposit",
    ];
    fields.forEach((inputName) => {
      const permName = inputName.charAt(0).toUpperCase() + inputName.slice(1);
      const hide =
        inputName === "sellTopUp"
          ? !parentPermissions[permName]
          : isEmployee || !parentPermissions[permName];
      this._hideInputBlock(inputName, hide);
      hideCount = hide ? hideCount + 1 : hideCount;
    });
    this._hidePermsSeparator(hideCount === fields.length);
  };

  setInputsVisibility = (parentPermissions, isEmployee) => {
    this._setDisplayPermissions(parentPermissions, isEmployee);
    const instance = this;
    const fieldsToHide = [
      "email",
      "contact",
      "phone",
      "mobileNumber",
      "city",
      "state",
      "street",
      "colony",
      "postalCode",
      "commission",
    ];
    this._inputs.each(function () {
      const item = $(this);
      const [userID, fieldName] = item.prop("name").split("-");
      if (userID === instance.userID && fieldsToHide.includes(fieldName)) {
        const parentDiv = item.parent(".form-group");
        if (!parentDiv) {
          console.log("Not found for " + fieldName);
          console.log(item);
          console.log(item.parent(".form-group"));
          return;
        }
        instance._hideElement(parentDiv, isEmployee);
      }
    });
    const prefix = "#" + this.userID;
    $(prefix + "-personalInfo").css("display", isEmployee ? "none" : "block");
    $(prefix + "-addressInfo").css("display", isEmployee ? "none" : "block");
  };

  loadFromObject = (data) => {
    if (isObjectEmpty(data)) {
      this._logger(Logging.LEVELS.WARNING, "No data to load");
      return;
    }
    const instance = this;
    this._inputs.each(function () {
      const item = $(this);
      const [userID, fieldName] = item.prop("name").split("-");
      if (userID === instance.userID && data[fieldName] !== undefined) {
        const newValue = data[fieldName];
        const attrName = "_" + fieldName;
        instance[attrName].value = newValue;
        switch (instance[attrName].type) {
          case "checkbox":
            item.prop("checked", newValue);
            break;
          default:
            item.val(newValue);
            break;
        }
      }
    });
  };
}

export const loadFormPOSData = async (formID) => {
  const logger = Logging.Logger(
    DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
    "loadFormPOSData"
  );
  logger(Logging.LEVELS.DEBUG, `Retrieving data for FORM ${formID}`);

  let tmp = formID.split("-");
  const posUserID = tmp[1];
  const posUsername = tmp[2];
  const form = new EditFormObject(formID);
  const u = new User();
  const user = new User(u.username, { debug: DEBUG_MODE });
  try {
    let res = await user.getUserData(posUsername);
    const posData = res.message;
    let dataObject = {};
    let canonicalName = "";
    const firstCharToLC = (s) => s.charAt(0).toLowerCase() + s.slice(1);
    Object.entries(posData).forEach(([key, value]) => {
      canonicalName = "";
      switch (key) {
        case "ContactName":
          canonicalName = "contact";
          break;
        case "StreetAndNumber":
          canonicalName = "street";
          break;
        case "PhoneNumber":
          canonicalName = "phone";
          break;
        case "SellerComission":
        case "SellerCommission":
          canonicalName = "commission";
          break;
        case "CompanyName":
        case "MobileNumber":
        case "PostalCode":
          canonicalName = firstCharToLC(key);
          break;
        case "Colony":
        case "City":
        case "Email":
        case "State":
          canonicalName = key.toLowerCase();
          break;
        case "Permissions":
          Object.entries(value).forEach((kv) => {
            canonicalName = /^[A-Z][A-Z].+$/.test(kv[0])
              ? kv[0].toLowerCase()
              : firstCharToLC(kv[0]);
            dataObject[canonicalName] = kv[1];
          });
          break;
        case "IsEmployee":
          canonicalName = "isEmployee";
          value = value ? "1" : "0";
          break;
        default:
          break;
      }
      if (canonicalName && key !== "Permissions") {
        dataObject[canonicalName] = value;
      }
    });
    form.loadFromObject(dataObject);
    res = null;

    /**
     * Reload permissions and set input blocks' visibility
     *
     * There should be not problem just hiding these inputs
     * By design the backend API
     * MUST IGNORE VALUES THAT THE USER HAS NOT PERMISSION TO MODIFY
     */
    res = await user.getUserData();
    const userData = res.message;
    const userPerms = userData.Permissions;
    form.setInputsVisibility(userPerms, posData.IsEmployee);
    if (!posData.IsEmployee) {
      $(`#${posUserID}-commissionText`).text(
        "Max Com: " + userData.SellerComission
      );
    }
    form.setInputValue("disableAuthPIN", userPerms["DisableAuthPIN"]);
    form._hideInputBlock("pin", userPerms["DisableAuthPIN"]);
  } catch (err) {
    logger(
      Logging.LEVELS.WARNING,
      `Get UserEditForm Data and Permissions - ${err.name}: ${err.message}`
    );
    this._checkAndLogout(err);
  }
};

class UserEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "UserEditForm"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
  }

  _handleSubmit = async (event) => {
    const formID = event.target.form.id;
    const form = new EditFormObject(formID);
    const username = form.username.trim();
    const isEmployee = form.getInputValue("isEmployee") === "1";
    const needsPIN = form.getInputValue("disableAuthPIN") === "false";
    this.setState({ processing: true });
    let message = "";
    try {
      let newData;
      if (isEmployee) {
        newData = {
          sellTopUp: form.getInputValue("sellTopUp"),
        };
      } else {
        newData = {
          contactName: form.getInputValue("contact").trim(),
          phoneNumber: form.getInputValue("phone").trim(),
          mobileNumber: form.getInputValue("mobileNumber").trim(),
          city: form.getInputValue("city").trim(),
          state: form.getInputValue("state").trim(),
          streetAndNumber: form.getInputValue("street").trim(),
          colony: form.getInputValue("colony").trim(),
          postalCode: form.getInputValue("postalCode").trim(),
          sellerCommission: form.getInputValue("commission").trim(),
          sellTopUp: form.getInputValue("sellTopUp"),
          createUsers: form.getInputValue("createUsers"),
          createEmployees: form.getInputValue("createEmployees"),
          getReports: form.getInputValue("getReports"),
          reportDeposit: form.getInputValue("reportDeposit"),
        };

        if (newData.phoneNumber.length !== 10) {
          throw new Error(FORM_ERRORS.phoneNumber);
        }

        if (newData.mobileNumber !== "" && newData.mobileNumber.length !== 10) {
          throw new Error(FORM_ERRORS.mobileNumber);
        }

        if (newData.postalCode.length !== 5) {
          throw new Error(FORM_ERRORS.postalCode);
        }

        if (!/^\d{1,2}(\.\d{1,2})?$/.test(newData.sellerCommission)) {
          throw new Error(FORM_ERRORS.sellerCommission);
        }
      }

      if (needsPIN) {
        newData["pin"] = form.getInputValue("pin");
        if (newData.pin.length !== 6) {
          throw new Error(FORM_ERRORS.pin);
        }
      }

      this._logger(
        Logging.LEVELS.DEBUG,
        "UserEditForm, Submit: data to Send: " + JSON.stringify(newData)
      );
      const res = await this.props.userObj.setPOSData(username, newData);
      message = res.message;

      await loadFormPOSData(formID);
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Updating POS User ${form.userID} - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
      message = err.message;
    }
    this.setState({ processing: false });
    this.props.showSnackbar(message);
  };

  _filterChars = (event, type) => {
    const inputObj = $("#" + event.target.id);
    const isPercentage = type === "percentage";
    const regexBlacklist = isPercentage
      ? regexCharBlacklist.numberWithDecimal
      : regexCharBlacklist[type] || null;
    if (regexBlacklist === null) {
      return;
    }
    let filtered = inputObj.val().replace(regexBlacklist, "");
    inputObj.val(filtered);
  };

  render = () => {
    const inputDisabled = this.state.processing;
    const formID = this.props.formID;
    const userID = this.props.userID;
    return (
      <div className="mx-auto pb-3" id={`${userID}-userEditForm`}>
        <form id={formID} autoComplete="off" onSubmit={this._handleSubmit}>
          <input
            type="hidden"
            id={`${userID}-isEmployee`}
            name={`${userID}-isEmployee`}
          />
          <input
            type="hidden"
            id={`${userID}-disableAuthPIN`}
            name={`${userID}-disableAuthPIN`}
          />

          <div className="d-flex p-4 mx-auto justify-content-center">
            <Button onClick={this._handleSubmit}>Guardar Cambios</Button>
          </div>

          <div className="form-group d-flex justify-content-center">
            <input
              id={`${userID}-pin`}
              name={`${userID}-pin`}
              type="password"
              className="form-control"
              placeholder="PIN"
              maxLength="6"
              style={{ width: "6em" }}
              onKeyUp={(e) => this._filterChars(e, "number")}
            />
          </div>

          <div className="d-flex p-4 mx-auto justify-content-center font-weight-bold lead">
            {this.props.username}
          </div>

          <div className="form-group">
            <label htmlFor="companyName">Razón</label>
            <input
              id={`${userID}-companyName`}
              name={`${userID}-companyName`}
              type="text"
              className="form-control"
              placeholder="Razón"
              disabled={true}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id={`${userID}-email`}
              name={`${userID}-email`}
              type="text"
              className="form-control"
              placeholder="Email"
              disabled={true}
            />
          </div>

          <hr id={`${userID}-personalInfo`} className="separator" />

          <div className="form-group">
            <label htmlFor="contact">Contacto</label>
            <input
              id={`${userID}-contact`}
              name={`${userID}-contact`}
              type="text"
              className="form-control"
              placeholder="Contacto"
              disabled={inputDisabled}
              onKeyUp={(e) => this._filterChars(e, "name")}
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Teléfono</label>
            <input
              id={`${userID}-phone`}
              name={`${userID}-phone`}
              type="text"
              className="form-control"
              placeholder="Teléfono"
              disabled={inputDisabled}
              maxLength="10"
              onKeyUp={(e) => this._filterChars(e, "number")}
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobileNumber">Celular</label>
            <input
              id={`${userID}-mobileNumber`}
              name={`${userID}-mobileNumber`}
              type="text"
              className="form-control"
              placeholder="Celular"
              disabled={inputDisabled}
              maxLength="10"
              onKeyUp={(e) => this._filterChars(e, "number")}
            />
          </div>

          <hr id={`${userID}-addressInfo`} className="separator" />

          <div className="form-group">
            <label htmlFor="city">Ciudad</label>
            <input
              id={`${userID}-city`}
              name={`${userID}-city`}
              type="text"
              className="form-control"
              placeholder="Ciudad"
              disabled={inputDisabled}
              onKeyUp={(e) => this._filterChars(e, "name")}
            />
          </div>

          <div className="form-group">
            <label htmlFor="state">Estado</label>
            <br />
            <select
              id={`${userID}-state`}
              name={`${userID}-state`}
              disabled={inputDisabled}
            >
              {countryStates.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="street">Calle y Número</label>
            <input
              id={`${userID}-street`}
              name={`${userID}-street`}
              type="text"
              className="form-control"
              placeholder="Calle"
              disabled={inputDisabled}
            />
          </div>

          <div className="form-group">
            <label htmlFor="colony">Colonia</label>
            <input
              id={`${userID}-colony`}
              name={`${userID}-colony`}
              type="text"
              className="form-control"
              placeholder="Colonia"
              disabled={inputDisabled}
            />
          </div>

          <div className="form-group">
            <label htmlFor="postalCode">CP</label>
            <input
              id={`${userID}-postalCode`}
              name={`${userID}-postalCode`}
              type="text"
              className="form-control"
              placeholder="CP"
              disabled={inputDisabled}
              maxLength="5"
              onKeyUp={(e) => this._filterChars(e, "number")}
            />
          </div>

          <div className="form-group">
            <div className="d-flex justify-content-between">
              <label htmlFor="commission">Comisión</label>
              <div
                id={`${userID}-commissionText`}
                className="small font-weight-bold my-auto"
              ></div>
            </div>
            <input
              id={`${userID}-commission`}
              name={`${userID}-commission`}
              type="text"
              className="form-control"
              placeholder="Comisión"
              disabled={inputDisabled}
              maxLength="5"
              onKeyUp={(e) => this._filterChars(e, "percentage")}
            />
          </div>

          <hr id={`${userID}-permsSeparator`} className="separator" />

          <div className="form-group">
            <label htmlFor="sellTopUp">Cobrar Saldo</label>
            <ToggleSwitch
              id={`${userID}-sellTopUp`}
              name={`${userID}-sellTopUp`}
              disabled={inputDisabled}
            />
          </div>

          <div className="form-group">
            <label htmlFor="createUsers">Crear PDV</label>
            <ToggleSwitch
              id={`${userID}-createUsers`}
              name={`${userID}-createUsers`}
              disabled={inputDisabled}
            />
          </div>

          <div className="form-group">
            <label htmlFor="createEmployees">Crear Empleados</label>
            <ToggleSwitch
              id={`${userID}-createEmployees`}
              name={`${userID}-createEmployees`}
              disabled={inputDisabled}
            />
          </div>

          <div className="form-group">
            <label htmlFor="getReports">Reportes de Venta</label>
            <ToggleSwitch
              id={`${userID}-getReports`}
              name={`${userID}-getReports`}
              disabled={inputDisabled}
            />
          </div>

          <div className="form-group">
            <label htmlFor="reportDeposit">Reportar Depósitos</label>
            <ToggleSwitch
              id={`${userID}-reportDeposit`}
              name={`${userID}-reportDeposit`}
              disabled={inputDisabled}
            />
          </div>
        </form>
      </div>
    );
  };
}

UserEditForm.propTypes = {
  username: PropTypes.string,
  userID: PropTypes.string,
  formID: PropTypes.string,
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(UserEditForm);
