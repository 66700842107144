import {
  APP_NAME,
  APP_TOKEN,
  TESTAPP_NAME,
  TESTAPP_TOKEN,
} from "../constants/Credentials";
import { API_BASEURL, TESTAPI_BASEURL } from "../constants/Constants";

const TEST_API = false;

export const AppData = {
  baseURL: TEST_API ? TESTAPI_BASEURL : API_BASEURL,
  appName: TEST_API ? TESTAPP_NAME : APP_NAME,
  appToken: TEST_API ? TESTAPP_TOKEN : APP_TOKEN,
};

/**
 * Recargaki Client Response
 *
 * An instance of this class must be returned after
 * a Recargaki REST API Call has been processed.
 *
 * @class
 * @public
 */
export class RecargakiClientResponse {
  /**
   * @param {Object}  result
   * @param {boolean} result.success
   * @param {int}     result.errorCode
   * @param {string}  result.message
   * @param {Object}  result.data
   * @param {boolean} result.shouldLogout
   *
   * @constructor
   */
  constructor(
    result = {
      success: false,
      errorCode: 0,
      message: "Error Desconocido",
      data: null,
      shouldLogout: false,
    }
  ) {
    this.success = result.success;
    this.errorCode = result.errorCode;
    this.message = result.message;
    this.data = result.data;
    this.shouldLogout = result.shouldLogout;
  }

  toString = () =>
    "RecargakiClientResponse - Success: " +
    this.success.toString() +
    `, ErrorCode: ${this.errorCode}, Message: ${this.message}, Extra: ` +
    this.data.toString() +
    ", ShouldLogout: " +
    this.shouldLogout.toString();
}

/**
 * Normalize Recargaki API Error Data
 * @param {RESTClientResponse} requestResponse
 * @returns {Object} {code: int, message: string}
 *
 * @public
 * @function
 */
export function parseRecargakiError(requestResponse) {
  const code = requestResponse.httpCode;
  const data = requestResponse.data;
  const dataIsObject = !!data && data.constructor === Object;
  if (code >= 400 && code < 500 && code !== 404 && dataIsObject) {
    return { code: data.return_code, message: data.message };
  } else {
    return { code: -1, message: data.toString() };
  }
}

/**
 * Normalize Recargaki API Data or formats it on Error
 *
 * This function should be used when the API has returned
 * a valid HTTP Error (200) response. A 200 response doesn't
 * guarantee a succesful operation. It just means a completed
 * operation.
 *
 * If a response is received with the wrong format due
 * unexpected failure, a generic fail response is returned.
 *
 * @param {RESTClientResponse} requestResponse
 * @returns {Object} {success: boolean, code: int, message: string}
 *
 * @public
 * @function
 */
export function parseRecargakiResult(requestResponse) {
  const data = requestResponse.data;
  if (requestResponse.isObject()) {
    return {
      success: Boolean(data.success),
      code: data.return_code,
      message: data.message,
    };
  } else {
    return {
      success: 0,
      code: -1,
      message:
        data === ""
          ? "El Servicio No Respondió"
          : "El Servicio tiene un problema",
    };
  }
}

/**
 * @class
 * @public
 */
export class RecargakiClient {
  /**
   * @param {Object} appData
   * @param {User} user
   *
   * @constructor
   */
  constructor(appData, user) {
    this._appData = appData;
    this._user = user;
  }
}
