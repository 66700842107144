import React from "react";
import PropTypes from "prop-types";

import SellForm from "./SellForm";
import ProductMiniCard from "./ProductMiniCard";
import { PINS } from "../../../../objects/Brands";

class TopUp extends React.Component {
  constructor() {
    super();
    this.initFormData = {
      amount: "0",
      maxBenLeght: 10,
      beneficiary: "",
      beneficiaryConf: "",
      comissionLabel: "",
      pin: "",
      errors: {
        beneficiary: "",
        beneficiaryConf: "",
        srvAmount: "",
      },
    };
    this.state = {
      productSelected: {},
      formData: this.initFormData,
      showSellForm: false,
    };
  }

  showSellForm = (show) => this.setState({ showSellForm: show });

  _handleProductSelect = (event, pData) => {
    event.preventDefault();
    this.setState({
      productSelected: pData,
      formData: this.initFormData,
    });
    this.showSellForm(!this.state.showSellForm);
  };

  _productsMosaic = () => {
    const itemsByRow = 4;
    let items = [],
      tmp = [];

    Object.entries(this.props.normalProducts).forEach((data) => {
      if (PINS.includes(data[0])) {
        // PINs products, extract proper description
        Object.entries(data[1]).forEach((pinData) => {
          const shortName = data[0] + "-" + pinData[0].toString(); // canonName + price
          tmp.push(
            <ProductMiniCard
              fullName={pinData[1]}
              shortName={shortName}
              type="pins"
              key={shortName}
              handle={this._handleProductSelect}
            />
          );
        });
      } else {
        // Normal products
        tmp.push(
          <ProductMiniCard
            fullName={data[0]}
            shortName={data[0]}
            type="normal"
            key={data[0]}
            handle={this._handleProductSelect}
          />
        );
      }
    });
    Object.entries(this.props.servicesProducts).forEach((data) => {
      tmp.push(
        <ProductMiniCard
          fullName={data[0]}
          shortName={data[0]}
          type="services"
          key={data[0]}
          handle={this._handleProductSelect}
        />
      );
    });
    if (tmp.length <= 0) {
      // Placeholder whenever data still hasn't loaded
      items.push(
        <div className="row" key="content-row">
          <ProductMiniCard key="default" shortName="default" />
        </div>
      );
    } else {
      const remainder = tmp.length % itemsByRow;
      const rows = (tmp.length - remainder) / itemsByRow;
      for (let i = 0; i < rows; i++) {
        // Create a row and do .shift() "itemsByRow" times on the tmp array
        items.push(
          <div className="row" key={`row-${i}`}>
            {Array.apply(null, Array(itemsByRow)).map(() => tmp.shift())}
          </div>
        );
      }
      items.push(
        <div className="row" key="row-last">
          {Array.apply(null, Array(remainder)).map(() => tmp.shift())}
        </div>
      );
    }
    return <>{items}</>;
  };

  setFormData = (obj) => {
    this.setState({
      ...this.state,
      formData: obj,
    });
  };

  resetFormData = () => {
    this.setState({
      ...this.state,
      formData: this.initFormData,
    });
  };

  render = () => (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Ventas</h1>
      </div>

      <div className="row">
        <SellForm
          show={this.state.showSellForm}
          onHide={() => this.showSellForm(false)}
          productData={this.state.productSelected}
          normalProducts={this.props.normalProducts}
          servicesProducts={this.props.servicesProducts}
          areaCommissions={this.props.areaCommissions}
          setData={this.setFormData}
          resetForm={this.resetFormData}
          formData={this.state.formData}
          userObj={this.props.userObj}
          userData={this.props.userData}
          showSnackbar={this.props.showSnackbar}
        />
      </div>

      {this._productsMosaic()}
    </>
  );
}

TopUp.propTypes = {
  userObj: PropTypes.object,
  userData: PropTypes.object,
  showSnackbar: PropTypes.func,
  normalProducts: PropTypes.object,
  servicesProducts: PropTypes.object,
  areaCommissions: PropTypes.object,
};

export default TopUp;
