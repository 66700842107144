import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { checkAndLogout } from "../../../../objects/User";
import * as Logging from "../../../../objects/Logging";

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

class EditInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "EditInfoForm class"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
  }

  _handleNewPassword = async (event) => {
    event.preventDefault();

    this.setState({ processing: true });

    let message;
    try {
      const res = await this.props.userObj.newPassword();
      message = res.message;
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Edit Info failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        message = match[1] || e.message;
      } else {
        message = e.message;
      }
    }

    this.props.showSnackbar(message);
    this.setState({ processing: false });
  };

  _activatePINHandle = async (event) => {
    event.preventDefault();

    this.setState({ processing: true });

    let message;
    try {
      const res = await this.props.userObj.newPIN();
      message = res.message;
    } catch (e) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Edit Info creating PIN failed (${e.name}) ` + e.message
      );
      this._checkAndLogout(e);
      const match = /^.+\(\d+\) (.+)$/m.exec(e.message);
      if (match) {
        message = match[1] || e.message;
      } else {
        message = e.message;
      }
    }

    this.props.showSnackbar(message);
    this.setState({ processing: false });
    this.props.updateUserData();
  };

  render = () => {
    const inputDisabled = this.state.processing;
    const pinButtonText = this.props.userData.Permissions["DisableAuthPIN"]
      ? "Activar uso de PIN de Seguridad"
      : "Regenerar PIN de Seguridad";
    return (
      <div className="mx-auto pb-3" id="repDepForm">
        <form
          autoComplete="off"
          onSubmit={this._handleSubmit}
          id="newPasswordForm"
        >
          <div style={{ marginBottom: "3em" }}>
            <div className="mx-auto" style={{ width: "150px" }}>
              <button
                className="btn btn-primary btn-block"
                disabled={inputDisabled}
                onClick={this._handleNewPassword}
              >
                Regenerar Clave
              </button>
            </div>
            <div className="small my-2" style={{ textAlign: "center " }}>
              Su nueva Clave y PIN serán enviados a su correo registrado
            </div>
          </div>
        </form>

        <form autoComplete="off" id="activatePINForm">
          <div>
            <div className="mx-auto" style={{ width: "150px" }}>
              <button
                className="btn btn-primary btn-block"
                disabled={inputDisabled}
                onClick={this._activatePINHandle}
              >
                {pinButtonText}
              </button>
            </div>
            <div className="small my-2" style={{ textAlign: "center " }}>
              Su nuevo PIN será enviado a su correo registrado
              <br />
              Una vez activado, el PIN no puede ser desactivado.
            </div>
          </div>
        </form>
      </div>
    );
  };
}

EditInfoForm.propTypes = {
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  userData: PropTypes.object,
  updateUserData: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(EditInfoForm);
