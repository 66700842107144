import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";

export const DEFAULT_OPTIONS = {
  sellReport: "Mis Ventas",
  balanceTransfer: "Seleccione",
  generic: "Seleccione",
};

function UsersListModal(props) {
  const defaultUsername = props.defaultOption || DEFAULT_OPTIONS.generic;
  const showEmployees =
    props.showEmployees !== undefined ? props.showEmployees : true;

  const userRow = (
    userData = { id: 0, username: "", company: "", isEmployee: false }
  ) => (
    <div
      key={`${userData.id}-${userData.username}`}
      role="button"
      onClick={() => {
        props.setUserData(userData);
        props.onHide();
      }}
    >
      <i
        className="fas fa-id-card px-2"
        style={userData.isEmployee ? {} : { display: "none" }}
      ></i>
      <span className="px-2">{userData.username}</span>
    </div>
  );

  const usersRows = [];
  usersRows.push(userRow({ username: defaultUsername }));
  props.usersList.forEach((userData) => {
    if (!showEmployees && userData.IsEmployee) {
      return;
    }
    usersRows.push(<hr key={`div-${userData.UserID}`} />);
    usersRows.push(
      userRow({
        id: userData.UserID,
        username: userData.Username,
        company: userData.Company,
        isEmployee: Number.parseInt(userData.IsEmployee) === 1,
      })
    );
  });

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      animation={false}
      aria-labelledby="usersModalTitle"
      aria-hidden="true"
      role="dialog"
      centered
    >
      <Modal.Header className="py-2" closeButton>
        <Modal.Title id="usersModalTitle" style={{ fontSize: "1.2em" }}>
          Seleccione el Usuario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{usersRows}</Modal.Body>
    </Modal>
  );
}

UsersListModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  usersList: PropTypes.array,
  setUserData: PropTypes.func,
  defaultOption: PropTypes.string,
  showEmployees: PropTypes.bool,
};

export default UsersListModal;
