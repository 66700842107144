import React from "react";
import PropTypes from "prop-types";
import User from "../../objects/User";
import { PATHS } from "../../App";
import { withRouter } from "react-router-dom";

const DEBUG_MODE = false;

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
  };

  handleChange = (event) => {
    const { id, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  handleSubmit = (event) => {
    let user = new User(this.state.username, { debug: DEBUG_MODE });
    user
      .logInUser(this.state.password)
      .then((res) => {
        this.props.history.replace(PATHS.app);
        console.log("Login: " + res.message);
      })
      .catch((err) => {
        this.props.showError(err.message);
        console.log("Login: " + err.message);
        user.logout();
      });
    event.preventDefault();
  };

  componentDidMount = () => {
    let user = new User();
    if (user.isLoggedIn()) {
      this.props.history.replace(PATHS.app);
      console.log("Login: already logged in");
    }
  };

  render = () => (
    <div className="p-5">
      <div className="text-center">
        <h1 className="h4 text-gray-900 mb-4">Bienvenido a Districarga</h1>
        <h4>{this.props.distributorName}</h4>
        <hr />
      </div>
      <form className="user" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-user"
            id="username"
            aria-describedby="username"
            placeholder="Escriba su Usuario"
            value={this.state.username}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control form-control-user"
            id="password"
            placeholder="Clave"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-user btn-block">
          Ingresar
        </button>
      </form>
      <hr />
      <button className="btn btn-secondary btn-user btn-block" onClick={this.props.showSignInForm}>
        Registro de Clientes
      </button>
      <hr />
      <div className="text-center">
        <a className="small" href="https://recargaki.com/recuperacion.php">
          Recuperar Clave
        </a>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  distributorName: PropTypes.string,
  showError: PropTypes.func,
  showSignInForm: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(LoginForm);
