import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Card,
  Button,
  useAccordionToggle,
  AccordionContext,
} from "react-bootstrap";

import * as Logging from "../../../../objects/Logging";
import { isObjectEmpty } from "../../../../objects/Toolbox";
import UserEditForm, { userKeys, loadFormPOSData } from "./UserEditForm";

export const ITEMS_PERPAGE = 20;
const leftChevron = <i className="fas fa-chevron-left"></i>;
const rightChevron = <i className="fas fa-chevron-right"></i>;

export function pageData(index, itemsList) {
  if (ITEMS_PERPAGE > itemsList.length) {
    return itemsList;
  }
  if (ITEMS_PERPAGE * (index + 1) >= itemsList.length) {
    // pageIndex overflows or is exactly = lastPageIndex or out of bounds
    const decrement = itemsList.length % ITEMS_PERPAGE === 0 ? 1 : 0;
    const lastPageIndex =
      Math.floor(itemsList.length / ITEMS_PERPAGE) - decrement;
    return itemsList.slice(lastPageIndex * ITEMS_PERPAGE);
  }
  return itemsList.slice(index * ITEMS_PERPAGE, (index + 1) * ITEMS_PERPAGE);
}

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

function HeaderToggle({ children, eventKey }) {
  const [posUserID, posUsername] = eventKey.split("-");
  const keys = userKeys(posUserID, posUsername);
  const openingEventKey = useContext(AccordionContext);

  const onClick = useAccordionToggle(eventKey, async () => {
    const isClosing = openingEventKey === eventKey;
    if (isClosing) {
      return;
    }
    await loadFormPOSData(keys.formID);
  });

  return <div onClick={onClick}>{children}</div>;
}

HeaderToggle.propTypes = {
  children: PropTypes.array,
  eventKey: PropTypes.string,
};

class UserList extends React.Component {
  constructor() {
    super();
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "UserList class"
    );
  }

  render = () => {
    const data = this.props.usersListData;
    let pagerControls;

    if (isObjectEmpty(data.users)) {
      return <></>;
    }

    if (ITEMS_PERPAGE < data.totalUsers) {
      const pagers = [];
      const className = "btn-light mx-2 my-4 font-weight-bold";
      const currentIndex = data.pageIndex;
      const lastIndex = data.lastPageIndex;
      const moveLeft = () => this.props.changePage(currentIndex - 1);
      const moveRight = () => this.props.changePage(currentIndex + 1);

      if (currentIndex === 0) {
        pagers.push(
          <Button
            className={`${className} text-muted`}
            key="current"
            disabled={true}
          >
            1
          </Button>
        );
        pagers.push(
          <Button className={className} key="next" onClick={moveRight}>
            2
          </Button>
        );
        pagers.push(
          <Button className={className} key="next-chevron" onClick={moveRight}>
            {rightChevron}
          </Button>
        );
      } else if (currentIndex === data.lastPageIndex) {
        pagers.push(
          <Button className={className} key="back-chevron" onClick={moveLeft}>
            {leftChevron}
          </Button>
        );
        pagers.push(
          <Button className={className} key="back" onClick={moveLeft}>
            {lastIndex}
          </Button>
        );
        pagers.push(
          <Button
            className={`${className} text-muted`}
            key="current"
            disabled={true}
          >
            {lastIndex + 1}
          </Button>
        );
      } else {
        pagers.push(
          <Button className={className} key="back" onClick={moveLeft}>
            {leftChevron}
          </Button>
        );
        pagers.push(
          <Button
            className={`${className} text-muted`}
            key="current"
            disabled={true}
          >
            {currentIndex + 1}
          </Button>
        );
        pagers.push(
          <Button className={className} key="next" onClick={moveRight}>
            {rightChevron}
          </Button>
        );
      }

      pagerControls = (
        <div className="d-flex justify-content-center mx-auto">{pagers}</div>
      );
    }
    const entries = data.pagedList.map((user) => {
      const keys = userKeys(user.UserID, user.Username);
      return (
        <Card key={keys.key}>
          <Card.Header>
            <HeaderToggle eventKey={keys.key}>
              <i
                className="fas fa-id-card px-2"
                style={user.IsEmployee ? {} : { display: "none" }}
              ></i>
              <span className="px-2">{user.Username}</span>
            </HeaderToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={keys.key}>
            <Card.Body>
              <UserEditForm
                username={user.Username}
                userID={user.UserID}
                formID={keys.formID}
                showSnackbar={this.props.showSnackbar}
                userObj={this.props.userObj}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
    return (
      <div className="container">
        <div className="row">
          <Accordion className="mx-auto">{entries}</Accordion>
        </div>
        <div className="row">{pagerControls}</div>
      </div>
    );
  };
}

UserList.propTypes = {
  showSnackbar: PropTypes.func,
  usersListData: PropTypes.object,
  changePage: PropTypes.func,
  userObj: PropTypes.object,
};

export default UserList;
