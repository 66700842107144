import React from "react";
import PropTypes from "prop-types";

import EditInfoForm from "./EditInfoForm";

class EditInfo extends React.Component {
  render = () => {
    return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Cambiar Información</h1>
        </div>

        <div className="row">
          <div className="mx-auto my-4 text-center">
            <p className="h3">{this.props.userData.Username}</p>
            <p>{this.props.userData.Email}</p>
          </div>
        </div>

        <div className="row">
          <EditInfoForm
            showSnackbar={this.props.showSnackbar}
            userObj={this.props.userObj}
            userData={this.props.userData}
            updateUserData={this.props.updateUserData}
          />
        </div>
      </>
    );
  };
}

EditInfo.propTypes = {
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  userData: PropTypes.object,
  updateUserData: PropTypes.func,
};

export default EditInfo;
