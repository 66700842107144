import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { checkAndLogout } from "../../../../objects/User";
import * as Logging from "../../../../objects/Logging";
import {
  countryStates,
  defaultSelectedState,
  regexCharBlacklist,
} from "./UserEditForm";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

const DEBUG_MODE = false;
const LOG_LEVEL = Logging.LEVELS.INFO;

const FORM_ERRORS = {
  companyName: "Escriba una Razón Social",
  email: "Escriba un email",
  username: "El Nombre de Usuario debe de ser de 10 a 20 dígitos",
  contactName: "Escriba un nombre del Contacto",
  phoneNumber: "Teléfono debe de ser de 10 dígitos",
  mobileNumber: "Celular debe de ser de 10 dígitos",
  city: "Escriba una Ciudad",
  state: "Seleccione un Estado",
  streetAndNumber: "Escriba Calle y Número",
  colony: "Escriba la Colonia",
  postalCode: "Código Postal debe de ser de 5 dígitos",
  sellerCommission: "Comisión inválida. Formato D.DD",
};

const USER_DESC = {
  pos:
    "Un Punto de Venta tiene su propio saldo y hace sus propios depósitos de saldo.",
  employee: "Un Empleado utiliza el saldo de la cuenta que lo creó.",
};

const CHANGE_USERTYPE_BUTTON = {
  pos: "Crear Empleado",
  employee: "Crear Punto de Venta",
};

class NewUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.canOnlyCreateEmployees =
      props.hasPermission("CreateEmployees") &&
      !props.hasPermission("CreateUsers");
    this.canOnlyCreateUsers =
      props.hasPermission("CreateUsers") &&
      !props.hasPermission("CreateEmployees");

    this.initForm = {
      // Initial Form Type: true[Employee], false[Normal]
      isEmployee: this.canOnlyCreateEmployees,
      companyName: "",
      email: "",
      username: "",
      contactName: "",
      phoneNumber: "",
      mobileNumber: "",
      city: "",
      state: defaultSelectedState,
      streetAndNumber: "",
      colony: "",
      postalCode: "",
      sellerCommission: "",
    };
    this.state = {
      processing: false,
      formData: this.initForm,
    };
    this._logger = Logging.Logger(
      DEBUG_MODE ? Logging.LEVELS.DEBUG : LOG_LEVEL,
      "NewUserForm class"
    );
    this._checkAndLogout = checkAndLogout(
      this.props.userObj,
      this.props.history
    );
    // Currently User Creation API ignores any permission set at creation.
    this.deactivatePermissions = true;
  }

  _canonicalID = (formID) => {
    let id = formID.slice(3);
    return id.charAt(0).toLowerCase() + id.slice(1);
  };

  _onChange = (event) => {
    event.preventDefault();
    let { id, value } = event.target;
    id = this._canonicalID(id);

    switch (id) {
      case "companyName":
      case "contactName":
        value = value.replace(regexCharBlacklist.name, "");
        break;
      case "username":
        value = value.replace(regexCharBlacklist.username, "");
        value = value.trim();
        break;
      case "email":
        value = value.replace(regexCharBlacklist.email, "");
        break;
      case "phoneNumber":
      case "mobileNumber":
      case "postalCode":
        value = value.replace(regexCharBlacklist.number, "");
        break;
      case "sellerCommission":
        value = value.replace(regexCharBlacklist.numberWithDecimal, "");
        break;
      case "city":
      case "streetAndNumber":
      case "colony":
        break;
      default:
        value = value.trim();
        break;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [id]: value,
      },
    });
  };

  _handleSubmit = async (event) => {
    this.setState({ processing: true });
    const formData = this.state.formData;
    const isEmployee = formData.isEmployee;
    try {
      if (formData.companyName.length <= 5) {
        throw new Error(FORM_ERRORS.companyName);
      }
      if (formData.username.length < 10) {
        throw new Error(FORM_ERRORS.username);
      }
      if (!isEmployee) {
        if (formData.email.length < 6) {
          throw new Error(FORM_ERRORS.email);
        }
        if (formData.contactName.length <= 3) {
          throw new Error(FORM_ERRORS.contactName);
        }
        if (formData.phoneNumber.length !== 10) {
          throw new Error(FORM_ERRORS.phoneNumber);
        }
        if (
          formData.mobileNumber !== "" &&
          formData.mobileNumber.length !== 10
        ) {
          throw new Error(FORM_ERRORS.mobileNumber);
        }
        if (formData.city.length < 3) {
          throw new Error(FORM_ERRORS.city);
        }
        if (formData.state === defaultSelectedState) {
          throw new Error(FORM_ERRORS.state);
        }
        if (formData.streetAndNumber.length < 3) {
          throw new Error(FORM_ERRORS.streetAndNumber);
        }
        if (formData.colony === "") {
          throw new Error(FORM_ERRORS.colony);
        }
        if (formData.postalCode.length !== 5) {
          throw new Error(FORM_ERRORS.postalCode);
        }
        if (!/^\d{1,2}(\.\d{1,2})?$/.test(formData.sellerCommission)) {
          throw new Error(FORM_ERRORS.sellerCommission);
        }
      }

      const inputs = event.target.form.elements;
      for (let i = 0; i < inputs.length; i++) {
        const inputID = this._canonicalID(inputs[i].id);
        switch (inputID) {
          case "sellTopUp":
          case "createUsers":
          case "createEmployees":
          case "getReports":
          case "reportDeposit":
            formData[inputID] = inputs[i].checked;
            break;
          default:
            break;
        }
      }
      const res = await this.props.userObj.setPOSData(
        formData.username,
        formData,
        true,
        isEmployee ? "employee" : "normal"
      );
      this.setState({ formData: this.initForm });
      await this.props.getUserList(true);
      await this.props.changePage(0);

      const parts = res.message.split("\n");
      const title = <p>{parts[0]}</p>;
      const firstField = (
        <div id="CreatedUser">
          {isEmployee ? "Clave " : "Usuario "}
          <b className="mx-3">{parts[1].split(": ")[1]}</b>
        </div>
      );
      const secondField = (
        <div id="CreatedUserEmail">
          {isEmployee ? "PIN " : "Email "}
          <b className="mx-3">{parts[2].split(": ")[1]}</b>
        </div>
      );
      const message = (
        <>
          {title}
          {firstField}
          {secondField}
        </>
      );

      this.props.showResponseModal(message, isEmployee);
    } catch (err) {
      this._logger(
        Logging.LEVELS.WARNING,
        `Creating POS User - ${err.name}: ${err.message}`
      );
      this._checkAndLogout(err);
      const match = /^.+\(\d+\) (.+)$/m.exec(err.message);
      let message;
      if (match) {
        message = match[1] || err.message;
      } else {
        message = err.message;
      }
      this.props.showSnackbar(message);
    }
    this.setState({ processing: false });
  };

  _buildSwitchInput = (inputName, title) => {
    const newName = "new" + inputName;
    return (
      <div className="form-group" key={newName}>
        <label htmlFor={newName}>{title}</label>
        <ToggleSwitch
          id={newName}
          name={newName}
          disabled={this.state.processing}
        />
      </div>
    );
  };

  _changeUserType = () =>
    this.setState({
      formData: {
        ...this.state.formData,
        isEmployee: !this.state.formData.isEmployee,
      },
    });

  render = () => {
    const inputDisabled = this.state.processing;
    const formState = this.state.formData;
    const userData = this.props.userData;
    const commissionText = userData.SellerComission
      ? "Max Com: " + userData.SellerComission
      : "";
    const isEmployee = this.state.formData.isEmployee;
    const formType = isEmployee ? "employee" : "pos";
    const userDescription = USER_DESC[formType];

    const permissions = [];
    Object.entries(userData.Permissions || {}).forEach(
      ([permKey, hasPermission]) => {
        if (permKey === "SellTopUp" && hasPermission) {
          permissions.push(
            this._buildSwitchInput("new" + permKey, "Cobrar Saldo")
          );
        }
        if (permKey === "CreateUsers" && hasPermission && !isEmployee) {
          permissions.push(
            this._buildSwitchInput("new" + permKey, "Crear PDVs")
          );
        }
        if (permKey === "CreateEmployees" && hasPermission && !isEmployee) {
          permissions.push(
            this._buildSwitchInput("new" + permKey, "Crear Empleados")
          );
        }
        if (permKey === "GetReports" && hasPermission && !isEmployee) {
          permissions.push(
            this._buildSwitchInput("new" + permKey, "Reportes de Venta")
          );
        }
        if (permKey === "ReportDeposit" && hasPermission && !isEmployee) {
          permissions.push(
            this._buildSwitchInput("new" + permKey, "Reportar Depósitos")
          );
        }
      }
    );
    if (permissions.length > 0) {
      permissions.unshift(<hr key="permsSeparator" className="separator" />);
    }
    if (this.deactivatePermissions) {
      permissions.length = 0;
    }
    return (
      <div className="mx-auto pb-3" id="newUserForm">
        <form autoComplete="off" onSubmit={this._handleSubmit}>
          <div className="h6 text-center" style={{ width: "300px" }}>
            {userDescription}
          </div>

          {
            // If the user only have one permission, change UserType Button disappears
            this.canOnlyCreateUsers || this.canOnlyCreateEmployees ? null : (
              <div className="p-3 text-center">
                <Button variant="light" onClick={this._changeUserType}>
                  {CHANGE_USERTYPE_BUTTON[formType]}
                </Button>
              </div>
            )
          }

          <div className="form-group">
            <label htmlFor="newCompanyName">
              {isEmployee ? "Nombre Empleado" : "Razón"}
            </label>
            <input
              id="newCompanyName"
              name="newCompanyName"
              type="text"
              className="form-control"
              placeholder={isEmployee ? "Nombre" : "Razón Social"}
              value={formState.companyName}
              onChange={this._onChange}
              disabled={inputDisabled}
            />
          </div>

          {isEmployee ? (
            <></>
          ) : (
            <div className="form-group">
              <label htmlFor="newEmail">Email</label>
              <input
                id="newEmail"
                name="newEmail"
                type="text"
                className="form-control"
                placeholder="Email"
                value={formState.email}
                onChange={this._onChange}
                disabled={inputDisabled}
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="newUsername">Nombre de Usuario</label>
            <input
              id="newUsername"
              name="newUsername"
              type="text"
              className="form-control"
              placeholder="Usuario"
              value={formState.username}
              onChange={this._onChange}
              disabled={inputDisabled}
              maxLength="20"
            />
          </div>

          {isEmployee ? (
            <></>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="newContactName">Contacto</label>
                <input
                  id="newContactName"
                  name="newContactName"
                  type="text"
                  className="form-control"
                  placeholder="Nombre de Contacto"
                  value={formState.contactName}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newPhoneNumber">Teléfono</label>
                <input
                  id="newPhoneNumber"
                  name="newPhoneNumber"
                  type="text"
                  className="form-control"
                  placeholder="Teléfono"
                  value={formState.phoneNumber}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                  maxLength="10"
                />
              </div>

              <div className="form-group">
                <label htmlFor="newMobileNumber">Celular</label>
                <input
                  id="newMobileNumber"
                  name="newMobileNumber"
                  type="text"
                  className="form-control"
                  placeholder="Celular"
                  value={formState.mobileNumber}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                  maxLength="10"
                />
              </div>

              <hr className="separator" />

              <div className="form-group">
                <label htmlFor="newCity">Ciudad</label>
                <input
                  id="newCity"
                  name="newCity"
                  type="text"
                  className="form-control"
                  placeholder="Ciudad"
                  value={formState.city}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newState">Estado</label>
                <br />
                <select
                  id="newState"
                  name="newState"
                  value={formState.state}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                >
                  {countryStates.map((state) => (
                    <option value={state} key={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="newStreetAndNumber">Calle y Número</label>
                <input
                  id="newStreetAndNumber"
                  name="newStreetAndNumber"
                  type="text"
                  className="form-control"
                  placeholder="Calle"
                  value={formState.streetAndNumber}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newColony">Colonia</label>
                <input
                  id="newColony"
                  name="newColony"
                  type="text"
                  className="form-control"
                  placeholder="Colonia"
                  value={formState.colony}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                />
              </div>

              <div className="form-group">
                <label htmlFor="newPostalCode">CP</label>
                <input
                  id="newPostalCode"
                  name="newPostalCode"
                  type="text"
                  className="form-control"
                  placeholder="CP"
                  value={formState.postalCode}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                  maxLength="5"
                />
              </div>

              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label htmlFor="newSellerCommission">Comisión</label>
                  <div className="small font-weight-bold my-auto">
                    {commissionText}
                  </div>
                </div>
                <input
                  id="newSellerCommission"
                  name="newSellerCommission"
                  type="text"
                  className="form-control"
                  placeholder="Comisión"
                  value={formState.sellerCommission}
                  onChange={this._onChange}
                  disabled={inputDisabled}
                  maxLength="5"
                />
              </div>
            </>
          )}

          {permissions}

          <div className="d-flex p-4 mx-auto justify-content-center">
            <Button onClick={this._handleSubmit}>Crear Usuario</Button>
          </div>
        </form>
      </div>
    );
  };
}

NewUserForm.propTypes = {
  userData: PropTypes.object,
  showSnackbar: PropTypes.func,
  userObj: PropTypes.object,
  getUserList: PropTypes.func,
  changePage: PropTypes.func,
  showResponseModal: PropTypes.func,
  hasPermission: PropTypes.func,
  history: PropTypes.object, // from withRouter()
  match: PropTypes.object, // from withRouter()
  location: PropTypes.object, // from withRouter()
};

export default withRouter(NewUserForm);
