export const PINS = [
  "televia",
  "penguin",
  "xbox",
  "america",
  "bajalibros",
  "convergia",
  "cinepolis",
  "cinepolispax",
  "skypecien",
  "sendiez",
  "senveinte",
  "sentresmes",
  "facfiel",
  "nintendo200",
  "nintendo3mes",
  "kasperski",
  "faceunocinco",
  "netflix",
  "blim",
  "amazon3",
  "amazon5",
  "mercadolibre3",
  "spotify100",
  "spotify300",
];

export const DATA_PRODUCTS = [
  "telcelilimitado",
  "telceldatos",
  "movistardatos",
  "attdatos",
];

export const PRODS_WITHAREACOMMS = [
  "telcel",
  "telcelilimitado",
  "telceldatos",
  "att",
  "attdatos",
  "unefon",
];

export const AREACOMMS_PRODUCTS = {
  telcel: "CommissionTel",
  telcelilimitado: "CommissionTel",
  telceldatos: "CommissionTel",
  att: "CommissionATT",
  attdatos: "CommissionATT",
  unefon: "CommissionUne",
};

const COLORS = {
  default: "#ecb71d",
  telcel: "#4e73df",
  telcelilimitado: "#4e73df",
  telceldatos: "#4e73df",
  movistar: "#66cd00",
  movistardatos: "#66cd00",
  iusacell: "#e3001b",
  unefon: "#f7c000",
  nextel: "#e05206",
  virgin: "#e2211c",
  tuenti: "#ff0066",
  masrecargas: "#f8931f",
  cierto: "#ee1a60",
  alo: "#ff1818",
  flash: "#9e28b5",
  att: "#00a8e0",
  attdatos: "#00a8e0",
  oui: "#ffe100",
  televia: "#efb82f",
  penguin: "#032d60",
  xbox: "#8dda2a",
  america: "#f7e900",
  bajalibros: "#f57701",
  convergia: "#63247d",
  cinepolis: "#13216a",
  cinepolispax: "#13216a",
  skypecien: "#00439c",
  senveinte: "#00439c",
  sentresmes: "#00439c",
  facfiel: "#7c0000",
  nintendo200: "#e70012",
  nintendo3mes: "#e70012",
  kasperski: "#006a59",
  faceunocinco: "#3359a4",
  netflix: "#e50914",
  blim: "#00d2eb",
  amazon3: "#fa9700",
  amazon5: "#fa9700",
  mercadolibre3: "#fbe600",
  spotify100: "#1ed760",
  spotify300: "#1ed760",
};

export default COLORS;
