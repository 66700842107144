import React from "react";
import PropTypes from "prop-types";
import "./Footer.css";

const ONE_CHAR_SCROLL_TIME = 10; // Seconds

function Footer(props) {
  const messageArray = props.messages.map((msgData) =>
    msgData.message.replace(/[\n\t]/gi, " ")
  );
  const oneLineMessage = messageArray.join(
    "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0|\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"
  );
  const scrollTime = parseInt(
    ONE_CHAR_SCROLL_TIME + oneLineMessage.length * 0.3
  );
  return (
    <>
      <footer className="fixed-bottom bg-white">
        <div className="container my-auto" style={{'--scrollTime': `${scrollTime}s`}}>
          <div className="announcements text-center my-auto">
            {oneLineMessage}
          </div>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  messages: PropTypes.array,
};

export default Footer;
